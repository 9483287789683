import { auth } from '../firebase/config';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { sendAutoAccountEmail } from './emailService';

interface AutoAccountData {
  email: string;
  firstName: string;
  lastName: string;
  orderNumber: string;
}

export const createAutoAccount = async (data: AutoAccountData) => {
  try {
    // Créer un nouveau compte pour chaque commande
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      data.email,
      data.orderNumber
    );

    // Envoyer l'email avec les informations de connexion
    await sendAutoAccountEmail({
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      orderNumber: data.orderNumber,
      tempPassword: data.orderNumber,
      resetToken: ''
    });

    return {
      user: userCredential.user,
      message: 'ACCOUNT_CREATED'
    };
  } catch (error: any) {
    console.error('Erreur lors de la création du compte:', error);
    return {
      user: null,
      message: error.code || 'ERROR'
    };
  }
};
