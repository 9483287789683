import React, { useState, useEffect } from 'react';
import { Package, FileText, DollarSign, BarChart } from 'lucide-react';
import { calculateDashboardStats } from '../../services/statsService';
import { DashboardStats as DashboardStatsType } from '../../types/dashboard';
import { toast } from 'react-hot-toast';

const DashboardStats = () => {
  const [stats, setStats] = useState<DashboardStatsType>({
    ordersInProgress: 0,
    pendingDocuments: 0,
    revenue: 0,
    conversionRate: 0
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadStats = async () => {
      try {
        const newStats = await calculateDashboardStats();
        setStats(newStats);
      } catch (error) {
        toast.error('Erreur lors du chargement des statistiques');
      } finally {
        setIsLoading(false);
      }
    };

    loadStats();
    // Rafraîchir les stats toutes les 5 minutes
    const interval = setInterval(loadStats, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  if (isLoading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {[...Array(4)].map((_, i) => (
          <div key={i} className="bg-white rounded-lg shadow-lg p-6 animate-pulse">
            <div className="h-20"></div>
          </div>
        ))}
      </div>
    );
  }

  // Formater les nombres pour l'affichage
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };

  const formatPercent = (value: number) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'percent',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    }).format(value / 100);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
      {/* Commandes en cours */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-between mb-4">
          <div className="bg-blue-100 p-3 rounded-lg">
            <Package className="w-6 h-6 text-blue-600" />
          </div>
          <span className="text-sm font-medium text-gray-400">Commandes en cours</span>
        </div>
        <div>
          <h4 className="text-2xl font-bold text-gray-900">{stats.ordersInProgress}</h4>
          <p className="text-sm text-gray-500">
            Commandes à traiter
          </p>
        </div>
      </div>

      {/* Documents en attente */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-between mb-4">
          <div className="bg-yellow-100 p-3 rounded-lg">
            <FileText className="w-6 h-6 text-yellow-600" />
          </div>
          <span className="text-sm font-medium text-gray-400">Documents en attente</span>
        </div>
        <div>
          <h4 className="text-2xl font-bold text-gray-900">{stats.pendingDocuments}</h4>
          <p className="text-sm text-gray-500">
            Documents à valider
          </p>
        </div>
      </div>

      {/* Chiffre d'affaires */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-between mb-4">
          <div className="bg-green-100 p-3 rounded-lg">
            <DollarSign className="w-6 h-6 text-green-600" />
          </div>
          <span className="text-sm font-medium text-gray-400">Chiffre d'affaires</span>
        </div>
        <div>
          <h4 className="text-2xl font-bold text-gray-900">
            {formatCurrency(stats.revenue)}
          </h4>
          <p className="text-sm text-gray-500">
            30 derniers jours
          </p>
        </div>
      </div>

      {/* Taux de conversion */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-between mb-4">
          <div className="bg-purple-100 p-3 rounded-lg">
            <BarChart className="w-6 h-6 text-purple-600" />
          </div>
          <span className="text-sm font-medium text-gray-400">Taux de conversion</span>
        </div>
        <div>
          <h4 className="text-2xl font-bold text-gray-900">
            {formatPercent(stats.conversionRate)}
          </h4>
          <p className="text-sm text-gray-500">
            Commandes finalisées
          </p>
        </div>
      </div>
    </div>
  );
};

export default DashboardStats;