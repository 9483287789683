import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../firebase/config';
import { DashboardStats, Order, OrderStats } from '../types/dashboard';

export const calculateDashboardStats = async (): Promise<DashboardStats> => {
  try {
    const ordersRef = collection(db, 'orders');
    
    // Get date 30 days ago for revenue calculation
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    
    // Query orders from the last 30 days
    const recentOrdersQuery = query(
      ordersRef,
      where('createdAt', '>=', Timestamp.fromDate(thirtyDaysAgo))
    );
    
    // Get all orders for other calculations
    const [recentOrdersSnapshot, allOrdersSnapshot] = await Promise.all([
      getDocs(recentOrdersQuery),
      getDocs(ordersRef)
    ]);

    const recentOrders = recentOrdersSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    const allOrders = allOrdersSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    const getOrderStats = async (orders: Order[]): Promise<OrderStats> => {
      const stats: OrderStats = {
        total: orders.length,
        pending: orders.filter(order => order.status === 'pending').length,
        completed: orders.filter(order => order.status === 'completed').length,
        rejected: orders.filter(order => order.status === 'rejected').length,
        totalAmount: orders.reduce((sum, order) => sum + (order.amount || 0), 0),
        averageAmount: orders.length > 0 
          ? orders.reduce((sum, order) => sum + (order.amount || 0), 0) / orders.length 
          : 0,
        documentsStats: {
          total: orders.reduce((sum, order) => sum + Object.keys(order.documents || {}).length, 0),
          pending: orders.reduce((sum, order) => 
            sum + Object.values(order.documents || {})
              .filter(doc => doc.status === 'pending').length, 0),
          validated: orders.reduce((sum, order) => 
            sum + Object.values(order.documents || {})
              .filter(doc => doc.status === 'validated').length, 0),
          rejected: orders.reduce((sum, order) => 
            sum + Object.values(order.documents || {})
              .filter(doc => doc.status === 'rejected').length, 0),
        }
      };

      return stats;
    };

    const recentOrdersStats = await getOrderStats(recentOrders);
    const allOrdersStats = await getOrderStats(allOrders);

    // Chiffre d'affaires (commandes des 30 derniers jours)
    const revenue = recentOrdersStats.totalAmount;

    // Taux de conversion (commandes terminées / total des commandes)
    const conversionRate = allOrdersStats.total > 0 
      ? Math.round((allOrdersStats.completed / allOrdersStats.total) * 100)
      : 0;

    return {
      ordersInProgress: allOrdersStats.pending,
      pendingDocuments: allOrdersStats.documentsStats.pending,
      revenue,
      conversionRate
    };
  } catch (error) {
    console.error('Erreur lors du calcul des statistiques:', error);
    throw error;
  }
};