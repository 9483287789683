import { API_BASE_URL, PAYMENT_API } from '../config/api';

interface PaymentInitiateParams {
  amount: number;
  orderId: string;
  orderType: string;
  email: string;
  orderNumber: string;
}

export const initiatePayment = async ({
  amount,
  orderId,
  orderType,
  email,
  orderNumber
}: PaymentInitiateParams) => {
  try {
    console.log('Création intention de paiement:', {
      amount,
      orderId,
      orderType,
      email,
      orderNumber
    });

    const response = await fetch(`${API_BASE_URL}${PAYMENT_API.CREATE_INTENT}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount,
        orderId,
        orderType,
        email,
        metadata: {
          orderId,
          email,
          orderNumber
        }
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Erreur serveur:', errorData);
      throw new Error(errorData.error || 'Erreur lors de la création du paiement');
    }

    const data = await response.json();
    console.log('Réponse du serveur:', data);
    return data;
  } catch (error) {
    console.error('Erreur lors de l\'initiation du paiement:', error);
    throw error;
  }
};
