import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FileText, Package, Users, Settings, LogOut, ClipboardList } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';

const AdminSidebar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  return (
    <div className="h-full w-64 bg-white border-r border-gray-200">
      <div className="flex flex-col h-full">
        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            <span className="text-lg font-semibold text-gray-900">
              {user?.email}
            </span>
            <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
              Administrateur
            </span>
          </div>
          <nav className="mt-5 flex-1 px-2 space-y-1">
            <NavLink
              to="/admin/dashboard/orders"
              end
              className={({ isActive }) =>
                `group flex items-center px-2 py-2 text-sm font-medium rounded-md ${
                  isActive
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                }`
              }
            >
              <Package className="mr-3 h-5 w-5 text-gray-500" />
              Commandes
            </NavLink>
            <NavLink
              to="/admin/documents"
              className={({ isActive }) =>
                `group flex items-center px-2 py-2 text-sm font-medium rounded-md ${
                  isActive
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                }`
              }
            >
              <FileText className="mr-3 h-5 w-5 text-gray-500" />
              Suivi des documents
            </NavLink>
            <NavLink
              to="/admin/dashboard/clients"
              className={({ isActive }) =>
                `group flex items-center px-2 py-2 text-sm font-medium rounded-md ${
                  isActive
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                }`
              }
            >
              <Users className="mr-3 h-5 w-5 text-gray-500" />
              Clients
            </NavLink>
            <NavLink
              to="/admin/dashboard/settings"
              className={({ isActive }) =>
                `group flex items-center px-2 py-2 text-sm font-medium rounded-md ${
                  isActive
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                }`
              }
            >
              <Settings className="mr-3 h-5 w-5 text-gray-500" />
              Paramètres
            </NavLink>
          </nav>
        </div>
        <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
          <button
            onClick={handleLogout}
            className="flex-shrink-0 w-full group block"
          >
            <div className="flex items-center">
              <LogOut className="mr-3 h-5 w-5 text-gray-500" />
              <div className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                Déconnexion
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;