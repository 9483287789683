import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, CircularProgress, Paper } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import StripeWrapper from './StripeWrapper';
import toast from 'react-hot-toast';
import { API_BASE_URL, PAYMENT_API } from '../config/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase/config';

interface PaymentProps {
  onSuccess?: () => void;
}

const Payment: React.FC<PaymentProps> = ({ onSuccess }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMounted = useRef(true);
  const [clientSecret, setClientSecret] = useState<string>('');
  const [isInitializing, setIsInitializing] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [vehiculeDetails, setVehiculeDetails] = useState<any>(null);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [isCheckingUser, setIsCheckingUser] = useState(true);
  const [orderType, setOrderType] = useState<string>('');

  // Récupérer le montant et l'ID de commande depuis l'état de navigation
  const amount = Number(location.state?.amount) || 0;
  const orderId = location.state?.orderId || `order_${Date.now()}`;

  // Définir initializePayment avant de l'utiliser
  const initializePayment = async () => {
    if (isInitializing) return;
    
    setIsInitializing(true);
    const apiUrl = `${API_BASE_URL}${PAYMENT_API.CREATE_INTENT}`;
    
    try {
      // Envoi du montant en euros au backend qui fera la conversion
      console.log('Envoi du montant au backend:', amount, '€');

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          amount: amount,
          orderId 
        }),
      });

      if (!response.ok) {
        const text = await response.text();
        try {
          const errorData = JSON.parse(text);
          throw new Error(errorData.error || 'Erreur lors de l\'initialisation du paiement');
        } catch (e) {
          throw new Error(`Erreur ${response.status}: ${text}`);
        }
      }

      const data = await response.json();
      
      if (!data.clientSecret) {
        throw new Error('Pas de client secret reçu');
      }

      setClientSecret(data.clientSecret);
      setShowPaymentForm(true);
    } catch (error) {
      console.error('Erreur lors de l\'initialisation:', error);
      toast.error(error.message || 'Erreur lors de l\'initialisation du paiement');
    } finally {
      setIsInitializing(false);
    }
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Initialize component
  useEffect(() => {
    const initialize = async () => {
      try {
        // Check amount first
        if (!location.state?.amount) {
          if (isMounted.current) {
            toast.error('Montant non spécifié. Veuillez recommencer le calcul.');
            navigate('/calculateur');
          }
          return;
        }

        // Load stored data
        try {
          const storedVehiculeData = localStorage.getItem('vehiculeData');
          const storedFormData = localStorage.getItem('formData');

          if (!storedFormData) {
            if (isMounted.current) {
              toast.error('Informations manquantes. Veuillez recommencer.');
              navigate('/calculateur');
            }
            return;
          }

          const formData = JSON.parse(storedFormData);
          const vehiculeData = storedVehiculeData ? JSON.parse(storedVehiculeData) : null;

          // Determine order type
          const type = vehiculeData?.type || formData?.type || location.state?.orderType || 'changement-proprietaire';
          console.log('Type de commande déterminé:', type);
          
          setOrderType(type);
          setVehiculeDetails({
            ...vehiculeData,
            type // S'assurer que le type est bien défini
          });
          setUserDetails(formData);

        } catch (error) {
          console.error('Erreur lors du chargement des données:', error);
          toast.error('Erreur lors du chargement des données. Veuillez réessayer.');
          return;
        }

        // Initialize payment
        if (!isInitializing) {
          setIsInitializing(true);
          await initializePayment();
        }
      } catch (error) {
        console.error('Error during initialization:', error);
        toast.error('Une erreur est survenue. Veuillez réessayer.');
      } finally {
        setIsCheckingUser(false);
      }
    };

    initialize();
  }, [location.state]);

  // Ne pas continuer si la vérification n'est pas terminée
  if (isCheckingUser) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
        <CircularProgress />
      </Box>
    );
  }

  const handlePaymentSuccess = () => {
    toast.success('Paiement réussi !');
    if (onSuccess) {
      onSuccess();
    }
  };

  const handlePaymentError = (error: Error) => {
    console.error('Erreur de paiement:', error);
    toast.error('Erreur lors du paiement');
  };

  return (
    <Box sx={{ 
      height: '100vh',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Box sx={{ 
        p: 3, 
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        bgcolor: 'background.paper'
      }}>
        <Typography variant="h5" align="center" sx={{ color: 'primary.main' }}>
          Montant à payer : {amount.toFixed(2)} €
        </Typography>
      </Box>

      <Box sx={{ 
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto'
      }}>
        {!showPaymentForm ? (
          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              size="large"
              onClick={initializePayment}
              disabled={isInitializing}
              startIcon={isInitializing ? <CircularProgress size={20} /> : <LockIcon />}
              sx={{ 
                py: 2, 
                px: 4,
                backgroundColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
              }}
            >
              {isInitializing ? 'Initialisation...' : 'Continuer vers le paiement'}
            </Button>
            
            <Typography variant="body2" color="text.secondary" sx={{ mt: 3 }}>
              En cliquant sur ce bouton, vous serez redirigé vers notre interface de paiement sécurisée
            </Typography>
          </Box>
        ) : clientSecret ? (
          <StripeWrapper
            clientSecret={clientSecret}
            orderId={orderId}
            amount={amount}
            orderType={orderType}
            vehiculeDetails={vehiculeDetails}
            userDetails={userDetails}
            onSuccess={handlePaymentSuccess}
            onError={handlePaymentError}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default Payment;
