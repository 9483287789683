import { collection, getDocs, addDoc, query, where, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase/config';

export const migrateClientsToUsers = async () => {
  try {
    // 1. Récupérer tous les clients de la collection clients
    const clientsRef = collection(db, 'clients');
    const clientsSnapshot = await getDocs(clientsRef);
    
    let migratedCount = 0;
    let skippedCount = 0;

    for (const clientDoc of clientsSnapshot.docs) {
      const clientData = clientDoc.data();
      
      // Vérifier si l'email existe
      if (!clientData.email) {
        console.log('Client ignoré car pas d\'email:', clientDoc.id);
        continue;
      }

      // 2. Vérifier si un utilisateur avec cet email existe déjà
      const usersRef = collection(db, 'users');
      const userQuery = query(usersRef, where('email', '==', clientData.email));
      const userSnapshot = await getDocs(userQuery);
      
      if (userSnapshot.empty) {
        // 3. Créer un nouvel utilisateur avec les données du client
        // S'assurer que tous les champs sont définis avec des valeurs par défaut si nécessaire
        const userData = {
          email: clientData.email,
          prenom: clientData.firstName || '',  // Utiliser une chaîne vide si undefined
          nom: clientData.lastName || '',
          telephone: clientData.phone || '',
          adresse: clientData.address || '',
          role: 'client',
          createdAt: clientData.createdAt || new Date(),
          updatedAt: new Date()
        };

        // Vérifier qu'aucune valeur n'est undefined
        Object.keys(userData).forEach(key => {
          if (userData[key] === undefined) {
            userData[key] = '';  // Remplacer undefined par une chaîne vide
          }
        });

        // Créer l'utilisateur
        await addDoc(usersRef, userData);
        
        // 4. Supprimer l'ancien document client
        await deleteDoc(doc(db, 'clients', clientDoc.id));
        
        migratedCount++;
        console.log('Client migré avec succès:', clientData.email);
      } else {
        skippedCount++;
        console.log('Client déjà existant:', clientData.email);
      }
    }
    
    console.log(`Migration terminée. ${migratedCount} clients migrés, ${skippedCount} clients ignorés car déjà existants.`);
    return { migratedCount, skippedCount };
  } catch (error) {
    console.error('Erreur lors de la migration des clients:', error);
    throw error;
  }
};
