import React from 'react';
import { Box, Button, Typography, Paper, Grid, Divider, Chip, useTheme, useMediaQuery } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ReceiptIcon from '@mui/icons-material/Receipt';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router-dom';

interface PaymentConfirmationProps {
  orderId: string;
  orderNumber: string;
  amount: number;
  orderType: string;
  vehicule: any;
  onClose: () => void;
}

const PaymentConfirmation: React.FC<PaymentConfirmationProps> = ({
  orderId,
  orderNumber,
  amount,
  orderType,
  vehicule,
  onClose
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleViewOrder = () => {
    navigate(`/order-tracking/${orderId}`);
  };

  return (
    <Box sx={{ 
      width: '100%',
      maxWidth: { xs: '100%', sm: '600px', md: '700px' },
      mx: 'auto',
      p: { xs: 1, sm: 2 }
    }}>
      <Paper 
        elevation={3} 
        sx={{ 
          p: { xs: 2, sm: 3, md: 4 },
          textAlign: 'center',
          background: 'linear-gradient(to bottom, #ffffff, #f8f9fa)',
          borderRadius: { xs: 0, sm: 2 }
        }}
      >
        <Box sx={{ 
          mb: { xs: 2, sm: 3, md: 4 }, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center' 
        }}>
          <CheckCircleIcon sx={{ 
            fontSize: { xs: 60, sm: 70, md: 80 },
            color: 'success.main', 
            mb: 2,
            animation: 'pulse 2s infinite',
            '@keyframes pulse': {
              '0%': { transform: 'scale(1)' },
              '50%': { transform: 'scale(1.1)' },
              '100%': { transform: 'scale(1)' }
            }
          }} />
          
          <Typography variant={isMobile ? "h5" : "h4"} gutterBottom fontWeight="bold">
            Paiement confirmé !
          </Typography>

          <Typography 
            variant={isMobile ? "subtitle1" : "h6"} 
            color="text.secondary" 
            sx={{ mb: 2 }}
          >
            Votre commande a été traitée avec succès
          </Typography>

          <Chip 
            label={`Commande N° ${orderNumber}`}
            color="primary"
            sx={{ 
              fontSize: { xs: '1rem', sm: '1.1rem' },
              py: { xs: 1.5, sm: 2 },
              px: { xs: 0.5, sm: 1 }
            }}
          />
        </Box>

        <Divider sx={{ my: { xs: 2, sm: 3 } }} />

        <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ textAlign: 'left' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                <ReceiptIcon sx={{ mr: 1, color: 'primary.main' }} />
                <Typography variant={isMobile ? "subtitle1" : "h6"}>
                  Détails de la commande
                </Typography>
              </Box>
              
              <Typography variant="body1" sx={{ mb: 1, color: 'text.secondary' }}>
                Montant payé : <strong>{amount.toFixed(2)} €</strong>
              </Typography>
              
              <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                Type de démarche : <strong>{orderType === 'changement-proprietaire' ? 'Changement de propriétaire' : 
                                          orderType === 'immatriculation' ? 'Immatriculation' : 
                                          orderType === 'duplicata' ? 'Duplicata' : 
                                          orderType}</strong>
              </Typography>
            </Box>
          </Grid>

          {vehicule && (
            <Grid item xs={12} sm={6}>
              <Box sx={{ textAlign: 'left' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                  <DirectionsCarIcon sx={{ mr: 1, color: 'primary.main' }} />
                  <Typography variant={isMobile ? "subtitle1" : "h6"}>
                    Informations du véhicule
                  </Typography>
                </Box>
                
                <Typography variant="body1" sx={{ mb: 1, color: 'text.secondary' }}>
                  Marque : <strong>{vehicule.marque}</strong>
                </Typography>
                
                <Typography variant="body1" sx={{ mb: 1, color: 'text.secondary' }}>
                  Modèle : <strong>{vehicule.modele}</strong>
                </Typography>
                
                {vehicule.immatriculation && (
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    Immatriculation : <strong>{vehicule.immatriculation}</strong>
                  </Typography>
                )}
              </Box>
            </Grid>
          )}
        </Grid>

        <Divider sx={{ my: { xs: 2, sm: 3 } }} />

        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          mb: { xs: 2, sm: 3 },
          px: { xs: 1, sm: 0 }
        }}>
          <EmailIcon sx={{ mr: 1, color: 'info.main' }} />
          <Typography variant="body1" color="text.secondary">
            Un email de confirmation vous a été envoyé
          </Typography>
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={handleViewOrder}
          fullWidth={isMobile}
          size={isMobile ? "medium" : "large"}
          sx={{ 
            px: { xs: 3, sm: 4 },
            py: { xs: 1, sm: 1.5 },
            borderRadius: 2,
            textTransform: 'none',
            fontSize: { xs: '1rem', sm: '1.1rem' }
          }}
        >
          Suivre ma commande
        </Button>
      </Paper>
    </Box>
  );
};

export default PaymentConfirmation;
