import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { getFraisDeService, getDescriptionFraisDeService } from '../services/fraisService';

interface VehiculeInfo {
  marque: string;
  modele: string;
  puissance: string;
  co2: string;
  datePremiereCirculation?: string;
  dateMiseEnCirculation?: string;
  energie: string;
  immatriculation: string;
}

interface Taxes {
  total: number;
  taxeRegionale: number;
  taxeGestion: number;
  taxeRedevanceAcheminement: number;
  taxesCO2?: number;
  majorationTransport?: number;
}

interface OrderData {
  vehicule: VehiculeInfo;
  taxes: Taxes;
  totalAmount: number;
  departement: string;
  demarche: string;
}

const Recap: React.FC = () => {
  const [orderData, setOrderData] = useState<OrderData | null>(null);
  const [loading, setLoading] = useState(true);
  const [showTaxDetails, setShowTaxDetails] = useState(false);
  const navigate = useNavigate();

  // Fonction pour formater la date
  const formatDate = (dateString: string) => {
    if (!dateString) return 'Non spécifiée';
    
    try {
      // Si la date est déjà au format JJ/MM/AAAA
      if (dateString.includes('/')) {
        const [day, month, year] = dateString.split('/');
        if (day.length === 2) return dateString; // Déjà au bon format
        // Si c'est au format AAAA/MM/JJ, on le convertit
        if (day.length === 4) {
          return `${year}/${month}/${day}`;
        }
      }
      
      // Si la date est au format AAAA-MM-JJ
      if (dateString.includes('-')) {
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
      }
      
      // Sinon, essayer de parser la date
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return 'Non spécifiée';
      }
      return date.toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Non spécifiée';
    }
  };

  useEffect(() => {
    try {
      const storedData = localStorage.getItem('orderData');
      if (!storedData) {
        toast.error('Aucune donnée trouvée. Veuillez recommencer le calcul.');
        navigate('/calculateur');
        return;
      }

      const parsedData = JSON.parse(storedData);
      console.log('Données chargées dans Recap (brutes):', parsedData);
      console.log('Date de première circulation (brute):', parsedData.vehicule?.datePremiereCirculation);
      
      // Vérifier la présence des données de taxes
      if (!parsedData.taxes && !parsedData.y1TaxeRegionale) {
        console.error('Données de taxes manquantes:', parsedData);
        toast.error('Données de calcul manquantes. Veuillez recommencer.');
        navigate('/calculateur');
        return;
      }
      
      // Normaliser les données pour gérer l'ancien et le nouveau format
      const normalizedData = {
        ...parsedData,
        demarche: parsedData.demarche || parsedData.type,
        totalAmount: parsedData.totalAmount || parsedData.amount,
        taxes: parsedData.taxes || {
          taxeRegionale: parsedData.y1TaxeRegionale,
          taxeGestion: parsedData.y4TaxeFixe,
          taxeRedevanceAcheminement: parsedData.y5Acheminement,
          taxesCO2: parsedData.y3MalusCO2,
          majorationTransport: parsedData.y2MajorationTransport,
          total: parsedData.y6TotalTaxes
        }
      };
      
      console.log('Données normalisées:', normalizedData);
      
      // Obtenir les frais de service pour le type de démarche
      const fraisDeService = getFraisDeService(normalizedData.demarche);
      
      console.log('Données chargées dans Recap (détaillées):', {
        'Taxe régionale': normalizedData.taxes.taxeRegionale,
        'Taxe de gestion': normalizedData.taxes.taxeGestion,
        'Redevance acheminement': normalizedData.taxes.taxeRedevanceAcheminement,
        'Frais de service': fraisDeService,
        'Total des taxes': normalizedData.taxes.total,
        'Montant total': normalizedData.totalAmount
      });
      
      // Validation des données du véhicule
      const { vehicule, taxes, totalAmount } = normalizedData;
      const requiredFields = ['marque', 'modele', 'puissance', 'energie'];
      const missingFields = requiredFields.filter(field => !vehicule?.[field]);
      
      if (missingFields.length > 0) {
        console.error('Champs manquants:', missingFields);
        console.log('Données véhicule reçues:', vehicule);
        toast.error(`Données manquantes: ${missingFields.join(', ')}`);
        navigate('/calculateur');
        return;
      }

      // Vérifier uniquement si le montant total est valide
      if (!totalAmount || totalAmount <= 0) {
        console.error('Montant total invalide:', totalAmount);
        toast.error('Erreur dans le calcul du montant total. Veuillez recommencer.');
        navigate('/calculateur');
        return;
      }

      // Sauvegarder les données du véhicule séparément pour le checkout
      localStorage.setItem('vehiculeData', JSON.stringify({
        ...normalizedData.vehicule,
        type: normalizedData.demarche,
        datePremiereCirculation: normalizedData.vehicule.datePremiereCirculation
      }));
      
      setOrderData(normalizedData);
    } catch (error) {
      console.error('Erreur lors de la récupération des données:', error);
      toast.error('Une erreur est survenue. Veuillez recommencer.');
      navigate('/calculateur');
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  const isVehicleOver10Years = (datePremiereCirculation: string) => {
    const date = new Date(datePremiereCirculation);
    const currentYear = new Date().getFullYear();
    return currentYear - date.getFullYear() > 10;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!orderData) {
    return null;
  }

  return (
    <div className="max-w-4xl mx-auto p-4 space-y-6">
      {/* En-tête avec logo et titre */}
      <div className="flex items-center justify-between mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-800">{orderData.vehicule.marque} {orderData.vehicule.modele}</h1>
          <p className="text-gray-600">VOTRE CARTE GRISE EN LIGNE</p>
        </div>
        <div className="text-right">
          <div className="bg-blue-700 text-white p-4 rounded-lg">
            <h2 className="font-semibold mb-2">TARIF DE VOTRE DÉMARCHE</h2>
            <p className="text-3xl font-bold">{orderData.totalAmount.toFixed(2)} €</p>
          </div>
        </div>
      </div>

      {/* Informations du véhicule */}
      <div className="bg-white rounded-3xl shadow-lg p-8">
        <h1 className="text-3xl font-bold mb-8">{orderData.vehicule.marque} {orderData.vehicule.modele}</h1>
        <h2 className="text-xl font-semibold mb-6">VOTRE CARTE GRISE EN LIGNE</h2>

        <div className="space-y-8">
          <div>
            <h3 className="text-xl font-semibold mb-4">Type de démarche</h3>
            <p className="text-gray-700">{orderData.demarche}</p>
          </div>

          <div>
            <h3 className="text-xl font-semibold mb-4">Informations du véhicule</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-gray-600">Marque et modèle: {orderData.vehicule.marque} {orderData.vehicule.modele}</p>
                <p className="text-gray-600">Immatriculation: {orderData.vehicule.immatriculation}</p>
                <p className="text-gray-600">Département: {orderData.departement}</p>
                <p className="text-gray-600">Puissance fiscale: {orderData.vehicule.puissance} CV</p>
              </div>
              <div>
                <p className="text-gray-600">Énergie: {orderData.vehicule.energie === 'GO' ? 'GAZOLE' : 'ESSENCE'}</p>
                <p className="text-gray-600">Date de première circulation: {formatDate(orderData.vehicule.dateMiseEnCirculation || orderData.vehicule.datePremiereCirculation)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Taxes et redevances */}
      <div className="bg-white rounded-xl shadow-lg p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Taxes et redevances</h2>
          <button
            onClick={() => setShowTaxDetails(!showTaxDetails)}
            className="text-blue-600 hover:text-blue-800"
          >
            {showTaxDetails ? 'Masquer le détail' : 'Afficher le détail'}
          </button>
        </div>

        {showTaxDetails && (
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-gray-600">Taxe régionale</p>
                {isVehicleOver10Years(orderData.vehicule.dateMiseEnCirculation || orderData.vehicule.datePremiereCirculation) && (
                  <p className="text-green-600 text-sm">Réduction de 50% appliquée (véhicule de plus de 10 ans)</p>
                )}
              </div>
              <p className="text-gray-900 font-semibold">{orderData.taxes.taxeRegionale.toFixed(2)} €</p>
            </div>

            {orderData.taxes.majorationTransport > 0 && (
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-gray-600">Majoration transport</p>
                  <p className="text-gray-500 text-sm">Applicable aux véhicules utilitaires</p>
                </div>
                <p className="text-gray-900 font-semibold">{orderData.taxes.majorationTransport.toFixed(2)} €</p>
              </div>
            )}

            <div className="flex justify-between items-center">
              <p className="text-gray-600">Taxe de gestion</p>
              <p className="text-gray-900 font-semibold">{orderData.taxes.taxeGestion.toFixed(2)} €</p>
            </div>

            <div className="flex justify-between items-center">
              <p className="text-gray-600">Redevance d'acheminement</p>
              <p className="text-gray-900 font-semibold">{orderData.taxes.taxeRedevanceAcheminement.toFixed(2)} €</p>
            </div>

            <div className="flex justify-between items-center">
              <p className="text-gray-600">Frais de service</p>
              <p className="text-gray-900 font-semibold">{getFraisDeService(orderData.demarche).toFixed(2)} €</p>
            </div>

            <div className="border-t pt-4 mt-4">
              <div className="flex justify-between items-center font-bold">
                <p className="text-xl">Total</p>
                <p className="text-xl text-blue-600">{orderData.totalAmount.toFixed(2)} €</p>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Section Traitement et Agrément */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
        {/* Traitement en 24h */}
        <div className="bg-white shadow-lg rounded-lg p-6">
          <div className="border-2 border-blue-600 rounded-lg p-4">
            <h2 className="text-xl font-bold text-center text-blue-600">TRAITEMENT EN 24H</h2>
          </div>
        </div>

        {/* Agrément */}
        <div className="bg-white shadow-lg rounded-lg p-6">
          <div className="border-2 border-gray-200 rounded-lg p-4">
            <h2 className="text-xl font-bold text-center mb-2">Professionnel habilité & agréé</h2>
            <div className="relative h-8 mb-4">
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="h-1 w-full bg-gray-200">
                  <div className="h-full w-1/3 bg-blue-600"></div>
                  <div className="h-full w-1/3 bg-red-600 ml-auto"></div>
                </div>
              </div>
            </div>
            <h3 className="text-lg font-bold text-center mb-4">MINISTÈRE DE L'INTÉRIEUR</h3>
            <div className="text-center text-blue-600">
              <div className="text-sm text-gray-600">
                <p className="mb-2">Agrément Préfecture n°57878</p>
                <p>Habilitation Trésor Public n°226709</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Boutons d'action */}
      <div className="flex justify-between mt-8">
        <button
          onClick={() => navigate(-1)}
          className="px-6 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition-colors"
        >
          Retour
        </button>
        <button
          onClick={() => navigate('/checkout')}
          className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          Continuer
        </button>
      </div>
    </div>
  );
};

export default Recap;
