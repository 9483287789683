import { Timestamp } from 'firebase/firestore';

export const formatDate = (date: Date | Timestamp | null | undefined) => {
  if (!date) return '';
  
  if (date instanceof Timestamp) {
    return date.toDate().toLocaleDateString('fr-FR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }
  
  if (date instanceof Date) {
    return date.toLocaleDateString('fr-FR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }
  
  return new Date(date).toLocaleDateString('fr-FR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
};
