import { API_CONFIG } from '../config/api';
import { http, HttpError } from '../utils/http';

interface VehicleInfo {
  message?: string;
  code?: number;
  error?: boolean;
  country?: string;
  plaque?: string;
  data?: {
    AWN_marque: string;
    AWN_modele: string;
    AWN_puissance_fiscale: string;
    AWN_date_mise_en_circulation: string;
    AWN_energie: string;
    AWN_emission_co_2: string;
    AWN_version: string;
    AWN_genre_carte_grise: string;
    [key: string]: string;
  };
  AWN_marque?: string;
  AWN_modele?: string;
  AWN_puissance_fiscale?: string;
  AWN_date_mise_en_circulation?: string;
  AWN_energie?: string;
  AWN_emission_co_2?: string;
  AWN_version?: string;
  AWN_genre_carte_grise?: string;
}

// Fonction pour convertir une date dans un format standardisé YYYY-MM-DD
const formatDate = (dateStr: string): string => {
  if (!dateStr) return '';
  
  // Nettoyage de la chaîne de date
  dateStr = dateStr.trim();
  
  // Format de l'API SIV: "DD-MM-YYYY"
  const match = dateStr.match(/^(\d{2})-(\d{2})-(\d{4})$/);
  if (match) {
    const [_, day, month, year] = match;
    return `${year}-${month}-${day}`;
  }
  
  console.error('Format de date non reconnu:', dateStr);
  return '';
};

// Mock data pour le développement
const MOCK_VEHICLE_DATA = {
  AWN_marque: 'HONDA',
  AWN_modele: 'FR-V',
  AWN_puissance_fiscale: '8',
  AWN_date_mise_en_circulation: '2006-06-22',
  AWN_energie: 'ES',
  AWN_emission_co_2: '190',
  AWN_version: '2.0 I-VTEC',
  AWN_genre_carte_grise: 'VP'
};

// Le mock n'est actif qu'en développement
const USE_MOCK = false;

const mapSivDataToVehicleInfo = (sivData: any): VehicleInfo => {
  // Si les données sont dans un sous-objet data, utiliser celui-ci
  const sourceData = sivData.data || sivData;
  
  console.log('Données SIV brutes:', sivData);
  console.log('Données source pour mappage:', sourceData);
  
  // Vérifier spécifiquement le genre du véhicule
  const genre = sourceData.AWN_genre_carte_grise || '';
  console.log('Genre du véhicule détecté:', genre);
  
  return {
    AWN_marque: sourceData.AWN_marque || '',
    AWN_modele: sourceData.AWN_modele || '',
    AWN_puissance_fiscale: sourceData.AWN_puissance_fiscale || '',
    AWN_date_mise_en_circulation: formatDate(sourceData.AWN_date_mise_en_circulation),
    AWN_energie: sourceData.AWN_energie || '',
    AWN_emission_co_2: sourceData.AWN_emission_co_2 || '',
    AWN_version: sourceData.AWN_version || '',
    AWN_genre_carte_grise: genre,
    data: sivData.data // Conserver les données brutes pour référence
  };
};

export const fetchVehicleInfo = async (immatriculation: string): Promise<VehicleInfo> => {
  try {
    // Si le mock est activé en développement, retourner les données mockées
    if (USE_MOCK) {
      console.log('Mode développement : utilisation des données de test');
      return MOCK_VEHICLE_DATA;
    }

    // En production, utiliser l'API réelle
    // Formatage de l'immatriculation (suppression des tirets)
    const formattedImmat = immatriculation.replace(/-/g, '');
    
    const response = await http<VehicleInfo>(`https://api-siv-systeme-d-immatriculation-des-vehicules.p.rapidapi.com/${formattedImmat}`, {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '15c42e25f1msh9d00bbf01a95053p15a0c2jsndf0d4e6d9d06',
        'X-RapidAPI-Host': 'api-siv-systeme-d-immatriculation-des-vehicules.p.rapidapi.com',
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      timeout: 10000,
      retries: 2,
    });
    
    console.log('Réponse complète de l\'API:', JSON.stringify(response, null, 2));
    
    if (!response.data) {
      throw new Error('Réponse invalide du serveur');
    }

    const vehicleData = response.data.data;
    
    if (!vehicleData) {
      throw new Error('Données du véhicule non trouvées');
    }
    
    // Log pour debug
    console.log('Données brutes reçues de l\'API:', JSON.stringify(response.data, null, 2));
    console.log('Données du véhicule extraites:', JSON.stringify(vehicleData, null, 2));
    
    // Transformation des données
    const transformedData = mapSivDataToVehicleInfo(vehicleData);
    
    console.log('Données transformées:', JSON.stringify(transformedData, null, 2));
    
    return transformedData;
  } catch (error) {
    if (error instanceof HttpError) {
      console.error('Erreur HTTP lors de la récupération des informations du véhicule:', {
        status: error.status,
        message: error.message,
        response: error.response
      });
      
      if (error.status === 404) {
        throw new Error('Véhicule non trouvé avec cette immatriculation');
      } else if (error.status === 429) {
        throw new Error('Trop de requêtes. Veuillez réessayer dans quelques instants');
      } else {
        throw new Error(`Erreur ${error.status || ''}: Impossible de récupérer les informations du véhicule`);
      }
    }
    
    console.error('Erreur lors de la récupération des informations du véhicule:', error);
    throw new Error('Impossible de récupérer les informations du véhicule. Veuillez réessayer plus tard.');
  }
};
