import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import PaymentConfirmation from '../components/PaymentConfirmation';
import { sendOrderConfirmationEmail } from '../services/emailService';
import toast from 'react-hot-toast';

const PaymentSuccessPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [orderData, setOrderData] = useState<any>(null);

  useEffect(() => {
    const processPaymentSuccess = async () => {
      try {
        const orderId = searchParams.get('order');
        const paymentIntent = searchParams.get('payment_intent');

        if (!orderId) {
          toast.error('Numéro de commande manquant');
          navigate('/');
          return;
        }

        // Récupérer les données de la commande
        const orderRef = doc(db, 'orders', orderId);
        const orderSnap = await getDoc(orderRef);

        if (!orderSnap.exists()) {
          toast.error('Commande introuvable');
          navigate('/');
          return;
        }

        const orderData = orderSnap.data();

        // Mettre à jour le statut de la commande
        await updateDoc(orderRef, {
          status: 'completed',
          paymentIntentId: paymentIntent,
          updatedAt: new Date()
        });

        // Envoyer l'email de confirmation
        await sendOrderConfirmationEmail({
          to: orderData.email,
          orderNumber: orderData.orderNumber,
          clientName: `${orderData.userDetails?.firstName} ${orderData.userDetails?.lastName}`,
          totalAmount: orderData.amount,
          requiredDocuments: ['piece_identite', 'justificatif_domicile'],
          vehiculeDetails: orderData.vehiculeDetails
        });

        setOrderData(orderData);
        setIsLoading(false);
      } catch (error) {
        console.error('Erreur lors du traitement du paiement:', error);
        toast.error('Une erreur est survenue lors de la confirmation du paiement');
        navigate('/');
      }
    };

    processPaymentSuccess();
  }, [searchParams, navigate]);

  const handleClose = () => {
    // Cette fonction n'est plus utilisée car le bouton dans PaymentConfirmation
    // redirige maintenant directement vers la page de suivi de commande
    navigate(`/dashboard?order=${searchParams.get('order')}`);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <PaymentConfirmation
      orderId={searchParams.get('order') || ''}
      orderNumber={orderData.orderNumber || ''}
      amount={orderData.amount}
      orderType={orderData.orderType}
      vehicule={orderData.vehiculeDetails}
      onClose={handleClose}
    />
  );
};

export default PaymentSuccessPage;
