import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  User,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { 
  collection, 
  query, 
  where, 
  getDocs,
  doc,
  getDoc,
  setDoc
} from 'firebase/firestore';
import { auth, db } from '../firebase/config';
import { UserRole, UserProfile } from '../types/user.types';
import { verifyOrderAccess } from '../services/authService';

interface AuthContextType {
  user: User | null;
  role: UserRole | null;
  loading: boolean;
  isAdmin: boolean;
  userProfile: UserProfile | null;
  signIn: (email: string, orderNumber: string) => Promise<void>;
  logout: () => Promise<void>;
  updateProfile: (data: Partial<UserProfile>) => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  role: null,
  loading: true,
  isAdmin: false,
  userProfile: null,
  signIn: async () => {},
  logout: async () => {},
  updateProfile: async () => {}
});

export const useAuth = () => useContext(AuthContext);

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [role, setRole] = useState<UserRole | null>(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log("Auth state changed:", user?.email);
      setUser(user);

      if (user) {
        // Vérifier d'abord si c'est un admin
        const adminRef = collection(db, 'admins');
        const adminQuery = query(adminRef, where('email', '==', user.email));
        const adminSnapshot = await getDocs(adminQuery);

        if (!adminSnapshot.empty) {
          // C'est un admin
          const adminData = adminSnapshot.docs[0].data();
          setRole('admin');
          setIsAdmin(true);
          setUserProfile(adminData as UserProfile);
        } else {
          // C'est un client
          // Extraire l'email original et le numéro de commande
          const originalEmail = user.email?.split('+')[0] + '@' + user.email?.split('@')[1];
          const orderNumber = user.email?.split('+')[1]?.split('@')[0];

          // Chercher le profil client
          const clientRef = doc(db, 'clients', `${originalEmail}_${orderNumber}`);
          const clientDoc = await getDoc(clientRef);

          if (clientDoc.exists()) {
            const clientData = clientDoc.data();
            setRole('client');
            setIsAdmin(false);
            setUserProfile(clientData as UserProfile);
          }
        }
      } else {
        // Déconnecté
        setRole(null);
        setIsAdmin(false);
        setUserProfile(null);
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const signIn = async (email: string, orderNumber: string) => {
    try {
      // Vérifier d'abord si c'est un admin
      const adminRef = collection(db, 'admins');
      const adminQuery = query(adminRef, where('email', '==', email));
      const adminSnapshot = await getDocs(adminQuery);

      if (adminSnapshot.empty) {
        // Ce n'est pas un admin, donc c'est un client
        // Vérifier l'accès à la commande
        const isValid = await verifyOrderAccess(email, orderNumber);
        if (!isValid) {
          throw new Error('Numéro de commande ou email invalide');
        }

        // Créer l'email unique pour le client
        const clientEmail = `${email}+${orderNumber}@cartegrise.fr`;
        
        // Connecter le client
        await signInWithEmailAndPassword(auth, clientEmail, orderNumber);

        // Créer/mettre à jour le profil client
        const clientRef = doc(db, 'clients', `${email}_${orderNumber}`);
        await setDoc(clientRef, {
          email: email,
          orderNumber: orderNumber,
          role: 'client',
          lastLogin: new Date(),
          updatedAt: new Date()
        }, { merge: true });

      } else {
        // C'est un admin
        await signInWithEmailAndPassword(auth, email, orderNumber);
        
        // Mettre à jour la dernière connexion
        const adminDoc = adminSnapshot.docs[0];
        await setDoc(doc(db, 'admins', adminDoc.id), {
          lastLogin: new Date()
        }, { merge: true });
      }
    } catch (error) {
      console.error('Erreur de connexion:', error);
      throw error;
    }
  };

  const logout = async () => {
    if (user) {
      // Si c'est un client, nettoyer sa session
      if (role === 'client' && userProfile?.orderNumber) {
        const clientRef = doc(db, 'clients', `${userProfile.email}_${userProfile.orderNumber}`);
        await setDoc(clientRef, {
          lastLogout: new Date()
        }, { merge: true });
      }
    }
    
    setUserProfile(null);
    setRole(null);
    setIsAdmin(false);
    await signOut(auth);
  };

  const updateProfile = async (data: Partial<UserProfile>) => {
    if (!user) throw new Error('Utilisateur non connecté');

    // Utiliser la bonne collection selon le rôle
    const collection = isAdmin ? 'admins' : 'clients';
    const docId = isAdmin ? user.uid : `${data.email}_${userProfile?.orderNumber}`;
    
    const profileRef = doc(db, collection, docId);
    
    // Mettre à jour le profil avec les bonnes données selon le rôle
    await setDoc(profileRef, {
      ...data,
      role: isAdmin ? 'admin' : 'client',
      updatedAt: new Date()
    }, { merge: true });

    // Mettre à jour l'état local
    const updatedDoc = await getDoc(profileRef);
    if (updatedDoc.exists()) {
      setUserProfile(updatedDoc.data() as UserProfile);
    }
  };

  return (
    <AuthContext.Provider value={{
      user,
      role,
      loading,
      isAdmin,
      userProfile,
      signIn,
      logout,
      updateProfile
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;