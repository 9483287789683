export const DOCUMENT_GROUPS = {
  'changement-adresse': [
    {
      id: 'formulaires',
      title: 'Formulaires obligatoires',
      documents: [
        {
          id: 'cerfa-13750',
          name: 'Demande de certificat de changement d\'adresse (CERFA 13750*07)',
          description: 'Document OBLIGATOIRE pour toute demande de carte grise, rempli et signé',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'mandat-immatriculation',
          name: 'Mandat d\'immatriculation (CERFA 13757*03)',
          description: 'Nous autorisant à effectuer les démarches de carte grise pour vous',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'vehicule',
      title: 'Documents du véhicule',
      documents: [
        {
          id: 'carte-grise',
          name: 'Carte grise du véhicule',
          description: 'Qui doit être modifiée avec le changement d\'adresse (recto/verso)',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'controle-technique',
          name: 'Contrôle technique',
          description: 'En cours de validité',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'attestation-assurance',
          name: 'Attestation d\'assurance',
          description: 'En cours de validité ou mémo',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'identite',
      title: 'Justificatifs d\'identité',
      documents: [
        {
          id: 'piece-identite',
          name: 'Pièce d\'identité',
          description: 'CNI recto/verso, passeport ou titre de séjour en cours de validité',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'permis-conduire',
          name: 'Permis de conduire',
          description: 'Recto/verso du permis de conduire du demandeur',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'domicile',
      title: 'Justificatif de domicile',
      documents: [
        {
          id: 'justificatif-domicile',
          name: 'Justificatif de domicile',
          description: 'De moins de 6 mois (facture énergie, télécom, quittance de loyer, attestation d\'assurance habitation, avis d\'imposition). Doit être au nom du titulaire de la carte grise et à l\'adresse de résidence.',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'societe',
      title: 'Documents supplémentaires pour une société',
      documents: [
        {
          id: 'kbis',
          name: 'Extrait Kbis',
          description: 'De moins de 6 mois',
          required: false,
          conditionalText: 'Requis si le demandeur est une société',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'piece-identite-gerant',
          name: 'Pièce d\'identité du gérant',
          description: 'CNI, passeport ou titre de séjour en cours de validité du gérant',
          required: false,
          conditionalText: 'Requis si le demandeur est une société',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    }
  ],
  'changement-titulaire': [
    {
      id: 'formulaires',
      title: 'Formulaires obligatoires',
      documents: [
        {
          id: 'cerfa-13750',
          name: 'Demande de certificat d\'immatriculation (CERFA 13750*07)',
          description: 'Document OBLIGATOIRE pour toute demande de carte grise, rempli et signé',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'mandat-immatriculation',
          name: 'Mandat d\'immatriculation (CERFA 13757*03)',
          description: 'Nous autorisant à effectuer les démarches de carte grise pour vous',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'certificat-cession',
          name: 'Certificat de cession (CERFA N°15776*01)',
          description: 'Rempli et signé par l\'ancien propriétaire et l\'acquéreur',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'vehicule',
      title: 'Documents du véhicule',
      documents: [
        {
          id: 'carte-grise',
          name: 'Carte grise de l\'ancien propriétaire',
          description: 'Barrée et mentionnée avec la date et la signature du vendeur (recto/verso), et tamponnée si vendeur professionnel',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'declaration-achat-pro',
          name: 'Déclaration d\'achat du professionnel',
          description: 'Document requis si le véhicule est vendu par un professionnel de l\'automobile',
          required: false,
          conditionalText: 'Requis si le vendeur est un professionnel de l\'automobile',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'controle-technique',
          name: 'Contrôle technique',
          description: 'De moins de 6 mois pour les véhicules de plus de 4 ans',
          required: true,
          conditionalText: 'Requis si le véhicule a plus de 4 ans',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'attestation-assurance',
          name: 'Attestation d\'assurance',
          description: 'En cours de validité ou mémo',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'identite',
      title: 'Justificatifs d\'identité',
      documents: [
        {
          id: 'piece-identite',
          name: 'Pièce d\'identité',
          description: 'CNI recto/verso, passeport ou titre de séjour en cours de validité',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'permis-conduire',
          name: 'Permis de conduire',
          description: 'Recto/verso du permis de conduire du demandeur',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'domicile',
      title: 'Justificatif de domicile',
      documents: [
        {
          id: 'justificatif-domicile',
          name: 'Justificatif de domicile',
          description: 'De moins de 6 mois (facture énergie, télécom, quittance de loyer, attestation d\'assurance habitation, avis d\'imposition)',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'societe',
      title: 'Documents supplémentaires pour une société',
      documents: [
        {
          id: 'kbis',
          name: 'Extrait Kbis',
          description: 'De moins de 6 mois',
          required: false,
          conditionalText: 'Requis si le demandeur est une société',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'piece-identite-gerant',
          name: 'Pièce d\'identité du gérant',
          description: 'CNI, passeport ou titre de séjour en cours de validité du gérant',
          required: false,
          conditionalText: 'Requis si le demandeur est une société',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    }
  ],
  'declaration-cession': [
    {
      id: 'formulaires',
      title: 'Formulaires obligatoires',
      documents: [
        {
          id: 'certificat-cession',
          name: 'Certificat de cession',
          description: 'Certificat de cession d\'un véhicule d\'occasion (CERFA N°15776*01) rempli et signé par l\'ancien propriétaire et l\'acquéreur',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'mandat-immatriculation',
          name: 'Mandat d\'immatriculation',
          description: 'Mandat CERFA 13757*03 rempli et signé',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'vehicule',
      title: 'Documents du véhicule',
      documents: [
        {
          id: 'carte-grise-barree',
          name: 'Carte grise barrée',
          description: 'La carte grise de l\'ancien propriétaire, barrée et mentionnée avec la date et la signature du vendeur',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'identite',
      title: 'Documents d\'identité',
      documents: [
        {
          id: 'piece-identite',
          name: 'Pièce d\'identité',
          description: 'CNI recto/verso, passeport ou titre de séjour en cours de validité',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'kbis',
          name: 'Kbis',
          description: 'Un Kbis de moins de 6 mois (si société)',
          required: false,
          conditionalText: 'Document requis si le demandeur est une société, avec la pièce d\'identité du gérant',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    }
  ],
  'duplicata': [
    {
      id: 'formulaires',
      title: 'Formulaires obligatoires',
      documents: [
        {
          id: 'cerfa-13750',
          name: 'Demande de certificat de duplicata (CERFA 13750*07)',
          description: 'Document OBLIGATOIRE pour toute demande de duplicata, rempli et signé',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'mandat-immatriculation',
          name: 'Mandat d\'immatriculation (CERFA 13757*03)',
          description: 'Nous autorisant à effectuer les démarches de carte grise pour vous',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'declaration-perte',
          name: 'Déclaration de perte (CERFA N°13753*03)',
          description: 'Rempli et signé',
          required: false,
          conditionalText: 'Requis en cas de perte de la carte grise',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'vehicule',
      title: 'Documents du véhicule',
      documents: [
        {
          id: 'declaration-vol',
          name: 'Récépissé de déclaration de vol',
          description: 'Remis par la police ou la gendarmerie',
          required: false,
          conditionalText: 'Requis en cas de vol de la carte grise',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'controle-technique',
          name: 'Contrôle technique',
          description: 'En cours de validité',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'attestation-assurance',
          name: 'Attestation d\'assurance',
          description: 'En cours de validité ou mémo',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'identite',
      title: 'Justificatifs d\'identité',
      documents: [
        {
          id: 'piece-identite',
          name: 'Pièce d\'identité',
          description: 'CNI recto/verso, passeport ou titre de séjour en cours de validité',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'permis-conduire',
          name: 'Permis de conduire',
          description: 'Recto/verso du permis de conduire du demandeur',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'domicile',
      title: 'Justificatif de domicile',
      documents: [
        {
          id: 'justificatif-domicile',
          name: 'Justificatif de domicile',
          description: 'De moins de 6 mois (facture énergie, télécom, quittance de loyer, attestation d\'assurance habitation, avis d\'imposition)',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'societe',
      title: 'Documents supplémentaires pour une société',
      documents: [
        {
          id: 'kbis',
          name: 'Extrait Kbis',
          description: 'De moins de 6 mois',
          required: false,
          conditionalText: 'Requis si le demandeur est une société',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'piece-identite-gerant',
          name: 'Pièce d\'identité du gérant',
          description: 'CNI, passeport ou titre de séjour en cours de validité du gérant',
          required: false,
          conditionalText: 'Requis si le demandeur est une société',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    }
  ],
  'vehicule-neuf': [
    {
      id: 'formulaires',
      title: 'Formulaires obligatoires',
      documents: [
        {
          id: 'cerfa-13749',
          name: 'Demande de certificat d\'immatriculation (CERFA 13749*05)',
          description: 'Document OBLIGATOIRE pour toute demande de carte grise d\'un véhicule neuf, rempli et signé',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'mandat-immatriculation',
          name: 'Mandat d\'immatriculation (CERFA 13757*03)',
          description: 'Nous autorisant à effectuer les démarches de carte grise pour vous',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'vehicule',
      title: 'Documents du véhicule',
      documents: [
        {
          id: 'certificat-conformite',
          name: 'Certificat de conformité européen',
          description: 'Délivré par le constructeur, attestant que le véhicule respecte les normes UE',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'certificat-vente',
          name: 'Certificat de vente ou facture',
          description: 'Certificat de vente délivré par le concessionnaire ou facture d\'achat du véhicule',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'attestation-assurance',
          name: 'Attestation d\'assurance',
          description: 'En cours de validité ou mémo',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'identite',
      title: 'Justificatifs d\'identité',
      documents: [
        {
          id: 'piece-identite',
          name: 'Pièce d\'identité',
          description: 'CNI recto/verso, passeport ou titre de séjour en cours de validité',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'permis-conduire',
          name: 'Permis de conduire',
          description: 'Recto/verso du permis de conduire du demandeur',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'domicile',
      title: 'Justificatif de domicile',
      documents: [
        {
          id: 'justificatif-domicile',
          name: 'Justificatif de domicile',
          description: 'De moins de 6 mois (facture énergie, télécom, quittance de loyer, attestation d\'assurance habitation, avis d\'imposition)',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'societe',
      title: 'Documents supplémentaires pour une société',
      documents: [
        {
          id: 'kbis',
          name: 'Extrait Kbis',
          description: 'De moins de 6 mois',
          required: false,
          conditionalText: 'Requis si le demandeur est une société',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'piece-identite-gerant',
          name: 'Pièce d\'identité du gérant',
          description: 'CNI, passeport ou titre de séjour en cours de validité du gérant',
          required: false,
          conditionalText: 'Requis si le demandeur est une société',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    }
  ],
  'heritage': [
    {
      id: 'formulaires',
      title: 'Formulaires obligatoires',
      documents: [
        {
          id: 'cerfa-13750',
          name: 'Demande de certificat d\'immatriculation (CERFA 13750*07)',
          description: 'Document OBLIGATOIRE pour toute demande de carte grise, rempli et signé',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'mandat-immatriculation',
          name: 'Mandat d\'immatriculation (CERFA 13757*03)',
          description: 'Nous autorisant à effectuer les démarches de carte grise pour vous',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'vehicule',
      title: 'Documents du véhicule',
      documents: [
        {
          id: 'carte-grise-ancien',
          name: 'Carte grise de l\'ancien propriétaire',
          description: 'Carte grise du défunt (recto/verso)',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'certificat-cession',
          name: 'Certificat de cession (CERFA N°15776*01)',
          description: 'Rempli et signé par les héritiers ou le notaire',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'controle-technique',
          name: 'Contrôle technique',
          description: 'De moins de 6 mois pour les véhicules de plus de 4 ans',
          required: true,
          conditionalText: 'Requis si le véhicule a plus de 4 ans',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'attestation-assurance',
          name: 'Attestation d\'assurance',
          description: 'En cours de validité ou mémo',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'succession',
      title: 'Documents de succession',
      documents: [
        {
          id: 'certificat-deces',
          name: 'Certificat de décès',
          description: 'Certificat de décès du propriétaire décédé',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'preuve-heredite',
          name: 'Justificatif de la qualité d\'héritier',
          description: 'Certificat d\'hérédité (mairie) ou acte de notoriété (notaire)',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'desistement-coheritiers',
          name: 'Lettre de désistement des cohéritiers',
          description: 'Lettre signée par tous les autres héritiers renonçant à leurs droits sur le véhicule',
          required: false,
          conditionalText: 'Requis en cas de cohéritiers',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'identite',
      title: 'Justificatifs d\'identité',
      documents: [
        {
          id: 'piece-identite',
          name: 'Pièce d\'identité',
          description: 'CNI recto/verso, passeport ou titre de séjour en cours de validité',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'permis-conduire',
          name: 'Permis de conduire',
          description: 'Recto/verso du permis de conduire du demandeur',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'domicile',
      title: 'Justificatif de domicile',
      documents: [
        {
          id: 'justificatif-domicile',
          name: 'Justificatif de domicile',
          description: 'De moins de 6 mois (facture énergie, télécom, quittance de loyer, attestation d\'assurance habitation, avis d\'imposition)',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    }
  ],
  'vehicule-importe': [
    {
      id: 'formulaires',
      title: 'Formulaires obligatoires',
      documents: [
        {
          id: 'cerfa-13750',
          name: 'Demande de certificat d\'immatriculation (CERFA 13750*07)',
          description: 'Document OBLIGATOIRE pour toute demande de carte grise, rempli et signé',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'mandat-immatriculation',
          name: 'Mandat d\'immatriculation (CERFA 13757*03)',
          description: 'Nous autorisant à effectuer les démarches de carte grise pour vous',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'vehicule',
      title: 'Documents du véhicule',
      documents: [
        {
          id: 'carte-grise-ancien',
          name: 'Carte grise de l\'ancien propriétaire',
          description: 'Barrée et mentionnée avec la date et la signature du vendeur (recto/verso)',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'certificat-cession',
          name: 'Certificat de cession ou facture',
          description: 'Facture d\'achat du véhicule ou Certificat de cession (CERFA N°15776*01)',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'certificat-conformite',
          name: 'Certificat de conformité européen (COC)',
          description: 'Délivré par le constructeur ou le concessionnaire, attestant que le véhicule respecte les normes UE',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'quitus-fiscal',
          name: 'Quitus fiscal',
          description: 'Délivré par le service des impôts ou formulaire CERFA 846A fourni par les douanes',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'controle-technique',
          name: 'Contrôle technique',
          description: 'De moins de 6 mois pour les véhicules de plus de 4 ans',
          required: true,
          conditionalText: 'Requis si le véhicule a plus de 4 ans',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'attestation-assurance',
          name: 'Attestation d\'assurance',
          description: 'En cours de validité ou mémo',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'identite',
      title: 'Justificatifs d\'identité',
      documents: [
        {
          id: 'piece-identite',
          name: 'Pièce d\'identité',
          description: 'CNI recto/verso, passeport ou titre de séjour en cours de validité',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'permis-conduire',
          name: 'Permis de conduire',
          description: 'Recto/verso du permis de conduire du demandeur',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'domicile',
      title: 'Justificatif de domicile',
      documents: [
        {
          id: 'justificatif-domicile',
          name: 'Justificatif de domicile',
          description: 'De moins de 6 mois (facture énergie, télécom, quittance de loyer, attestation d\'assurance habitation, avis d\'imposition)',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'societe',
      title: 'Documents supplémentaires pour une société',
      documents: [
        {
          id: 'kbis',
          name: 'Extrait Kbis',
          description: 'De moins de 6 mois',
          required: false,
          conditionalText: 'Requis si le demandeur est une société',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'piece-identite-gerant',
          name: 'Pièce d\'identité du gérant',
          description: 'CNI, passeport ou titre de séjour en cours de validité du gérant',
          required: false,
          conditionalText: 'Requis si le demandeur est une société',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    }
  ],
  'vehicule-neuf-importe': [
    {
      id: 'formulaires',
      title: 'Formulaires obligatoires',
      documents: [
        {
          id: 'cerfa-immatriculation-neuf',
          name: 'Demande de certificat d\'immatriculation (CERFA 13749*05)',
          description: 'Document OBLIGATOIRE pour toute demande de carte grise d\'un véhicule neuf, rempli et signé',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'mandat-immatriculation',
          name: 'Mandat d\'immatriculation (CERFA 13757*03)',
          description: 'Nous autorisant à effectuer les démarches de carte grise pour vous',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'documents-vehicule',
      title: 'Documents du véhicule',
      documents: [
        {
          id: 'certificat-vente',
          name: 'Certificat de vente ou facture d\'achat',
          description: 'Certificat de vente délivré par le concessionnaire/vendeur ou facture d\'achat du véhicule',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'certificat-conformite',
          name: 'Certificat de conformité européen (COC)',
          description: 'Certificat de conformité délivré par le constructeur ou le concessionnaire, attestant que le véhicule respecte les normes UE. Pour les véhicules hors UE, un certificat spécifique peut être requis.',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'quitus-fiscal',
          name: 'Quitus fiscal',
          description: 'Quitus fiscal délivré par le service des impôts ou formulaire CERFA 846A fourni par les douanes',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'attestation-assurance',
          name: 'Attestation d\'assurance',
          description: 'Attestation d\'assurance en cours de validité ou mémo',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'documents-identite',
      title: 'Documents d\'identité',
      documents: [
        {
          id: 'piece-identite',
          name: 'Pièce d\'identité',
          description: 'CNI recto/verso, passeport ou titre de séjour en cours de validité',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'permis-conduire',
          name: 'Permis de conduire',
          description: 'Permis de conduire recto/verso du demandeur',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'justificatif-domicile',
          name: 'Justificatif de domicile',
          description: 'Justificatif de domicile de moins de 6 mois (facture EDF, téléphone, quittance de loyer...)',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'documents-societe',
      title: 'Documents supplémentaires pour une société',
      documents: [
        {
          id: 'kbis',
          name: 'Kbis',
          description: 'Un Kbis de moins de 6 mois',
          required: false,
          conditionalText: 'Document requis si le demandeur est une société',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    }
  ],
  'w-provisoire': [
    {
      id: 'formulaires',
      title: 'Formulaires obligatoires',
      documents: [
        {
          id: 'cerfa-immatriculation',
          name: 'Demande de certificat d\'immatriculation',
          description: 'CERFA 13750*07 pour véhicule d\'occasion ou CERFA 13749*05 pour véhicule neuf, rempli et signé',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'mandat-immatriculation',
          name: 'Mandat d\'immatriculation (CERFA 13757*03)',
          description: 'Nous autorisant à effectuer les démarches de carte grise pour vous',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'vehicule',
      title: 'Documents du véhicule',
      documents: [
        {
          id: 'carte-grise-etranger',
          name: 'Carte grise étrangère',
          description: 'Si le véhicule a déjà été immatriculé dans un autre pays',
          required: false,
          conditionalText: 'Requis si le véhicule était immatriculé à l\'étranger',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'certificat-vente',
          name: 'Certificat de vente ou facture',
          description: 'Certificat de vente du concessionnaire, facture d\'achat ou certificat de cession (CERFA N°15776*01)',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'certificat-conformite',
          name: 'Certificat de conformité européen (COC)',
          description: 'Délivré par le constructeur ou le concessionnaire. Pour les véhicules hors UE, un certificat spécifique peut être requis.',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'quitus-fiscal',
          name: 'Quitus fiscal',
          description: 'Délivré par le service des impôts ou formulaire CERFA 846A fourni par les douanes',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'attestation-assurance',
          name: 'Attestation d\'assurance',
          description: 'En cours de validité ou mémo',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'identite',
      title: 'Justificatifs d\'identité',
      documents: [
        {
          id: 'piece-identite',
          name: 'Pièce d\'identité',
          description: 'CNI recto/verso, passeport ou titre de séjour en cours de validité',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'permis-conduire',
          name: 'Permis de conduire',
          description: 'Recto/verso du permis de conduire du demandeur',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'domicile',
      title: 'Justificatif de domicile',
      documents: [
        {
          id: 'justificatif-domicile',
          name: 'Justificatif de domicile',
          description: 'De moins de 6 mois (facture énergie, télécom, quittance de loyer, attestation d\'assurance habitation, avis d\'imposition)',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'societe',
      title: 'Documents supplémentaires pour une société',
      documents: [
        {
          id: 'kbis',
          name: 'Extrait Kbis',
          description: 'De moins de 6 mois',
          required: false,
          conditionalText: 'Requis si le demandeur est une société',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'piece-identite-gerant',
          name: 'Pièce d\'identité du gérant',
          description: 'CNI, passeport ou titre de séjour en cours de validité du gérant',
          required: false,
          conditionalText: 'Requis si le demandeur est une société',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    }
  ],
  'premiere-immatriculation': [
    {
      id: 'formulaires',
      title: 'Formulaires obligatoires',
      documents: [
        {
          id: 'cerfa-13750',
          name: 'Demande d\'immatriculation (CERFA 13750*07)',
          description: 'Document OBLIGATOIRE pour toute demande de carte grise, rempli et signé',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'mandat-immatriculation',
          name: 'Mandat d\'immatriculation (CERFA 13757*03)',
          description: 'Nous autorisant à effectuer les démarches de carte grise pour vous',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'identite',
      title: 'Justificatifs d\'identité',
      documents: [
        {
          id: 'piece-identite',
          name: 'Pièce d\'identité',
          description: 'CNI recto/verso, passeport ou titre de séjour en cours de validité',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'permis-conduire',
          name: 'Permis de conduire',
          description: 'Recto/verso du permis de conduire du demandeur',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'domicile',
      title: 'Justificatif de domicile',
      documents: [
        {
          id: 'justificatif-domicile',
          name: 'Justificatif de domicile',
          description: 'De moins de 6 mois (facture énergie, télécom, quittance de loyer, attestation d\'assurance habitation, avis d\'imposition)',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'vehicule',
      title: 'Documents du véhicule',
      documents: [
        {
          id: 'certificat-immatriculation-etranger',
          name: 'Certificat d\'immatriculation étranger original',
          description: 'Original du titre de circulation étranger',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'quitus-fiscal',
          name: 'Quitus fiscal',
          description: 'Document délivré par les services fiscaux pour les véhicules provenant de l\'étranger',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'certificat-conformite',
          name: 'Certificat de conformité européen',
          description: 'Document attestant que le véhicule répond aux normes européennes',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    }
  ],
  'changement-proprietaire': [
    {
      id: 'formulaires',
      title: 'Formulaires obligatoires',
      documents: [
        {
          id: 'cerfa-13750',
          name: 'Demande de certificat d\'immatriculation (CERFA 13750*07)',
          description: 'Document OBLIGATOIRE pour toute demande de carte grise, rempli et signé',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'mandat-immatriculation',
          name: 'Mandat d\'immatriculation (CERFA 13757*03)',
          description: 'Nous autorisant à effectuer les démarches de carte grise pour vous',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'certificat-cession',
          name: 'Certificat de cession (CERFA N°15776*01)',
          description: 'Rempli et signé par l\'ancien propriétaire et l\'acquéreur',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'vehicule',
      title: 'Documents du véhicule',
      documents: [
        {
          id: 'certificat-immatriculation',
          name: 'Carte grise de l\'ancien propriétaire',
          description: 'Barrée et mentionnée avec la date et la signature du vendeur (recto/verso), et tamponnée si vendeur professionnel',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        },
        {
          id: 'controle-technique',
          name: 'Contrôle technique',
          description: 'De moins de 6 mois pour les véhicules de plus de 4 ans',
          required: true,
          conditionalText: 'Requis si le véhicule a plus de 4 ans',
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'identite',
      title: 'Justificatifs d\'identité',
      documents: [
        {
          id: 'piece-identite',
          name: 'Pièce d\'identité',
          description: 'CNI recto/verso, passeport ou titre de séjour en cours de validité',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    },
    {
      id: 'domicile',
      title: 'Justificatif de domicile',
      documents: [
        {
          id: 'justificatif-domicile',
          name: 'Justificatif de domicile',
          description: 'De moins de 6 mois (facture énergie, télécom, quittance de loyer, attestation d\'assurance habitation, avis d\'imposition)',
          required: true,
          acceptedFormats: ['.pdf', '.jpg', '.jpeg', '.png']
        }
      ]
    }
  ],
};
