import React, { useState, memo, useCallback, useEffect } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { 
  Box, 
  Button, 
  CircularProgress, 
  Typography,
  Container,
  Paper,
  Grid,
  Divider,
  Alert
} from '@mui/material';
import { Lock } from '@mui/icons-material';
import PaymentConfirmation from './PaymentConfirmation';
import { db } from '../firebase/config';
import { collection, addDoc, serverTimestamp, updateDoc, doc } from 'firebase/firestore';
import { generateOrderNumber } from '../utils/orderUtils';
import { initiatePayment } from '../services/paymentService';
import { sendOrderConfirmationEmail } from '../services/emailService';
import { createAutoAccount } from '../services/autoAccountService';
import { checkEmailExists } from '../services/userService';
import toast from 'react-hot-toast';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { DOCUMENT_GROUPS } from '../config/documentGroups';

interface PaymentFormProps {
  orderId?: string;
  amount?: number;
  orderType?: string;
  vehiculeDetails?: any;
  userDetails?: any;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
  onProcessingChange?: (isProcessing: boolean) => void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  orderId = '',
  amount = 0,
  orderType = '',
  vehiculeDetails = null,
  userDetails = null,
  onSuccess = () => {},
  onError = () => {},
  onProcessingChange = () => {}
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState('');
  const [currentOrderNumber, setCurrentOrderNumber] = useState('');
  const { user } = useAuth();
  const navigate = useNavigate();
  const [storedVehiculeDetails, setStoredVehiculeDetails] = useState<any>(null);
  const [storedUserDetails, setStoredUserDetails] = useState<any>(null);

  useEffect(() => {
    const storedVehiculeData = localStorage.getItem('vehiculeData');
    const storedFormData = localStorage.getItem('formData');

    if (storedFormData) {
      try {
        const formData = JSON.parse(storedFormData);
        setStoredUserDetails(formData);

        if (storedVehiculeData) {
          const parsedVehiculeData = JSON.parse(storedVehiculeData);
          setStoredVehiculeDetails(parsedVehiculeData);
        }
      } catch (error) {
        console.error('Erreur lors du chargement des données:', error);
      }
    }
  }, []);

  const createOrder = async (paymentStatus: string = 'pending') => {
    try {
      const storedFormData = localStorage.getItem('formData');
      const formData = storedFormData ? JSON.parse(storedFormData) : null;

      if (!formData) {
        throw new Error('Données du formulaire manquantes');
      }

      // Générer le numéro de commande avant la création du compte
      const orderNumber = generateOrderNumber();
      setCurrentOrderNumber(orderNumber);

      // Si l'utilisateur n'est pas connecté, créer un compte automatiquement
      let userId = user?.uid;
      if (!userId) {
        try {
          const result = await createAutoAccount({
            email: formData.email.toLowerCase(), // Stocker l'email en minuscules
            firstName: formData.firstName || formData.prenom,
            lastName: formData.lastName || formData.nom,
            orderNumber: orderNumber,
          });

          if (result.user) {
            userId = result.user.uid;
          }
          // Si result.user est null, on continue sans userId
          // L'utilisateur pourra se connecter plus tard
        } catch (error) {
          console.error('Erreur lors de la création/connexion du compte:', error);
          // On continue le processus même si la création du compte échoue
        }
      }

      const orderData = {
        orderNumber: orderNumber,
        userId: userId || null, // userId peut être null
        email: formData.email.toLowerCase(), // Stocker l'email en minuscules
        emailOriginal: formData.email, // Garder l'email original pour l'affichage
        status: paymentStatus,
        amount: amount,
        orderType: orderType,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        vehiculeDetails: vehiculeDetails || storedVehiculeDetails,
        userDetails: userDetails || storedUserDetails || formData,
        documentStatus: Object.keys(DOCUMENT_GROUPS).reduce((acc: any, groupId) => {
          acc[groupId] = { status: 'pending', documents: {} };
          return acc;
        }, {}),
      };

      // Créer la commande dans Firestore
      const ordersRef = collection(db, 'orders');
      const orderRef = await addDoc(ordersRef, orderData);
      setCurrentOrderId(orderRef.id);

      // Envoyer l'email de confirmation
      await sendOrderConfirmationEmail({
        to: formData.email,
        orderNumber: orderNumber,
        clientName: `${formData.firstName || formData.prenom} ${formData.lastName || formData.nom}`,
        totalAmount: amount,
        requiredDocuments: DOCUMENT_GROUPS[orderType]?.map(group => 
          group.documents.map(doc => doc.name)
        ).flat() || [],
        vehiculeDetails: vehiculeDetails || storedVehiculeDetails
      });

      return { orderId: orderRef.id, orderNumber };
    } catch (error) {
      console.error('Erreur lors de la création de la commande:', error);
      throw error;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsProcessing(true);

    try {
      if (!stripe || !elements) {
        throw new Error('Stripe n\'est pas initialisé');
      }

      // 1. Valider le formulaire
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw submitError;
      }

      // 2. Créer la commande
      const { orderId, orderNumber } = await createOrder();

      // 3. Initier le paiement
      const { clientSecret } = await initiatePayment({
        amount,
        orderId,
        orderType,
        email: storedUserDetails?.email || '',
        orderNumber,
      });

      // 4. Confirmer le paiement (sans redirection)
      const { error: confirmError, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: `${storedUserDetails?.firstName || ''} ${storedUserDetails?.lastName || ''}`.trim(),
              email: storedUserDetails?.email || '',
              address: {
                city: storedUserDetails?.city || '',
                postal_code: storedUserDetails?.postalCode || '',
                line1: storedUserDetails?.address || ''
              }
            }
          },
          return_url: window.location.origin + '/payment-confirmation'
        },
        clientSecret,
        redirect: 'if_required', // Ne redirige que si 3D Secure est nécessaire
      });

      if (confirmError) {
        throw confirmError;
      }

      // 5. Mettre à jour le statut de la commande
      await updateDoc(doc(db, 'orders', orderId), {
        status: 'completed',
        paymentIntentId: paymentIntent?.id
      });

      // 6. Afficher la confirmation
      setShowConfirmation(true);

    } catch (error) {
      console.error('Erreur lors du paiement:', error);
      onError(error as Error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
    navigate(`/dashboard?order=${currentOrderId}`);
  };

  if (showConfirmation) {
    return (
      <PaymentConfirmation
        orderId={currentOrderId}
        orderNumber={currentOrderNumber}
        amount={amount}
        orderType={orderType}
        vehicule={storedVehiculeDetails}
        onClose={handleConfirmationClose}
      />
    );
  }

  return (
    <Container 
      maxWidth={false}
      sx={{ 
        width: '100%',
        maxWidth: '100%',
        p: 0,
        mx: 'auto'
      }}
    >
      <Paper 
        elevation={0}
        sx={{ 
          p: { xs: 2, sm: 3 },
          borderRadius: 0,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 3
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box sx={{ 
                '.StripeElement': {
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: 1,
                  p: 3,
                  width: '100%',
                  '&--focus': {
                    border: '2px solid #1976d2'
                  }
                }
              }}>
                <PaymentElement options={{
                  layout: {
                    type: 'tabs',
                    defaultCollapsed: false,
                    spacedAccordionItems: true
                  },
                  business: {
                    name: 'Carte Grise'
                  },
                  paymentMethodOrder: ['card']
                }} />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isProcessing}
                fullWidth
                size="large"
                startIcon={isProcessing ? <CircularProgress size={20} /> : <Lock />}
                sx={{ 
                  py: 2,
                  fontSize: '1.1rem'
                }}
              >
                {isProcessing ? 'Traitement en cours...' : 'Payer maintenant'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default PaymentForm;