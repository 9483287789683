import React, { useState } from 'react';
import { Search, X } from 'lucide-react';

interface SearchBarProps {
  onSearch: (query: string, type: string, status: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [query, setQuery] = useState('');
  const [searchType, setSearchType] = useState('all');
  const [status, setStatus] = useState('all');

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch(query, searchType, status);
  };

  const clearSearch = () => {
    setQuery('');
    onSearch('', searchType, status);
  };

  return (
    <form onSubmit={handleSearch} className="flex gap-2">
      <div className="relative flex-1">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <Search className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="block w-full pl-10 pr-12 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
          placeholder="Rechercher..."
        />
        {query && (
          <button
            type="button"
            onClick={clearSearch}
            className="absolute inset-y-0 right-0 pr-3 flex items-center"
          >
            <X className="h-5 w-5 text-gray-400 hover:text-gray-600" />
          </button>
        )}
      </div>

      <select
        value={searchType}
        onChange={(e) => setSearchType(e.target.value)}
        className="rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500"
      >
        <option value="all">Tout</option>
        <option value="client">Client</option>
        <option value="order">N° Commande</option>
        <option value="plate">Immatriculation</option>
      </select>

      <select
        value={status}
        onChange={(e) => {
          setStatus(e.target.value);
          onSearch(query, searchType, e.target.value);
        }}
        className="rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500"
      >
        <option value="all">Tous les statuts</option>
        <option value="pending">En cours</option>
        <option value="validated">Validée</option>
        <option value="rejected">Rejetée</option>
      </select>

      <button
        type="submit"
        className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
      >
        Rechercher
      </button>
    </form>
  );
};

export default SearchBar;