import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tag, Lock } from 'lucide-react';
import LoginModal from './LoginModal';
import { toast } from 'react-hot-toast';
import { useAuth } from '../context/AuthContext';

const CheckoutForm = () => {
  const navigate = useNavigate();
  const { userProfile, updateProfile } = useAuth();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isPromoModalOpen, setIsPromoModalOpen] = useState(false);
  const [titulaire, setTitulaire] = useState<'particulier' | 'societe'>(
    userProfile?.raisonSociale ? 'societe' : 'particulier'
  );
  const [civilite, setCivilite] = useState<'monsieur' | 'madame'>(
    userProfile?.civilite || 'monsieur'
  );
  const [orderData, setOrderData] = useState<any>(null);

  useEffect(() => {
    const storedData = localStorage.getItem('orderData');
    const vehiculeData = localStorage.getItem('vehiculeData');
    if (storedData) {
      const parsedOrderData = JSON.parse(storedData);
      const parsedVehiculeData = vehiculeData ? JSON.parse(vehiculeData) : null;
      console.log('Données de commande chargées:', parsedOrderData);
      console.log('Données du véhicule chargées:', parsedVehiculeData);
      setOrderData(parsedOrderData);
    }
  }, []);

  // Form fields
  const [formData, setFormData] = useState({
    nom: userProfile?.nom || '',
    prenom: userProfile?.prenom || '',
    raisonSociale: userProfile?.raisonSociale || '',
    siret: userProfile?.siret || '',
    email: userProfile?.email || '',
    telephone: userProfile?.telephone || '',
    adresse: userProfile?.adresse || '',
    complementAdresse: userProfile?.complementAdresse || '',
    codePostal: userProfile?.codePostal || '',
    ville: userProfile?.ville || ''
  });

  // Update form data when userProfile changes
  useEffect(() => {
    if (userProfile) {
      setFormData({
        nom: userProfile.nom || '',
        prenom: userProfile.prenom || '',
        raisonSociale: userProfile.raisonSociale || '',
        siret: userProfile.siret || '',
        email: userProfile.email || '',
        telephone: userProfile.telephone || '',
        adresse: userProfile.adresse || '',
        complementAdresse: userProfile.complementAdresse || '',
        codePostal: userProfile.codePostal || '',
        ville: userProfile.ville || ''
      });
      
      if (userProfile.raisonSociale) {
        setTitulaire('societe');
      }
      
      if (userProfile.civilite) {
        setCivilite(userProfile.civilite);
      }
    }
  }, [userProfile]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      // Sauvegarder les informations du client
      if (userProfile) {
        await updateProfile({
          ...formData,
          civilite,
          type: titulaire
        });
      }

      // Récupérer les informations du véhicule
      const vehiculeData = JSON.parse(localStorage.getItem('vehiculeData') || '{}');

      // Préparer les données complètes pour le paiement
      const clientData = {
        firstName: formData.prenom,
        lastName: formData.nom,
        address: formData.adresse,
        complementAddress: formData.complementAdresse,
        city: formData.ville,
        postalCode: formData.codePostal,
        email: formData.email,
        phone: formData.telephone,
        type: titulaire,
        civilite: civilite,
        userId: userProfile?.uid || null
      };

      if (titulaire === 'societe') {
        clientData['companyName'] = formData.raisonSociale;
        clientData['siret'] = formData.siret;
      }

      // Stocker les informations complètes
      localStorage.setItem('formData', JSON.stringify(clientData));
      
      // Générer un ID de commande unique
      const orderId = `HS${new Date().getFullYear().toString().substr(-2)}${
        String(new Date().getMonth() + 1).padStart(2, '0')}${
        String(new Date().getDate()).padStart(2, '0')}${
        Math.floor(Math.random() * 10000).toString().padStart(4, '0')}`;

      // Stocker les informations de la commande
      const orderInfo = {
        orderId,
        amount: orderData?.totalAmount || 0,
        orderType: vehiculeData.type || 'declaration-cession',
        clientData,
        vehiculeData
      };
      localStorage.setItem('orderInfo', JSON.stringify(orderInfo));

      // Naviguer vers la page de paiement
      navigate('/payment', { 
        state: { 
          amount: orderData?.totalAmount || 0,
          orderId: orderId,
          orderType: vehiculeData.type || 'declaration-cession'
        } 
      });
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire:', error);
      toast.error('Une erreur est survenue lors de la soumission du formulaire');
    }
  };

  return (
    <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg">
      {/* En-tête */}
      <div className="bg-blue-600 text-white p-6 rounded-t-xl">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold">Finalisation de votre commande</h1>
            <p className="text-blue-100">
              Remplissez le formulaire ci-dessous pour obtenir votre carte grise
            </p>
          </div>
          <div className="flex gap-4">
            {!userProfile && (
              <button 
                onClick={() => setIsLoginModalOpen(true)}
                className="flex items-center gap-2 bg-white/10 hover:bg-white/20 px-4 py-2 rounded-lg text-sm font-medium transition-colors"
              >
                <Tag className="w-4 h-4" />
                <span>Déjà client ?</span>
              </button>
            )}
            <button 
              onClick={() => setIsPromoModalOpen(true)}
              className="flex items-center gap-2 bg-white/10 hover:bg-white/20 px-4 py-2 rounded-lg text-sm font-medium transition-colors"
            >
              <Lock className="w-4 h-4" />
              <span>Code promo</span>
            </button>
          </div>
        </div>
      </div>

      {/* Formulaire */}
      <form onSubmit={handleSubmit} className="p-6">
        <div className="space-y-6">
          {/* Identité du nouveau titulaire */}
          <div>
            <h2 className="text-lg font-semibold mb-4">Identité du nouveau titulaire</h2>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <button 
                type="button"
                onClick={() => setTitulaire('particulier')}
                className={`p-4 rounded-lg transition-all duration-200 flex items-center justify-center font-medium ${
                  titulaire === 'particulier' 
                    ? 'bg-blue-600 text-white shadow-lg scale-[1.02] border-2 border-blue-600' 
                    : 'bg-white border-2 border-gray-200 text-gray-600 hover:border-blue-400 hover:text-blue-600'
                }`}
              >
                Particulier
              </button>
              <button 
                type="button"
                onClick={() => setTitulaire('societe')}
                className={`p-4 rounded-lg transition-all duration-200 flex items-center justify-center font-medium ${
                  titulaire === 'societe' 
                    ? 'bg-blue-600 text-white shadow-lg scale-[1.02] border-2 border-blue-600' 
                    : 'bg-white border-2 border-gray-200 text-gray-600 hover:border-blue-400 hover:text-blue-600'
                }`}
              >
                Société
              </button>
            </div>
            {titulaire === 'particulier' && (
              <div className="grid grid-cols-2 gap-4 mb-4">
                <button 
                  type="button"
                  onClick={() => setCivilite('monsieur')}
                  className={`p-4 rounded-lg transition-all duration-200 flex items-center justify-center font-medium ${
                    civilite === 'monsieur' 
                      ? 'bg-blue-600 text-white shadow-lg scale-[1.02] border-2 border-blue-600' 
                      : 'bg-white border-2 border-gray-200 text-gray-600 hover:border-blue-400 hover:text-blue-600'
                  }`}
                >
                  Monsieur
                </button>
                <button 
                  type="button"
                  onClick={() => setCivilite('madame')}
                  className={`p-4 rounded-lg transition-all duration-200 flex items-center justify-center font-medium ${
                    civilite === 'madame' 
                      ? 'bg-blue-600 text-white shadow-lg scale-[1.02] border-2 border-blue-600' 
                      : 'bg-white border-2 border-gray-200 text-gray-600 hover:border-blue-400 hover:text-blue-600'
                  }`}
                >
                  Madame
                </button>
              </div>
            )}
          </div>

          {/* Informations personnelles */}
          <div className="grid grid-cols-2 gap-4">
            {titulaire === 'societe' ? (
              <>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Raison sociale*
                  </label>
                  <input 
                    type="text" 
                    name="raisonSociale"
                    value={formData.raisonSociale}
                    onChange={handleInputChange}
                    className="w-full p-3 border rounded-lg" 
                    required 
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    SIRET*
                  </label>
                  <input 
                    type="text" 
                    name="siret"
                    value={formData.siret}
                    onChange={handleInputChange}
                    className="w-full p-3 border rounded-lg" 
                    required 
                  />
                </div>
              </>
            ) : (
              <>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Nom*
                  </label>
                  <input 
                    type="text" 
                    name="nom"
                    value={formData.nom}
                    onChange={handleInputChange}
                    className="w-full p-3 border rounded-lg" 
                    required 
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Prénom*
                  </label>
                  <input 
                    type="text" 
                    name="prenom"
                    value={formData.prenom}
                    onChange={handleInputChange}
                    className="w-full p-3 border rounded-lg" 
                    required 
                  />
                </div>
              </>
            )}
          </div>

          {/* Contact */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">
                Email*
              </label>
              <input 
                type="email" 
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-3 border rounded-lg" 
                required 
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">
                Téléphone*
              </label>
              <input 
                type="tel" 
                name="telephone"
                value={formData.telephone}
                onChange={handleInputChange}
                className="w-full p-3 border rounded-lg" 
                required 
              />
            </div>
          </div>

          {/* Adresse */}
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">
                Adresse*
              </label>
              <input 
                type="text" 
                name="adresse"
                value={formData.adresse}
                onChange={handleInputChange}
                className="w-full p-3 border rounded-lg" 
                required 
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">
                Complément d'adresse
              </label>
              <input 
                type="text" 
                name="complementAdresse"
                value={formData.complementAdresse}
                onChange={handleInputChange}
                className="w-full p-3 border rounded-lg" 
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">
                  Code postal*
                </label>
                <input 
                  type="text" 
                  name="codePostal"
                  value={formData.codePostal}
                  onChange={handleInputChange}
                  className="w-full p-3 border rounded-lg" 
                  required 
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Ville*
                </label>
                <input 
                  type="text" 
                  name="ville"
                  value={formData.ville}
                  onChange={handleInputChange}
                  className="w-full p-3 border rounded-lg" 
                  required 
                />
              </div>
            </div>
          </div>

          {/* Cases à cocher */}
          <div className="space-y-4 mt-6">
            <div className="flex items-start">
              <input
                type="checkbox"
                id="certificationInfo"
                className="mt-1 mr-3"
                required
              />
              <label htmlFor="certificationInfo" className="text-sm text-gray-600">
                Je certifie sur l'honneur que les informations ci-dessus sont exactes et je reconnais être informé(e) qu'une fausse déclaration m'expose notamment aux sanctions pénales prévues par l'article 441-1 du Code Pénal
              </label>
            </div>

            <div className="flex items-start">
              <input
                type="checkbox"
                id="termsConditions"
                className="mt-1 mr-3"
                required
              />
              <label htmlFor="termsConditions" className="text-sm text-gray-600">
                J'ai lu et j'accepte les <a href="https://minute-cartegrise.com/cgv/" className="text-blue-600 hover:underline" target="_blank">conditions générales de vente</a>
              </label>
            </div>

            <div className="flex items-start">
              <input
                type="checkbox"
                id="newsletter"
                className="mt-1 mr-3"
              />
              <label htmlFor="newsletter" className="text-sm text-gray-600">
                Je souhaite recevoir des informations et offres promotionnelles de la part de MinuteCarteGrise
              </label>
            </div>
          </div>

          <div className="flex justify-end mt-6">
            <button
              type="submit"
              className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
            >
              Continuer vers le paiement
            </button>
          </div>
        </div>
      </form>

      <LoginModal 
        isOpen={isLoginModalOpen} 
        onClose={() => setIsLoginModalOpen(false)}
        onSignUpClick={() => {}}
      />
    </div>
  );
};

export default CheckoutForm;