const firebaseConfig = {
  apiKey: "AIzaSyC8yrdJkTd7qtnM38deC9M0pUiVQsXYyoU",
  authDomain: "minutecartegrise-c3390.firebaseapp.com",
  projectId: "minutecartegrise-c3390",
  storageBucket: "minutecartegrise-c3390.firebasestorage.app",
  messagingSenderId: "953650190317",
  appId: "1:953650190317:web:143e27d5b48a83f56679e4"
};

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { 
  getFirestore, 
  enableMultiTabIndexedDbPersistence, 
  collection, 
  doc, 
  getDoc, 
  setDoc, 
  updateDoc,
  serverTimestamp 
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Initialize Firebase services
export const db = getFirestore(app);
export const storage = getStorage(app, undefined, {
  customDomain: 'firebasestorage.googleapis.com'
});

// Enable multi-tab persistence
enableMultiTabIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      console.log('Multiple tabs open, persistence can only be enabled in one tab at a a time.');
    } else if (err.code === 'unimplemented') {
      console.log('The current browser does not support all of the features required to enable persistence');
    }
  });

export {
  app,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
  firebaseConfig
};