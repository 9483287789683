import { PROCESSING_FEES } from './pricingConfig';
import { departements } from '../data/departements';
import regionalRates from '../data/regional-rates.json';

// Configuration des taxes régionales
const getTaxeRegionale = (codeDepartement: string): number => {
  // Trouver la région correspondant au département
  const departement = departements.find(d => d.code === codeDepartement);
  if (!departement) {
    return 44.00; // Valeur par défaut si département non trouvé
  }

  // Trouver le taux correspondant à la région
  const regionRate = regionalRates.taxeRegionale.find(r => r.region === departement.region);
  if (!regionRate) {
    return 44.00; // Valeur par défaut si région non trouvée
  }

  return regionRate.tauxUnitaire;
};

// Configurations pour les véhicules propres
const VEHICULES_PROPRES_CONFIG = {
  exonerationTaxeRegionale: {
    regions: ["Île-de-France"], // Régions qui n'appliquent plus l'exonération
    reductionPourcentage: 50 // Réduction standard pour les autres régions
  }
};

const MOCK_PROCEDURES = {
  types: {
    "changement-proprietaire": { taxeFixe: 11.00 },
    "changement-adresse": { taxeFixe: 0, gratuitJusquaTrois: true },
    "duplicata": { taxeFixe: 11.00 },
    "premiere-immatriculation": { 
      taxeFixe: 11.00,
      appliquerTMOM: true,
      appliquerMalusCO2: true
    },
    "declaration-cession": { taxeFixe: 0 },
    "heritage": { 
      taxeFixe: 0,
      gratuit: true,
      acheminement: 2.76
    }
  }
};

const TMOM_CONFIG = {
  seuilPoids: 1800, // kg
  tarifParKg: 10, // € par kg au-dessus du seuil
  vehiculesExemptes: ['electrique', 'hydrogene', 'hybride-rechargeable']
};

const VEHICULES_PROPRES = ['electrique', 'hydrogene', 'hybride-rechargeable'];

// Fonction d'arrondi spécifique pour les taxes
const roundTaxe = (value: number): number => {
  // Arrondir à l'euro supérieur pour Y1 (taxe régionale)
  return Math.ceil(value);
};

// Fonction d'arrondi standard pour les autres montants
const roundToTwoDecimals = (value: number): number => {
  return Math.round(value * 100) / 100;
};

const formatMoney = (value: number): string => {
  // Arrondir à 2 décimales
  const roundedValue = roundToTwoDecimals(value);
  
  // Convertir en chaîne avec virgule comme séparateur décimal
  const [integerPart, decimalPart = '00'] = roundedValue.toString().split('.');
  
  // Ajouter les espaces comme séparateurs de milliers
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  
  // Combiner avec le symbole €
  return `${formattedIntegerPart},${decimalPart.padEnd(2, '0')} €`;
};

export const calculateCartGrise = async (params: {
  demarche: string;
  departement: string;
  puissanceFiscale: number;
  co2: number;
  datePremiereImmatriculation: Date;
  energie: string;
  genre: string;
  poids?: number;
}) => {
  try {
    // Récupération de la taxe régionale du département
    const tauxRegional = getTaxeRegionale(params.departement);
    let y1 = roundTaxe(tauxRegional * params.puissanceFiscale);

    // Pour l'héritage, toutes les taxes sont à 0 sauf l'acheminement
    if (params.demarche === 'heritage') {
      y1 = 0;
    }

    // Vérification si le véhicule est propre et si la région applique encore l'exonération
    const isVehiculePropre = VEHICULES_PROPRES.includes(params.energie);
    const departement = departements.find(d => d.code === params.departement);
    const regionExonere = !VEHICULES_PROPRES_CONFIG.exonerationTaxeRegionale.regions.includes(departement?.region || '');

    // Exonération de Y1 pour les véhicules propres selon la région
    if (params.demarche === 'premiere-immatriculation' && isVehiculePropre && regionExonere) {
      y1 = 0;
    }

    // Pour le changement d'adresse ou duplicata, pas de taxe régionale
    if (params.demarche === 'changement-adresse' || params.demarche === 'duplicata') {
      y1 = 0;
    } else if (params.demarche !== 'heritage') {
      // Réduction de 50% pour les véhicules de plus de 10 ans
      let age = 0;
      try {
        // Vérifier si la date est valide
        if (params.datePremiereImmatriculation instanceof Date && !isNaN(params.datePremiereImmatriculation.getTime())) {
          age = new Date().getFullYear() - params.datePremiereImmatriculation.getFullYear();
        } else {
          // Si la date n'est pas valide, on considère que le véhicule a plus de 10 ans
          console.warn('Date de première immatriculation invalide, on considère que le véhicule a plus de 10 ans');
          age = 11;
        }
      } catch (error) {
        console.warn('Erreur lors du calcul de l\'âge du véhicule, on considère qu\'il a plus de 10 ans:', error);
        age = 11;
      }

      if (age > 10) {
        console.log('Véhicule de plus de 10 ans, application de la réduction de 50%');
        y1 = roundToTwoDecimals(y1 * 0.5);
      }
    }

    // Majoration transport (Y2)
    // Vérifie si c'est un véhicule utilitaire (CTTE ou N1)
    console.log('Genre du véhicule pour Y2:', params.genre);
    const isVehiculeUtilitaire = ['CTTE', 'N1'].includes(params.genre.toUpperCase());
    console.log('Est un véhicule utilitaire:', isVehiculeUtilitaire);
    const y2 = params.demarche === 'heritage' ? 0 : (isVehiculeUtilitaire ? 34.00 : 0);
    console.log('Taxe Y2 calculée:', y2);
    console.log('Genre en majuscules:', params.genre.toUpperCase());
    console.log('Test inclusion CTTE:', params.genre.toUpperCase().includes('CTTE'));
    console.log('Test inclusion N1:', params.genre.toUpperCase().includes('N1'));

    // Malus CO2 (Y3)
    let y3 = 0;
    if (params.demarche !== 'heritage' && !isVehiculePropre && params.datePremiereImmatriculation >= new Date('2008-01-01')) {
      if (params.co2 > 117) {
        if (params.co2 <= 118) y3 = 50;
        else if (params.co2 <= 119) y3 = 75;
        else if (params.co2 <= 120) y3 = 100;
        else if (params.co2 <= 121) y3 = 125;
        else if (params.co2 <= 122) y3 = 150;
      }
    }

    // Taxe fixe (Y4)
    const y4 = roundToTwoDecimals(MOCK_PROCEDURES.types[params.demarche]?.taxeFixe || 11.00);

    // Redevance d'acheminement (Y5)
    const y5 = roundToTwoDecimals(2.76);

    // Total des taxes (Y6)
    const y6 = roundToTwoDecimals(y1 + y2 + y3 + y4 + y5);

    // Frais de traitement selon le type de démarche
    const processingFee = roundToTwoDecimals(PROCESSING_FEES[params.demarche]?.fee || 29.90);

    return {
      y1TaxeRegionale: y1,
      y2MajorationTransport: y2,
      y3MalusCO2: y3,
      y4TaxeFixe: y4,
      y5Acheminement: y5,
      y6TotalTaxes: y6,
      fraisDeGestion: processingFee,
      total: roundToTwoDecimals(y6 + processingFee),
      formattedValues: {
        y1TaxeRegionale: formatMoney(y1),
        y2MajorationTransport: formatMoney(y2),
        y3MalusCO2: formatMoney(y3),
        y4TaxeFixe: formatMoney(y4),
        y5Acheminement: formatMoney(y5),
        y6TotalTaxes: formatMoney(y6),
        fraisDeGestion: formatMoney(processingFee),
        total: formatMoney(roundToTwoDecimals(y6 + processingFee))
      }
    };

  } catch (error) {
    console.error('Erreur lors du calcul de la carte grise:', error);
    throw new Error('Impossible de calculer le coût de la carte grise');
  }
};

// Fonction de test pour vérifier les calculs
export const testCalculCartGrise = () => {
  // Test 1: Véhicule neuf en Île-de-France
  const testNeufIDF = calculateCartGrise({
    demarche: "premiere-immatriculation",
    departement: "75",
    puissanceFiscale: 7,
    co2: 140,
    datePremiereImmatriculation: new Date(),
    energie: "essence",
    genre: "Voiture particulière",
    poids: 1500
  });
  console.log("Test 1 - Véhicule neuf IDF:", testNeufIDF);

  // Test 2: Véhicule occasion > 10 ans en Occitanie
  const testOccasionOccitanie = calculateCartGrise({
    demarche: "changement-proprietaire",
    departement: "31",
    puissanceFiscale: 7,
    co2: 170,
    datePremiereImmatriculation: new Date(2012, 0, 1),
    energie: "essence",
    genre: "Voiture particulière",
    poids: 1500
  });
  console.log("Test 2 - Véhicule occasion Occitanie:", testOccasionOccitanie);

  // Test 3: Véhicule électrique neuf
  const testElectrique = calculateCartGrise({
    demarche: "premiere-immatriculation",
    departement: "69",
    puissanceFiscale: 5,
    co2: 0,
    datePremiereImmatriculation: new Date(),
    energie: "electrique",
    genre: "Voiture particulière",
    poids: 1900
  });
  console.log("Test 3 - Véhicule électrique:", testElectrique);
};

// Test pour la déclaration de cession
export const testDeclarationCession = async () => {
  const resultat = await calculateCartGrise({
    demarche: "declaration-cession",
    departement: "75", // Le département n'a pas d'importance pour une déclaration de cession
    puissanceFiscale: 5, // La puissance n'a pas d'importance pour une déclaration de cession
    co2: 0, // Le CO2 n'a pas d'importance pour une déclaration de cession
    datePremiereImmatriculation: new Date(),
    energie: "essence",
    genre: "Voiture particulière"
  });

  console.log("Test Déclaration de Cession:");
  console.log("Y1 (Taxe régionale):", resultat.y1TaxeRegionale);
  console.log("Y2 (Majoration transport):", resultat.y2MajorationTransport);
  console.log("Y3 (Malus CO2):", resultat.y3MalusCO2);
  console.log("Y4 (Taxe fixe):", resultat.y4TaxeFixe);
  console.log("Y5 (Acheminement):", resultat.y5Acheminement);
  console.log("Total taxes:", resultat.y6TotalTaxes);
  console.log("Frais de gestion:", resultat.fraisDeGestion);
  console.log("Total final:", resultat.total);
};

// Test pour l'héritage
export const testHeritage = async () => {
  // Test avec un véhicule de plus de 10 ans
  const resultatHeritage = await calculateCartGrise({
    demarche: "heritage",
    departement: "75", // Paris
    puissanceFiscale: 7,
    co2: 150,
    datePremiereImmatriculation: new Date(2010, 0, 1), // Véhicule de 2010
    energie: "essence",
    genre: "Voiture particulière"
  });

  console.log("Test Héritage d'un véhicule:");
  console.log("Y1 (Taxe régionale):", resultatHeritage.y1TaxeRegionale, "€");
  console.log("Y2 (Majoration transport):", resultatHeritage.y2MajorationTransport, "€");
  console.log("Y3 (Malus CO2):", resultatHeritage.y3MalusCO2, "€");
  console.log("Y4 (Taxe fixe):", resultatHeritage.y4TaxeFixe, "€");
  console.log("Y5 (Acheminement):", resultatHeritage.y5Acheminement, "€");
  console.log("Total taxes:", resultatHeritage.y6TotalTaxes, "€");
  console.log("Frais de gestion:", resultatHeritage.fraisDeGestion, "€");
  console.log("Total final:", resultatHeritage.total, "€");
};