import coutCertificatImmatriculation from '../data/cout-certificat-immatriculation.json';

interface FraisDeService {
  id: number;
  demarche: string;
  description: string;
  montant: number;
}

export function getFraisDeService(demarche: string): number {
  const fraisConfig = coutCertificatImmatriculation.fraisDeService as FraisDeService[];
  
  // Mapper les types de démarches possibles
  const demarcheMap: { [key: string]: string } = {
    'changement-titulaire': 'changement-titulaire',
    'changement-proprietaire': 'changement-titulaire',
    'changement-adresse': 'changement-adresse',
    'duplicata': 'duplicata',
    'premiere-immatriculation': 'premiere-immatriculation',
    'declaration-cession': 'declaration-cession'
  };

  const mappedDemarche = demarcheMap[demarche] || demarche;
  
  const frais = fraisConfig.find(f => f.demarche === mappedDemarche);
  return frais ? frais.montant : 29.90; // Valeur par défaut si non trouvé
}

export function getDescriptionFraisDeService(demarche: string): string {
  const fraisConfig = coutCertificatImmatriculation.fraisDeService as FraisDeService[];
  const frais = fraisConfig.find(f => f.demarche === demarche);
  return frais ? frais.description : 'Frais de service';
}
