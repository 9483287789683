import React from 'react';

const TestAutoAccountEmail: React.FC = () => {
  const testEmail = async () => {
    try {
      const response = await fetch('https://minutecartegrise.bosartfilm.workers.dev/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          to: 'contact@algerienomades.com', // Remplacez par votre email de test
          from: {
            email: 'noreply@minute-cartegrise.com',
            name: 'Minute Carte Grise'
          },
          templateId: 'd-6bd904befa8e4606928b60e87fd3288b',
          dynamicTemplateData: {
            firstName: 'Test',
            lastName: 'Utilisateur',
            orderNumber: 'TEST-123',
            tempPassword: 'MotDePasse123!',
            resetToken: 'token-test-123',
            siteUrl: 'https://app.minute-cartegrise.com'
          }
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Erreur d'envoi d'email: ${errorText}`);
      }

      const result = await response.json();
      console.log('Email envoyé avec succès:', result);
      alert('Email envoyé avec succès ! Vérifiez votre boîte de réception.');
    } catch (error) {
      console.error('Erreur:', error);
      alert('Erreur lors de l\'envoi de l\'email. Vérifiez la console.');
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>Test Email Création Compte</h2>
      <button 
        onClick={testEmail}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          backgroundColor: '#007bff',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer'
        }}
      >
        Envoyer Email Test
      </button>
    </div>
  );
};

export default TestAutoAccountEmail;
