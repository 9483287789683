import { API_CONFIG, CLOUDFLARE_WORKER } from '../config/api';

export interface HttpResponse<T> {
  data?: T;
  error?: string;
  status: number;
}

export interface HttpRequestConfig extends RequestInit {
  timeout?: number;
  retries?: number;
  retryDelay?: number;
}

export class HttpError extends Error {
  constructor(
    message: string,
    public status?: number,
    public response?: any
  ) {
    super(message);
    this.name = 'HttpError';
  }
}

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export async function http<T>(
  url: string,
  config: HttpRequestConfig = {}
): Promise<HttpResponse<T>> {
  const {
    timeout = API_CONFIG.timeout,
    retries = CLOUDFLARE_WORKER.maxRetries,
    retryDelay = CLOUDFLARE_WORKER.retryDelay,
    ...fetchConfig
  } = config;

  let attempt = 0;
  let lastError: Error | null = null;

  while (attempt < retries) {
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), timeout);

      const response = await fetch(url, {
        ...fetchConfig,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          ...fetchConfig.headers,
        },
        signal: controller.signal
      });

      clearTimeout(timeoutId);

      const contentType = response.headers.get('content-type');
      const isJson = contentType?.includes('application/json');
      const responseData = isJson ? await response.json() : await response.text();

      if (!response.ok) {
        throw new HttpError(
          isJson ? responseData.message || 'HTTP Error' : 'HTTP Error',
          response.status,
          responseData
        );
      }

      return {
        data: responseData,
        status: response.status
      };
    } catch (error: any) {
      lastError = error;
      attempt++;

      if (error.name === 'AbortError') {
        throw new HttpError('La requête a expiré', 408);
      }

      const shouldRetry = attempt < retries && (
        error instanceof HttpError && 
        [408, 429, 500, 502, 503, 504].includes(error.status || 0)
      );

      if (shouldRetry) {
        console.warn(`Tentative ${attempt}/${retries} échouée, nouvelle tentative dans ${retryDelay}ms...`);
        await delay(retryDelay);
        continue;
      }

      break;
    }
  }

  if (lastError instanceof HttpError) {
    throw lastError;
  }

  throw new HttpError(
    lastError?.message || 'Une erreur est survenue lors de la requête',
    500
  );
}