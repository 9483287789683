import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

interface Address {
  label: string;
  housenumber?: string;
  street?: string;
  postcode: string;
  city: string;
}

interface AddressAutocompleteProps {
  onSelect: (address: Address) => void;
  defaultValue?: string;
  className?: string;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  onSelect,
  defaultValue = '',
  className = '',
}) => {
  const [query, setQuery] = useState(defaultValue);
  const [suggestions, setSuggestions] = useState<Address[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setQuery(defaultValue);
  }, [defaultValue]);

  const fetchSuggestions = async (input: string) => {
    if (!input || input.length < 3) {
      setSuggestions([]);
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(input)}&limit=5`
      );
      const data = await response.json();
      
      const addresses: Address[] = data.features.map((feature: any) => ({
        label: feature.properties.label,
        housenumber: feature.properties.housenumber,
        street: feature.properties.street,
        postcode: feature.properties.postcode,
        city: feature.properties.city
      }));
      
      setSuggestions(addresses);
      setIsOpen(true);
    } catch (error) {
      console.error('Erreur lors de la récupération des suggestions:', error);
      setSuggestions([]);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetch = debounce(fetchSuggestions, 300);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    debouncedFetch(value);
  };

  const handleSelect = (address: Address) => {
    // Formatage de l'adresse pour n'avoir que la rue
    const streetAddress = address.housenumber ? 
      `${address.housenumber} ${address.street}` : 
      address.street;
      
    setQuery(streetAddress || '');
    setIsOpen(false);
    onSelect({
      ...address,
      label: streetAddress || '' // On envoie l'adresse sans la ville et le code postal
    });
  };

  return (
    <div ref={wrapperRef} className="relative">
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder="Entrez votre adresse"
        className={`w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 ${className}`}
        autoComplete="off"
      />
      
      {loading && (
        <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-500"></div>
        </div>
      )}
      
      {isOpen && suggestions.length > 0 && (
        <ul className="absolute z-10 w-full bg-white mt-1 rounded-lg shadow-lg border border-gray-200 max-h-60 overflow-auto">
          {suggestions.map((address, index) => (
            <li
              key={index}
              onClick={() => handleSelect(address)}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-sm"
            >
              {address.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AddressAutocomplete;
