import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase/config';

/**
 * Génère un numéro de commande unique au format "LLNNNN"
 * L: Lettre majuscule aléatoire (A-Z)
 * N: Chiffre aléatoire (0-9)
 */
export const generateOrderNumber = async (): Promise<string> => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let orderNumber: string;
  let isUnique = false;

  while (!isUnique) {
    // Générer 2 lettres aléatoires
    const letter1 = letters.charAt(Math.floor(Math.random() * letters.length));
    const letter2 = letters.charAt(Math.floor(Math.random() * letters.length));
    
    // Générer 4 chiffres aléatoires avec padding pour garantir 4 chiffres
    const numbers = String(Math.floor(Math.random() * 10000)).padStart(4, '0');
    
    orderNumber = `${letter1}${letter2}${numbers}`;

    // Vérifier que ce numéro n'existe pas déjà dans Firestore
    const ordersRef = collection(db, 'orders');
    const q = query(ordersRef, where('orderNumber', '==', orderNumber));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      isUnique = true;
    } else {
      console.log(`Numéro de commande ${orderNumber} existe déjà, génération d'un nouveau numéro`);
    }
  }

  return orderNumber!;
};

/**
 * Valide le format d'un numéro de commande
 * @param orderNumber Le numéro de commande à valider
 * @returns true si le format est valide (2 lettres suivies de 4 chiffres)
 */
export const isValidOrderNumber = (orderNumber: string): boolean => {
  const regex = /^[A-Z]{2}[0-9]{4}$/;
  return regex.test(orderNumber);
};
