export interface SendGridTestResult {
  success: boolean;
  error?: string;
  account?: {
    type: string;
    reputation: number;
    is_reseller_customer: boolean;
  };
}

export const testSendGridConnection = async (): Promise<SendGridTestResult> => {
  try {
    const response = await fetch('https://minutecartegrise.bosartfilm.workers.dev/', {
      headers: {
        'Accept': 'application/json'
      }
    });
    
    if (!response.ok) {
      throw new Error('Erreur de connexion au worker Cloudflare');
    }

    const data = await response.json();

    if (!data.sendgrid) {
      throw new Error('Réponse invalide du worker Cloudflare');
    }

    return data.sendgrid;
  } catch (error) {
    console.error('Erreur lors du test SendGrid:', error);
    return {
      success: false,
      error: error instanceof Error ? error.message : 'Erreur inattendue'
    };
  }
};