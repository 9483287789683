import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Package, CheckCircle, Clock, AlertCircle } from 'lucide-react';
import { collection, query, getDocs, orderBy, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import SearchBar from './SearchBar';
import DashboardStats from './DashboardStats';
import { formatDate } from '../../utils/formatDate';

interface Order {
  id: string;
  orderNumber: string;
  date: any;
  client: {
    name: string;
    email: string;
  };
  type: string;
  status: string;
  amount: number;
  createdAt: any;
}

const OrdersList = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const ordersRef = collection(db, 'orders');
      const q = query(ordersRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      
      const ordersPromises = querySnapshot.docs.map(async (docSnapshot) => {
        const data = docSnapshot.data();
        console.log('Order data:', data);
        
        let clientName = 'Client inconnu';
        let clientEmail = '';
        let demarche = data.vehiculeDetails?.type || 'Non spécifié';

        // Récupérer les informations du client depuis userDetails
        if (data.userDetails) {
          const { firstName, lastName, email, civilite } = data.userDetails;
          if (firstName && lastName) {
            clientName = `${civilite ? civilite.toUpperCase() + ' ' : ''}${firstName} ${lastName}`;
          }
          clientEmail = email || '';
        }

        return {
          id: docSnapshot.id,
          orderNumber: data.orderNumber || '',
          date: data.createdAt?.toDate(),
          client: {
            name: clientName,
            email: clientEmail
          },
          type: demarche,
          status: data.status || 'pending',
          amount: data.amount || 0,
          createdAt: data.createdAt
        };
      });

      const ordersData = await Promise.all(ordersPromises);
      console.log('Orders data:', ordersData);
      setOrders(ordersData);
      setFilteredOrders(ordersData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setLoading(false);
    }
  };

  const handleSearch = (query: string, type: string, status: string) => {
    let filtered = [...orders];

    if (status !== 'all') {
      filtered = filtered.filter(order => order.status === status);
    }

    if (query) {
      const searchQuery = query.toLowerCase();
      filtered = filtered.filter(order => {
        switch (type) {
          case 'client':
            return (
              order.client.name.toLowerCase().includes(searchQuery) ||
              order.client.email.toLowerCase().includes(searchQuery)
            );
          case 'order':
            return order.orderNumber.toLowerCase().includes(searchQuery);
          case 'plate':
            return order.vehicule.immatriculation.toLowerCase().includes(searchQuery);
          default:
            return (
              order.client.name.toLowerCase().includes(searchQuery) ||
              order.client.email.toLowerCase().includes(searchQuery) ||
              order.orderNumber.toLowerCase().includes(searchQuery) ||
              order.vehicule.immatriculation.toLowerCase().includes(searchQuery)
            );
        }
      });
    }

    setFilteredOrders(filtered);
  };

  const getStatusBadge = (status: string) => {
    switch (status) {
      case 'validated':
        return (
          <span className="inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-sm bg-green-100 text-green-800">
            <CheckCircle className="w-4 h-4" />
            Validée
          </span>
        );
      case 'pending':
        return (
          <span className="inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-sm bg-yellow-100 text-yellow-800">
            <Clock className="w-4 h-4" />
            En cours
          </span>
        );
      case 'en_cours':
        return (
          <span className="inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-sm bg-yellow-100 text-yellow-800">
            <Clock className="w-4 h-4" />
            En cours
          </span>
        );
      case 'error':
        return (
          <span className="inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-sm bg-red-100 text-red-800">
            <AlertCircle className="w-4 h-4" />
            Erreur
          </span>
        );
      case 'rejected':
        return (
          <span className="inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-sm bg-red-100 text-red-800">
            <AlertCircle className="w-4 h-4" />
            Rejetée
          </span>
        );
      default:
        return (
          <span className="inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-sm bg-gray-100 text-gray-800">
            <Clock className="w-4 h-4" />
            {status}
          </span>
        );
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <DashboardStats />
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Commandes</h2>
          <span className="text-sm text-gray-500">{filteredOrders.length} commandes</span>
        </div>

        <div className="mb-6">
          <SearchBar onSearch={handleSearch} />
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Commande
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Client
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Montant
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Statut
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {filteredOrders.map((order) => (
                <tr key={order.id} className="hover:bg-gray-50">
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                    <div className="flex items-center">
                      <Package className="h-5 w-5 text-gray-400 mr-2" />
                      <div>
                        <div className="font-medium text-gray-900">
                          <Link to={`/admin/dashboard/orders/${order.id}`} className="hover:text-blue-600">
                            {order.orderNumber}
                          </Link>
                        </div>
                        <div className="text-gray-500">{formatDate(order.date)}</div>
                      </div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div className="font-medium text-gray-900">{order.client.name}</div>
                    <div className="text-gray-500">{order.client.email}</div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span className="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700">
                      {order.type}
                    </span>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {order.amount.toFixed(2)} €
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {getStatusBadge(order.status)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrdersList;