import { 
  doc, 
  getDoc, 
  setDoc, 
  updateDoc, 
  collection, 
  query, 
  where, 
  getDocs, 
  serverTimestamp
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { User, getAuth } from 'firebase/auth';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/config';

export interface UserData {
  email: string;
  role: 'admin' | 'support' | 'client';
  createdAt: Date;
}

export type UserRole = 'admin' | 'support' | 'client';

export const initializeUserRole = async (user: User) => {
  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);

  if (!userDoc.exists()) {
    // Créer l'utilisateur dans la collection users
    await setDoc(userRef, {
      email: user.email,
      role: 'client',
      createdAt: new Date()
    });

    // Créer le client dans la collection clients
    const clientRef = doc(db, 'clients', user.uid);
    await setDoc(clientRef, {
      email: user.email,
      createdAt: new Date(),
      updatedAt: new Date(),
      role: 'client'
    });
  }
};

export const isUserAdmin = async (userId: string): Promise<boolean> => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      console.error('User document does not exist');
      return false;
    }

    const userData = userDoc.data() as UserData;
    return userData.role === 'admin';
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
};

export const setUserAsAdmin = async (userId: string): Promise<void> => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      role: 'admin'
    });
  } catch (error) {
    console.error('Error setting user as admin:', error);
    throw error;
  }
};

export const setUserAsClient = async (userId: string): Promise<void> => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      role: 'client'
    });
  } catch (error) {
    console.error('Error setting user as client:', error);
    throw error;
  }
};

export const migrateUserToClient = async (userId: string) => {
  try {
    // Récupérer les données de l'utilisateur
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('Utilisateur non trouvé');
    }

    const userData = userDoc.data();

    // Si c'est lisifilm@gmail.com, on ajoute des informations spécifiques
    const isLisifilm = userData.email === 'lisifilm@gmail.com';
    const clientData = {
      nom: isLisifilm ? 'Lisi' : (userData.lastName || userData.nom || ''),
      prenom: isLisifilm ? 'Film' : (userData.firstName || userData.prenom || ''),
      email: userData.email,
      telephone: isLisifilm ? '0123456789' : (userData.phone || userData.telephone || ''),
      adresse: isLisifilm ? '123 rue du Film' : (userData.address || userData.adresse || ''),
      ville: isLisifilm ? 'Paris' : (userData.city || userData.ville || 'Paris'),
      codePostal: isLisifilm ? '75000' : (userData.postalCode || userData.codePostal || '75000'),
      nombreCommandes: 0,
      derniereCommande: new Date(),
      searchTerms: [
        (isLisifilm ? 'Lisi' : userData.lastName || '').toLowerCase(),
        (isLisifilm ? 'Film' : userData.firstName || '').toLowerCase(),
        userData.email.toLowerCase(),
        'Paris'.toLowerCase(),
      ],
      role: 'client',
      createdAt: userData.createdAt || new Date(),
      updatedAt: new Date()
    };

    // Créer le client dans la collection clients
    const clientRef = doc(db, 'clients', userId);
    await setDoc(clientRef, clientData);

    // Mettre à jour le rôle dans la collection users
    await updateDoc(userRef, {
      role: 'client'
    });

    return true;
  } catch (error) {
    console.error('Erreur lors de la migration:', error);
    throw error;
  }
};

export const verifyOrderAccess = async (email: string, orderNumber: string): Promise<boolean> => {
  try {
    // Convertir l'email en minuscules pour la recherche
    const searchEmail = email.toLowerCase();
    console.log('Recherche de commande avec:', { searchEmail, orderNumber });

    // Rechercher la commande dans la collection orders
    const ordersRef = collection(db, 'orders');
    const q = query(ordersRef, 
      where('email', '==', searchEmail),
      where('orderNumber', '==', orderNumber)
    );
    
    const querySnapshot = await getDocs(q);
    console.log(`Nombre de résultats trouvés: ${querySnapshot.size}`);
    
    // Si on trouve une commande correspondante
    if (!querySnapshot.empty) {
      const orderDoc = querySnapshot.docs[0];
      const orderData = orderDoc.data();
      
      // Créer une session temporaire
      const sessionRef = doc(db, 'sessions', orderDoc.id);
      await setDoc(sessionRef, {
        email: searchEmail, // Stocker l'email en minuscules
        orderNumber: orderNumber,
        lastAccess: new Date(),
        expiresAt: new Date(Date.now() + 24 * 60 * 60 * 1000) // 24 heures
      });

      // Créer un email unique pour cette commande
      const uniqueEmail = searchEmail.replace('@', `+${orderNumber}@`);

      // Créer ou mettre à jour le compte temporaire dans Firebase Auth
      try {
        // Tenter de se connecter avec l'email unique
        await signInWithEmailAndPassword(auth, uniqueEmail, orderNumber);
      } catch (error) {
        // Si la connexion échoue, créer le compte
        try {
          await createUserWithEmailAndPassword(auth, uniqueEmail, orderNumber);
        } catch (createError) {
          console.error('Erreur lors de la création du compte:', createError);
          return false;
        }
      }
      
      return true;
    }
    
    return false;
  } catch (error) {
    console.error('Error verifying order access:', error);
    return false;
  }
};

export const verifyAdminAccess = async (email: string, password: string): Promise<boolean> => {
  try {
    console.log('Tentative de connexion admin avec:', email);
    
    // Vérifier dans la collection users d'abord
    const usersRef = collection(db, 'users');
    const userQuery = query(usersRef, where('email', '==', email), where('role', '==', 'admin'));
    const userSnapshot = await getDocs(userQuery);

    if (!userSnapshot.empty) {
      const userData = userSnapshot.docs[0].data();
      console.log('Utilisateur trouvé dans users:', userData);
      
      // Pour l'instant, on accepte la connexion si l'utilisateur est un admin
      localStorage.setItem('isAdmin', 'true');
      localStorage.setItem('adminEmail', email);
      return true;
    }

    // Si pas trouvé dans users, vérifier dans admins
    const adminsRef = collection(db, 'admins');
    const adminQuery = query(adminsRef, where('email', '==', email));
    const adminSnapshot = await getDocs(adminQuery);

    console.log('Recherche dans la collection admins:', !adminSnapshot.empty);

    if (!adminSnapshot.empty) {
      const adminData = adminSnapshot.docs[0].data();
      console.log('Admin trouvé:', adminData);

      if (adminData.password === password) {
        localStorage.setItem('isAdmin', 'true');
        localStorage.setItem('adminEmail', email);
        return true;
      }
    }

    return false;
  } catch (error) {
    console.error('Erreur détaillée lors de la vérification admin:', error);
    return false;
  }
};