import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { formatOrderId } from '../../utils/formatters';
import toast from 'react-hot-toast';
import { Package, Clock, MapPin, FileText, Upload, CheckCircle, XCircle, AlertCircle } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { uploadDocument, getOrderDocuments } from '../../services/documentService';
import { DOCUMENT_GROUPS } from '../../config/documentGroups';

interface OrderDocument {
  status: string;
  fileUrl?: string;
  fileName?: string;
  uploadedAt?: Date;
  uploadedBy?: string;
  comment?: string;
}

interface OrderData {
  id: string;
  type: string;
  orderNumber: string;
  formattedOrderNumber?: string;
  status: string;
  documents: Record<string, OrderDocument>;
  vehicule?: {
    marque: string;
    modele: string;
    immatriculation: string;
    dateMiseEnCirculation: string;
    energie: string;
    puissance: string;
  };
  client?: {
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    address?: string;
    isSociete?: boolean;
  };
  timeline?: Array<{
    date: string;
    event: string;
  }>;
  createdAt?: Date;
  updatedAt?: Date;
}

const OrderDetails = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const navigate = useNavigate();
  const [order, setOrder] = useState<OrderData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrder = async () => {
      if (!orderId) return;
      
      try {
        const orderRef = doc(db, 'orders', orderId);
        const orderSnapshot = await getDoc(orderRef);
        
        if (orderSnapshot.exists()) {
          const data = orderSnapshot.data();
          console.log('Raw order data:', data);
          
          // Récupérer les documents depuis le service de documents
          const { documents } = await getOrderDocuments(orderId);
          console.log('Documents from service:', documents);
          
          const orderData: OrderData = {
            id: orderSnapshot.id,
            type: data.type,
            orderNumber: data.orderNumber || '',  
            formattedOrderNumber: data.orderNumber ? await formatOrderId(data.orderNumber) : '',
            status: data.status,
            documents: documents.reduce((acc: Record<string, OrderDocument>, doc: any) => {
              acc[doc.documentId] = doc;
              return acc;
            }, {}),
            vehicule: data.vehicule,
            client: data.client,
            timeline: data.timeline,
            createdAt: data.createdAt?.toDate(),
            updatedAt: data.updatedAt?.toDate()
          };
          
          console.log('Formatted order data:', orderData);
          setOrder(orderData);
        } else {
          console.error('Order not found');
          navigate('/client/orders');
        }
      } catch (error) {
        console.error('Error fetching order:', error);
        toast.error('Erreur lors du chargement de la commande');
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [orderId, navigate]);

  const handleFileUpload = async (docId: string, file: File | null) => {
    if (!file) return;
    try {
      const url = await uploadDocument(orderId, docId, file);
      setOrder((prevOrder) => {
        if (!prevOrder) return null;
        return {
          ...prevOrder,
          documents: {
            ...prevOrder.documents,
            [docId]: {
              ...prevOrder.documents[docId],
              fileUrl: url,
            },
          },
        };
      });
      toast.success('Document téléchargé avec succès');
    } catch (error) {
      console.error('Erreur lors du téléchargement du document:', error);
      toast.error('Erreur lors du téléchargement du document');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
      </div>
    );
  }

  if (!order) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-gray-900">Commande non trouvée</h2>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-lg">
      {/* En-tête */}
      <div className="border-b p-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <Package className="w-8 h-8 text-blue-600" />
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                {order.orderNumber ? `Commande #${order.orderNumber}` : 'Commande en cours de traitement'}
              </h1>
              <p className="text-gray-500">
                {order.vehicule?.marque} {order.vehicule?.modele}
              </p>
            </div>
          </div>
          <StatusBadge status={order.status} />
        </div>
      </div>

      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Informations véhicule</h2>
            <div className="space-y-4">
              <div className="flex items-center gap-3">
                <Clock className="w-5 h-5 text-gray-400" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Mise en circulation</p>
                  <p className="text-gray-900">{order.vehicule?.dateMiseEnCirculation || 'Non spécifié'}</p>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <Package className="w-5 h-5 text-gray-400" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Immatriculation</p>
                  <p className="text-gray-900">{order.vehicule?.immatriculation || 'Non spécifié'}</p>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <FileText className="w-5 h-5 text-gray-400" />
                <div>
                  <p className="text-sm font-medium text-gray-500">Puissance</p>
                  <p className="text-gray-900">{order.vehicule?.puissance || 'Non spécifié'} CV</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Informations client</h2>
            <div className="flex items-center gap-3">
              <MapPin className="w-5 h-5 text-gray-400" />
              <div>
                <p className="text-sm font-medium text-gray-500">Adresse</p>
                <p className="text-gray-900">
                  {order.client ? (
                    <>
                      {order.client.address}
                      <br />
                      {order.client.phone}
                    </>
                  ) : (
                    'Non spécifié'
                  )}
                </p>
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Suivi de commande</h2>
            {order.timeline && order.timeline.length > 0 ? (
              <div className="space-y-4">
                {order.timeline.map((event, index) => (
                  <div key={index} className="flex items-center gap-3">
                    <Clock className="w-5 h-5 text-gray-400" />
                    <div>
                      <p className="text-sm text-gray-500">{event.date}</p>
                      <p className="text-gray-900">{event.event}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-500">Aucun événement</p>
            )}
          </div>

          <div>
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Documents</h2>
            <div className="space-y-6">
              {order.type && DOCUMENT_GROUPS[order.type]?.map((group) => {
                // Ne pas afficher le groupe société si ce n'est pas une société
                if (group.id === 'societe' && !order.client?.isSociete) {
                  return null;
                }
                return (
                  <div key={group.id}>
                    <h3 className="text-md font-medium text-gray-700 mb-3">{group.title}</h3>
                    <div className="space-y-4">
                      {group.documents.map((doc) => {
                        const orderDoc = order.documents?.[doc.id] || {};
                        return (
                          <div key={doc.id} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                            <div className="flex items-center gap-3">
                              <FileText className="w-5 h-5 text-gray-400" />
                              <div>
                                <p className="font-medium text-gray-900">{doc.name}</p>
                                <p className="text-sm text-gray-500">{doc.description}</p>
                                {orderDoc.status && (
                                  <DocumentStatus status={orderDoc.status} comment={orderDoc.comment} />
                                )}
                              </div>
                            </div>
                            <div className="flex items-center gap-2">
                              {orderDoc.fileUrl && (
                                <a
                                  href={orderDoc.fileUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="p-2 text-blue-600 hover:text-blue-800"
                                  title="Voir le document"
                                >
                                  <FileText className="w-5 h-5" />
                                </a>
                              )}
                              <label className="cursor-pointer p-2 text-blue-600 hover:text-blue-800" title="Télécharger un document">
                                <input
                                  type="file"
                                  className="hidden"
                                  onChange={(e) => handleFileUpload(doc.id, e.target.files?.[0])}
                                  accept={doc.acceptedFormats.join(',')}
                                />
                                <Upload className="w-5 h-5" />
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              {(!order.type || !DOCUMENT_GROUPS[order.type]) && (
                <div className="text-center py-4 text-gray-500">
                  Aucun document requis pour ce type de démarche
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-8 flex justify-center">
          <button
            onClick={() => {
              navigate('/dashboard/documents');
            }}
            className="inline-flex items-center gap-2 bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 transition-transform duration-200"
          >
            <Upload className="w-5 h-5" />
            Gérer mes documents
          </button>
        </div>
      </div>
    </div>
  );
};

const StatusBadge = ({ status }: { status: string }) => {
  switch (status) {
    case 'completed':
      return (
        <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
          Terminée
        </span>
      );
    case 'processing':
      return (
        <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
          En cours
        </span>
      );
    default:
      return null;
  }
};

const DocumentStatus = ({ status, comment }: { status: string; comment?: string }) => {
  const getStatusConfig = (status: string) => {
    switch (status) {
      case 'validated':
        return {
          icon: CheckCircle,
          text: 'Validé',
          color: 'text-green-600',
          bgColor: 'bg-green-50'
        };
      case 'rejected':
        return {
          icon: XCircle,
          text: 'Rejeté',
          color: 'text-red-600',
          bgColor: 'bg-red-50'
        };
      default:
        return {
          icon: AlertCircle,
          text: 'En attente',
          color: 'text-yellow-600',
          bgColor: 'bg-yellow-50'
        };
    }
  };

  const config = getStatusConfig(status);
  const Icon = config.icon;

  return (
    <div className="flex items-center gap-2">
      <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${config.color} ${config.bgColor}`}>
        <Icon className="w-4 h-4 mr-1" />
        {config.text}
      </span>
      {comment && (
        <span className="text-sm text-gray-500">
          - {comment}
        </span>
      )}
    </div>
  );
};

export default OrderDetails;