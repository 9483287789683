import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Package, Clock, Download } from 'lucide-react';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuth } from '../../context/AuthContext';
import toast from 'react-hot-toast';
import { formatOrderId } from '../../utils/formatters';

interface Order {
  id: string;
  orderNumber?: string;
  formattedOrderNumber?: string;
  vehicule?: {
    marque: string;
    modele: string;
    immatriculation: string;
  };
  type: string;
  status: string;
  amount?: number;
  documents?: Record<string, any>;
  createdAt?: Date;
}

const OrdersList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [highlightedOrderId, setHighlightedOrderId] = useState<string | null>(null);

  useEffect(() => {
    // Récupérer l'ID de commande depuis les paramètres d'URL
    const searchParams = new URLSearchParams(location.search);
    const orderId = searchParams.get('order');
    if (orderId) {
      setHighlightedOrderId(orderId);
      
      // Supprimer le paramètre de l'URL après 5 secondes
      setTimeout(() => {
        navigate('/dashboard', { replace: true });
      }, 5000);
    }
    
    fetchOrders();
  }, [location]);

  const fetchOrders = async () => {
    try {
      if (!user) {
        console.error('No user found');
        return;
      }

      console.log('Fetching orders for user:', {
        uid: user.uid,
        email: user.email
      });

      const ordersRef = collection(db, 'orders');
      
      // Extraire l'email original et le mettre en minuscules pour la recherche
      const originalEmail = user.email.split('+')[0] + '@' + user.email.split('@')[1];
      const searchEmail = originalEmail.toLowerCase();
      
      console.log('Email processing:', {
        inputEmail: user.email,
        searchEmail: searchEmail
      });

      // Chercher avec l'email en minuscules
      const emailQuery = query(ordersRef, where('email', '==', searchEmail));
      
      // Exécuter la requête
      console.log('Executing query...');
      const snapshot = await getDocs(emailQuery);
      console.log(`Query returned ${snapshot.size} results`);
      
      // Traiter les résultats
      const ordersMap = new Map();
      
      snapshot.docs.forEach(doc => {
        const data = doc.data();
        console.log('Found order:', {
          id: doc.id,
          email: data.email,
          orderNumber: data.orderNumber || doc.id
        });
        
        if (!ordersMap.has(doc.id)) {
          // S'assurer que les données du véhicule sont correctement structurées
          const vehiculeData = data.vehicule || {
            marque: data.marque,
            modele: data.modele,
            immatriculation: data.immatriculation
          };
          
          ordersMap.set(doc.id, {
            id: doc.id,
            ...data,
            vehicule: vehiculeData,
            type: data.vehiculeDetails?.type || data.type || '',  
            orderNumber: data.orderNumber || doc.id,
            createdAt: data.createdAt?.toDate() || new Date()
          });
        }
      });

      const ordersList = Array.from(ordersMap.values());
      console.log('Total orders found:', ordersList.length);

      // Trier les commandes par date (plus récent en premier)
      const sortedOrders = ordersList.sort((a, b) => {
        const dateA = a.createdAt instanceof Date ? a.createdAt : new Date();
        const dateB = b.createdAt instanceof Date ? b.createdAt : new Date();
        return dateB.getTime() - dateA.getTime();
      });

      console.log('Orders data (sorted):', sortedOrders);

      // Formater les numéros de commande
      const formattedOrders = await Promise.all(
        sortedOrders.map(async (order) => ({
          ...order,
          formattedOrderNumber: await formatOrderId(order.orderNumber)
        }))
      );

      console.log('Processed orders:', formattedOrders);
      setOrders(formattedOrders);
    } catch (error) {
      console.error('Error fetching orders:', error);
      toast.error('Erreur lors de la récupération des commandes');
    } finally {
      setLoading(false);
    }
  };

  const handleDocumentUpload = (order: Order) => {
    const orderData = {
      id: order.id,
      type: order.type,
      documents: order.documents || {},
      vehicleInfo: {
        brand: order.vehicule?.marque || 'Non spécifié',
        model: order.vehicule?.modele || '',
        immatriculation: order.vehicule?.immatriculation || ''
      }
    };
    localStorage.setItem('selectedOrder', JSON.stringify(orderData));
    localStorage.setItem('currentDemarche', order.type);
    navigate('/dashboard/documents');
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <div className="text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
          <p className="mt-2 text-gray-600">Chargement des commandes...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Mes commandes</h1>
        <Link
          to="/nouvelle-demande"
          className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium whitespace-nowrap"
        >
          <span className="hidden md:inline">Nouvelle demande</span>
          <span className="md:hidden">Nouvelle dem</span>
        </Link>
      </div>

      {orders.length === 0 ? (
        <div className="text-center py-12 bg-gray-50 rounded-lg">
          <Package className="w-12 h-12 mx-auto text-gray-400" />
          <p className="mt-4 text-gray-600">Aucune commande trouvée</p>
        </div>
      ) : (
        <div className="space-y-4">
          {orders.map((order) => (
            <div 
              key={order.id} 
              className={`bg-white rounded-lg p-4 shadow-sm transition-all duration-500 ${
                highlightedOrderId === order.id ? 'ring-2 ring-blue-500 bg-blue-50' : ''
              }`}
            >
              <div className="flex flex-col">
                <div className="flex items-start justify-between">
                  <div className="flex-1">
                    <div className="flex items-center gap-2">
                      <Package className="w-5 h-5 text-blue-500" />
                      <h3 className="text-base font-medium text-gray-900">
                        Commande #{order.orderNumber}
                      </h3>
                    </div>
                    <div className="mt-2 flex items-center gap-2">
                      {/* Plaque d'immatriculation */}
                      {(order.vehicule?.immatriculation || order.immatriculation) && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800">
                          {order.vehicule?.immatriculation || order.immatriculation}
                        </span>
                      )}
                      {/* Type de démarche */}
                      <span className="text-gray-600 text-sm">
                        {order.type === 'changement-adresse' ? 'Changement d\'adresse' : 
                         order.type === 'declaration-cession' ? 'Déclaration de cession' :
                         order.type === 'changement-proprietaire' ? 'Changement de titulaire' : 
                         order.type}
                      </span>
                    </div>
                  </div>
                  <div className="text-right">
                    <p className="font-medium text-base">{order.amount?.toFixed(2)} €</p>
                    <div className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 mt-1">
                      <Clock className="w-3 h-3 mr-1" />
                      En cours
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => handleDocumentUpload(order)}
                  className="mt-4 flex items-center justify-center w-full gap-2 px-4 py-2.5 bg-green-600 text-white rounded-lg text-sm font-medium hover:bg-green-700 transition-colors"
                >
                  <Download className="w-4 h-4" />
                  <span>Envoyer mes documents</span>
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OrdersList;