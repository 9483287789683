import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, doc, updateDoc, getDoc, where, deleteDoc, arrayUnion, Timestamp, addDoc, orderBy } from 'firebase/firestore';
import { db } from '../../firebase/config';
import toast from 'react-hot-toast';
import { FileText, CheckCircle, XCircle, AlertCircle, Clock, Download, ChevronUp, ChevronDown, Eye } from 'lucide-react';
import { createTestOrder } from '../../services/api/testData';
import { DOCUMENT_GROUPS } from '../../config/documentGroups';
import Modal from '../common/Modal';
import { useLocation } from 'react-router-dom';
import StatusBadge from './documents/StatusBadge';
import DocumentActions from './documents/DocumentActions';
import DocumentStatusBadge from './documents/DocumentStatusBadge';
import DocumentReminderModal from './documents/DocumentReminderModal';
import { sendDocumentReminder } from '../../services/documentReminderService';
import { User, Document, Order, Client } from '../../types/common';

interface AdminDocumentsProps {
  // Ajoutez ici les props si nécessaire
}

const AdminDocuments: React.FC<AdminDocumentsProps> = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const highlightedOrderId = searchParams.get('orderId');
  
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<'all' | 'pending' | 'validated' | 'rejected'>('all');
  const [expandedOrderId, setExpandedOrderId] = useState<string | null>(null);
  const [commentState, setCommentState] = useState<Record<string, string>>({});
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: 'validate',
    documentId: ''
  });
  const [commentInput, setCommentInput] = useState('');
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    if (highlightedOrderId) {
      if (!expandedOrderId || expandedOrderId !== highlightedOrderId) {
        setExpandedOrderId(highlightedOrderId);
      }
      
      setTimeout(() => {
        const element = document.getElementById(`order-${highlightedOrderId}`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          element.classList.add('highlight-animation');
          setTimeout(() => {
            element.classList.remove('highlight-animation');
          }, 2000);
        }
      }, 100);

      const order = orders.find(o => o.id === highlightedOrderId);
      if (order) {
        setSearchTerm(order.clientName || order.id);
      }
    }
  }, [highlightedOrderId, orders]);

  useEffect(() => {
    if (highlightedOrderId && !expandedOrderId) {
      setExpandedOrderId(highlightedOrderId);
    }
  }, [highlightedOrderId]);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const ordersRef = collection(db, 'orders');
      const ordersQuery = query(ordersRef, orderBy('createdAt', 'desc')); 
      const ordersSnapshot = await getDocs(ordersQuery);
      
      const ordersData = await Promise.all(ordersSnapshot.docs.map(async doc => {
        const data = doc.data();
        let clientName = '';
        let clientEmail = '';

        if (data.clientId) {
          const client = await getClientData(data.clientId);
          if (client) {
            const firstName = client.firstName || '';
            const lastName = client.lastName || '';
            clientName = `${firstName} ${lastName}`.trim();
            clientEmail = client.email || '';
          }
        }

        // Si pas de client trouvé, utiliser les données de la commande
        if (!clientName) {
          clientName = data.clientName || data.email?.split('@')[0] || 'Client sans nom';
        }
        if (!clientEmail) {
          clientEmail = data.email || 'Email non disponible';
        }

        const documents = data.documents || {};
        
        return {
          id: doc.id,
          clientId: data.clientId,
          clientName: clientName,
          clientEmail: clientEmail,
          type: data.type || 'unknown',
          status: data.status || 'pending',
          vehicule: data.vehicule || {},
          amount: data.amount || 0,
          documents: documents,
          createdAt: data.createdAt?.toDate() || new Date(),
          orderNumber: data.orderNumber || '',
        };
      }));

      setOrders(ordersData);
    } catch (error) {
      console.error('Erreur lors de la récupération des commandes:', error);
      toast.error('Erreur lors de la récupération des commandes');
    } finally {
      setLoading(false);
    }
  };

  const getClientData = async (clientId: string): Promise<Client | null> => {
    try {
      const clientDoc = await getDoc(doc(db, 'clients', clientId));
      if (clientDoc.exists()) {
        return { id: clientDoc.id, ...clientDoc.data() } as Client;
      }
      return null;
    } catch (error) {
      console.error('Erreur lors de la récupération du client:', error);
      return null;
    }
  };

  const handleValidateClick = (orderId: string, documentId: string, document: Document) => {
    setModalState({
      isOpen: true,
      type: 'validate',
      documentId
    });
    setSelectedOrder({ id: orderId, documents: { [documentId]: document } } as Order);
  };

  const handleRejectClick = (orderId: string, documentId: string, document: Document) => {
    setModalState({
      isOpen: true,
      type: 'reject',
      documentId
    });
    setSelectedOrder({ id: orderId, documents: { [documentId]: document } } as Order);
  };

  const handleModalConfirm = async () => {
    if (!selectedOrder || !modalState.documentId) return;

    try {
      const orderRef = doc(db, 'orders', selectedOrder.id);
      const currentDate = new Date().toISOString();
      const status = modalState.type === 'validate' ? 'validated' : 'rejected';
      const action = modalState.type === 'validate' ? 'validé' : 'rejeté';

      const notification = {
        type: modalState.type === 'validate' ? 'document_validated' : 'document_rejected',
        documentId: modalState.documentId,
        message: `Document ${action}${commentInput ? ` : ${commentInput}` : ''}`,
        timestamp: currentDate,
        read: false
      };

      const currentData = await getDoc(orderRef);
      const currentDocuments = currentData.data()?.documents || {};

      const updatedDocuments = {
        ...currentDocuments,
        [modalState.documentId]: {
          ...(currentDocuments[modalState.documentId] || {}),
          status,
          comment: commentInput,
          validatedAt: currentDate,
          validatedBy: 'Admin'
        }
      };

      await updateDoc(orderRef, {
        notifications: arrayUnion(notification),
        documents: updatedDocuments,
        lastUpdated: currentDate
      });

      const updatedOrderDoc = await getDoc(orderRef);
      if (updatedOrderDoc.exists()) {
        const updatedData = updatedOrderDoc.data();
        setOrders(prevOrders => 
          prevOrders.map(order => 
            order.id === selectedOrder.id
              ? {
                  ...order,
                  documents: updatedData.documents || {},
                  notifications: updatedData.notifications || []
                }
              : order
          )
        );
      }

      setModalState({ ...modalState, isOpen: false });
      setCommentInput('');
      toast.success(`Document ${action} avec succès`);
    } catch (error) {
      console.error('Erreur lors de la validation/rejet:', error);
      toast.error(`Erreur lors de la ${modalState.type === 'validate' ? 'validation' : 'rejet'} du document`);
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'validated':
        return <CheckCircle className="w-5 h-5 text-green-500" />;
      case 'rejected':
        return <XCircle className="w-5 h-5 text-red-500" />;
      default:
        return <AlertCircle className="w-5 h-5 text-yellow-500" />;
    }
  };

  const getOrderStatus = (order: Order) => {
    if (!order.documents) return 'pending';
    
    const documentStatuses = Object.values(order.documents).map(doc => {
      // Un document est considéré comme "uploaded" s'il a une URL mais pas de status validé/rejeté
      if (doc.fileUrl && (!doc.status || doc.status === 'pending')) {
        return 'uploaded';
      }
      return doc.status || 'pending';
    });
    
    if (documentStatuses.every(status => status === 'validated')) {
      return 'completed';
    }
    if (documentStatuses.some(status => status === 'rejected')) {
      return 'rejected';
    }
    if (documentStatuses.some(status => status === 'uploaded')) {
      return 'uploaded';
    }
    return 'pending';
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'completed':
        return 'bg-green-50 border-green-200';
      case 'rejected':
        return 'bg-red-50 border-red-200';
      case 'uploaded':
        return 'bg-blue-50 border-blue-200';
      case 'pending':
        return 'bg-yellow-50 border-yellow-200';
      default:
        return 'bg-gray-50 border-gray-200';
    }
  };

  const getStatusBadge = (status: string) => {
    switch (status) {
      case 'completed':
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            <CheckCircle className="w-4 h-4 mr-1" />
            Terminée
          </span>
        );
      case 'rejected':
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
            <XCircle className="w-4 h-4 mr-1" />
            Documents rejetés
          </span>
        );
      case 'uploaded':
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
            <AlertCircle className="w-4 h-4 mr-1" />
            Nouveaux documents
          </span>
        );
      case 'pending':
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
            <Clock className="w-4 h-4 mr-1" />
            En attente
          </span>
        );
      default:
        return null;
    }
  };

  const filteredOrders = orders.filter(order => {
    if (!order || !order.clientName || !order.clientEmail || !order.id) {
      return false;
    }

    // Convertir le terme de recherche en majuscules pour une recherche insensible à la casse
    const searchTermUpper = searchTerm.toUpperCase();
    
    // Vérifier si la recherche correspond au numéro de commande (insensible à la casse)
    const orderNumberMatch = order.orderNumber?.toUpperCase().includes(searchTermUpper);

    // Vérifier les autres champs
    const nameMatch = order.clientName.toLowerCase().includes(searchTerm.toLowerCase());
    const emailMatch = order.clientEmail.toLowerCase().includes(searchTerm.toLowerCase());
    const idMatch = order.id.toLowerCase().includes(searchTerm.toLowerCase());

    // Filtrer par statut si un statut spécifique est sélectionné
    if (selectedStatus !== 'all') {
      const orderStatus = getOrderStatus(order);
      if (orderStatus !== selectedStatus) {
        return false;
      }
    }

    // Retourner true si l'un des critères correspond
    return orderNumberMatch || nameMatch || emailMatch || idMatch;
  });

  const renderDocuments = (order: Order) => {
    if (!order.documents || Object.keys(order.documents).length === 0) {
      return (
        <div className="text-center py-4 text-gray-500">
          Aucun document disponible
        </div>
      );
    }

    return Object.entries(order.documents).map(([docId, doc]) => (
      <div
        key={docId}
        className="flex items-center justify-between p-4 bg-white rounded-lg shadow mb-2 hover:bg-gray-50 transition-colors duration-150"
      >
        <div className="flex items-center space-x-4">
          <FileText className="w-6 h-6 text-gray-400" />
          <div>
            <h3 className="text-sm font-medium text-gray-900">{doc.name || doc.type || 'Document inconnu'}</h3>
            <p className="text-sm text-gray-500">{doc.description}</p>
            <div className="mt-1 flex items-center space-x-2">
              <DocumentStatusBadge status={doc.status || 'pending'} />
              {doc.uploadDate && (
                <span className="text-xs text-gray-500">
                  Ajouté le {new Date(doc.uploadDate).toLocaleDateString('fr-FR')}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <DocumentActions
            document={doc}
            orderId={order.id}
            documentId={docId}
            onValidate={() => handleValidateClick(order.id, docId, doc)}
            onReject={() => handleRejectClick(order.id, docId, doc)}
            onRequestDocument={() => {
              setSelectedOrder(order);
              setModalState({
                isOpen: true,
                type: 'request',
                documentId: docId
              });
            }}
          />
          {doc.url && (
            <a
              href={doc.url}
              target="_blank"
              rel="noopener noreferrer"
              className="p-2 text-blue-600 hover:text-blue-800 rounded-full hover:bg-blue-50"
              title="Voir le document"
            >
              <Eye className="w-5 h-5" />
            </a>
          )}
        </div>
      </div>
    ));
  };

  const toggleOrderExpanded = (orderId: string) => {
    setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
  };

  const resetDatabase = async () => {
    const ordersRef = collection(db, 'orders');
    const ordersSnapshot = await getDocs(ordersRef);
    ordersSnapshot.docs.forEach(order => deleteDoc(doc(db, 'orders', order.id)));

    const clientsRef = collection(db, 'clients');
    const clientsSnapshot = await getDocs(clientsRef);
    clientsSnapshot.docs.forEach(client => deleteDoc(doc(db, 'clients', client.id)));
  };

  const handleReset = async () => {
    if (window.confirm('Êtes-vous sûr de vouloir réinitialiser la base de données ? Cette action supprimera toutes les commandes et clients.')) {
      try {
        await resetDatabase();
        toast.success('Base de données réinitialisée avec succès');
        fetchOrders();
      } catch (error) {
        console.error('Erreur lors de la réinitialisation de la base de données:', error);
        toast.error('Erreur lors de la réinitialisation de la base de données');
      }
    }
  };

  const openModal = (type: 'validate' | 'reject' | 'request', documentId: string, order: Order) => {
    setSelectedOrder(order);
    setModalState({ isOpen: true, type, documentId });
    setCommentInput('');
  };

  const closeModal = () => {
    setModalState({ isOpen: false, type: 'validate', documentId: '' });
    setCommentInput('');
  };

  const createTestOrder = async (type: 'changement-adresse' | 'changement-titulaire') => {
    try {
      const ordersRef = collection(db, 'orders');
      const clientsRef = collection(db, 'clients');

      // Créer un nouveau client
      const clientData = {
        name: 'Film Lisi',
        email: 'lisifilm@gmail.com',
        phone: '0123456789'
      };

      const clientDoc = await addDoc(clientsRef, clientData);

      // Créer une nouvelle commande
      const orderData = {
        clientId: clientDoc.id,
        clientName: clientData.name,
        clientEmail: clientData.email,
        type: type,
        status: 'pending',
        vehicule: {
          marque: 'Peugeot',
          modele: '308',
          immatriculation: 'AB-123-CD',
          puissance: '5',
          energie: 'Essence',
          datePremiereCirculation: '01/01/2020'
        },
        amount: 49.90,
        documents: {
          'cerfa-13750': {
            id: 'cerfa-13750',
            name: type === 'changement-adresse' ? 'Demande de changement d\'adresse' : 'Demande de changement de titulaire',
            status: 'pending',
            required: true
          },
          'justificatif-domicile': {
            id: 'justificatif-domicile',
            name: 'Justificatif de domicile',
            status: 'pending',
            required: true
          },
          'carte-grise': {
            id: 'carte-grise',
            name: 'Carte grise',
            status: 'pending',
            required: true
          }
        },
        createdAt: new Date(),
        orderNumber: `MC${Math.floor(Math.random() * 10000)}`
      };

      await addDoc(ordersRef, orderData);
      toast.success('Commande test créée avec succès');
      fetchOrders();
    } catch (error) {
      console.error('Erreur lors de la création de la commande test:', error);
      toast.error('Erreur lors de la création de la commande test');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Gestion des Documents</h1>
        <div className="flex space-x-4">
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value as 'all' | 'pending' | 'validated' | 'rejected')}
            className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="all">Tous les statuts</option>
            <option value="pending">En attente</option>
            <option value="validated">Validés</option>
            <option value="rejected">Rejetés</option>
          </select>
          <input
            type="text"
            placeholder="Rechercher un client..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <div className="space-y-4">
          {filteredOrders.map((order) => (
            <div
              key={order.id}
              id={`order-${order.id}`}
              className={`bg-white rounded-lg shadow-md overflow-hidden transition-all duration-200 ${
                expandedOrderId === order.id ? 'ring-2 ring-blue-500' : ''
              }`}
            >
              <button
                className="w-full flex items-center justify-between p-4 cursor-pointer hover:bg-gray-50 focus:outline-none"
                onClick={() => toggleOrderExpanded(order.id)}
              >
                <div className="flex items-center space-x-4">
                  <div>
                    <h2 className="text-lg font-medium text-gray-900">
                      {order.clientName} {order.orderNumber && `- ${order.orderNumber}`}
                    </h2>
                    <div className="flex items-center space-x-2 mt-1">
                      <p className="text-sm text-gray-500">
                        {new Date(order.createdAt).toLocaleDateString('fr-FR', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </p>
                      <span className="text-gray-300">•</span>
                      <p className="text-sm text-gray-500">{order.type}</p>
                    </div>
                  </div>
                </div>
                {expandedOrderId === order.id ? (
                  <ChevronUp className="w-5 h-5 text-gray-500" />
                ) : (
                  <ChevronDown className="w-5 h-5 text-gray-500" />
                )}
              </button>
              {expandedOrderId === order.id && (
                <div className="p-4 border-t border-gray-200">
                  {renderDocuments(order)}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      <Modal
        isOpen={modalState.isOpen}
        onClose={closeModal}
        title={
          modalState.type === 'validate'
            ? 'Valider le document'
            : modalState.type === 'reject'
            ? 'Rejeter le document'
            : 'Demander le document'
        }
      >
        <div className="p-6">
          <textarea
            value={commentInput}
            onChange={(e) => setCommentInput(e.target.value)}
            placeholder={
              modalState.type === 'request'
                ? 'Message pour le client...'
                : 'Ajouter un commentaire (optionnel)'
            }
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            rows={4}
          />
          <div className="mt-4 flex justify-end space-x-3">
            <button
              onClick={closeModal}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Annuler
            </button>
            <button
              onClick={handleModalConfirm}
              className={`px-4 py-2 border border-transparent rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                modalState.type === 'validate'
                  ? 'bg-green-600 hover:bg-green-700 focus:ring-green-500'
                  : modalState.type === 'reject'
                  ? 'bg-red-600 hover:bg-red-700 focus:ring-red-500'
                  : 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
              }`}
            >
              {modalState.type === 'validate'
                ? 'Valider'
                : modalState.type === 'reject'
                ? 'Rejeter'
                : 'Envoyer la demande'}
            </button>
          </div>
        </div>
      </Modal>

      <DocumentReminderModal
        isOpen={modalState.type === 'request' && modalState.isOpen}
        onClose={closeModal}
        onConfirm={handleModalConfirm}
        documentName={selectedOrder?.documents[modalState.documentId]?.name || ''}
      />
    </div>
  );
};

export default AdminDocuments;
