interface EmailConfig {
  fromEmail: string;
  fromName: string;
  siteUrl: string;
}

export const emailConfig: EmailConfig = {
  fromEmail: import.meta.env.VITE_EMAIL_FROM || 'noreply@em5370.minute-cartegrise.com',
  fromName: import.meta.env.VITE_EMAIL_FROM_NAME || 'Minute Carte Grise',
  siteUrl: import.meta.env.VITE_SITE_URL || 'http://localhost:5173'
};