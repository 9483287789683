import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { toast } from 'react-hot-toast';
import { Eye } from 'lucide-react';
import DocumentValidationModal from './documents/DocumentValidationModal';
import { DOCUMENT_GROUPS } from '../../config/documentGroups';
import { formatOrderId } from '../../utils/formatters';

interface OrderDocument {
  status: string;
  fileUrl?: string;
  fileName?: string;
  uploadedAt?: Date;
  uploadedBy?: string;
  comment?: string;
}

interface Order {
  id: string;
  orderNumber: string;
  type: string;
  documents: Record<string, OrderDocument>;
}

interface OrderDocumentsViewProps {
  orderId: string;
}

const OrderDocumentsView: React.FC<OrderDocumentsViewProps> = ({ orderId }) => {
  const [order, setOrder] = useState<Order | null>(null);
  const [selectedDocument, setSelectedDocument] = useState<{id: string; document: OrderDocument} | null>(null);
  const [modalMode, setModalMode] = useState<'validate' | 'reject'>('validate');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchOrder();
  }, [orderId]);

  const fetchOrder = async () => {
    if (!orderId) return;
    
    try {
      const orderRef = doc(db, 'orders', orderId);
      const orderDoc = await getDoc(orderRef);
      
      if (orderDoc.exists()) {
        const data = orderDoc.data();
        console.log('Raw order data:', data);

        const formattedOrder = {
          id: orderId,
          orderNumber: data.orderNumber || '',  // Ne pas utiliser l'ID comme fallback
          type: data.type || 'changement-titulaire',
          documents: data.documents || {}
        };
        
        console.log('Formatted order:', formattedOrder);  
        setOrder(formattedOrder);
      } else {
        toast.error('Commande non trouvée');
      }
    } catch (error) {
      console.error('Erreur lors de la récupération de la commande:', error);
      toast.error('Erreur lors de la récupération de la commande');
    }
  };

  const handleValidate = async (comment: string) => {
    if (!order || !selectedDocument) return;

    try {
      const orderRef = doc(db, 'orders', orderId);
      const updatedDocuments = { ...order.documents };
      updatedDocuments[selectedDocument.id] = {
        ...selectedDocument.document,
        status: 'validated',
        comment
      };

      await updateDoc(orderRef, {
        documents: updatedDocuments
      });

      setOrder(prev => prev ? {
        ...prev,
        documents: updatedDocuments
      } : null);

      toast.success('Document validé avec succès');
      setIsModalOpen(false);
      setSelectedDocument(null);
    } catch (error) {
      console.error('Erreur lors de la validation du document:', error);
      toast.error('Erreur lors de la validation du document');
    }
  };

  const handleReject = async (comment: string) => {
    if (!order || !selectedDocument) return;

    try {
      const orderRef = doc(db, 'orders', orderId);
      const updatedDocuments = { ...order.documents };
      updatedDocuments[selectedDocument.id] = {
        ...selectedDocument.document,
        status: 'rejected',
        comment
      };

      await updateDoc(orderRef, {
        documents: updatedDocuments
      });

      setOrder(prev => prev ? {
        ...prev,
        documents: updatedDocuments
      } : null);

      toast.success('Document rejeté');
      setIsModalOpen(false);
      setSelectedDocument(null);
    } catch (error) {
      console.error('Erreur lors du rejet du document:', error);
      toast.error('Erreur lors du rejet du document');
    }
  };

  const getStatusBadgeClass = (status: string) => {
    switch (status) {
      case 'validated':
        return 'bg-green-100 text-green-800';
      case 'rejected':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-yellow-100 text-yellow-800';
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'validated':
        return 'Validé';
      case 'rejected':
        return 'Rejeté';
      default:
        return 'En attente';
    }
  };

  if (!order) {
    return null;
  }

  return (
    <div className="space-y-8 p-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-semibold">
          {order.orderNumber ? `Commande ${order.orderNumber}` : 'Commande en cours de traitement'}
        </h2>
      </div>
      <div className="bg-white rounded-lg shadow-sm p-6">
        {order.type && DOCUMENT_GROUPS[order.type] ? (
          DOCUMENT_GROUPS[order.type].map(group => (
            <div key={group.id} className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold mb-6 text-gray-900 border-b pb-4">{group.title}</h2>
              <div className="grid md:grid-cols-2 gap-6">
                {group.documents.map(doc => {
                  const orderDoc = order.documents?.[doc.id] || { status: 'pending' };
                  return (
                    <div key={doc.id} className="border rounded-lg p-6 bg-gray-50 hover:bg-gray-100 transition-colors">
                      <div className="flex justify-between items-start space-x-4">
                        <div className="space-y-3 flex-grow">
                          <h3 className="font-medium text-gray-900">{doc.name}</h3>
                          <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${getStatusBadgeClass(orderDoc.status)}`}>
                            {getStatusText(orderDoc.status)}
                          </span>
                          {orderDoc.comment && (
                            <p className="text-sm text-gray-600 mt-2 bg-white p-3 rounded-md border">{orderDoc.comment}</p>
                          )}
                        </div>
                        <div className="flex space-x-3">
                          {orderDoc.fileUrl && (
                            <a
                              href={orderDoc.fileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-600 hover:text-blue-800 p-2 hover:bg-blue-50 rounded-full transition-colors"
                            >
                              <Eye className="w-5 h-5" />
                            </a>
                          )}
                          {orderDoc.status === 'pending' && (
                            <>
                              <button
                                onClick={() => {
                                  setSelectedDocument({ id: doc.id, document: orderDoc });
                                  setModalMode('validate');
                                  setIsModalOpen(true);
                                }}
                                className="text-green-600 hover:text-green-800 p-2 hover:bg-green-50 rounded-full transition-colors"
                              >
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                              </button>
                              <button
                                onClick={() => {
                                  setSelectedDocument({ id: doc.id, document: orderDoc });
                                  setModalMode('reject');
                                  setIsModalOpen(true);
                                }}
                                className="text-red-600 hover:text-red-800 p-2 hover:bg-red-50 rounded-full transition-colors"
                              >
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-12 bg-white rounded-lg shadow-sm">
            <p className="text-gray-500">Aucun document trouvé pour le type de commande : {order.type}</p>
          </div>
        )}
      </div>

      {selectedDocument && (
        <DocumentValidationModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedDocument(null);
          }}
          onValidate={handleValidate}
          onReject={handleReject}
          document={{
            id: selectedDocument.id,
            name: DOCUMENT_GROUPS[order.type]
              ?.flatMap(g => g.documents)
              .find(d => d.id === selectedDocument.id)?.name || '',
            status: selectedDocument.document.status,
            url: selectedDocument.document.fileUrl || '',
            comment: selectedDocument.document.comment
          }}
          mode={modalMode}
        />
      )}
    </div>
  );
};

export default OrderDocumentsView;
