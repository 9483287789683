import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Package, User, LogOut, FileText } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/config';

const ClientSidebar = () => {
  const { user, logout } = useAuth();
  const [clientName, setClientName] = useState('');

  useEffect(() => {
    const fetchClientInfo = async () => {
      if (!user?.email) return;

      try {
        // Extraire l'email original
        const originalEmail = user.email.includes('+') 
          ? user.email.split('+')[0] + '@' + user.email.split('@')[1]
          : user.email;

        // Chercher la dernière commande pour cet email
        const ordersRef = collection(db, 'orders');
        const q = query(ordersRef, where('email', '==', originalEmail));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // Prendre la première commande trouvée
          const orderData = querySnapshot.docs[0].data();
          const fullName = `${orderData.firstName || ''} ${orderData.lastName || ''}`.trim();
          setClientName(fullName || originalEmail); // Utiliser l'email si pas de nom trouvé
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des informations client:', error);
        setClientName(user.email);
      }
    };

    fetchClientInfo();
  }, [user]);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  return (
    <div className="hidden lg:block">
      <div className="w-64 bg-white rounded-lg shadow-lg p-6">
        <div className="mb-8">
          <h2 className="font-semibold text-gray-900 truncate">
            {clientName}
          </h2>
          <p className="text-sm text-gray-500">Client</p>
        </div>

        <nav className="space-y-2">
          <NavLink
            to="/dashboard"
            end
            className={({ isActive }) =>
              `flex items-center px-4 py-2 text-sm font-medium rounded-md ${
                isActive
                  ? 'bg-blue-50 text-blue-700'
                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
              }`
            }
          >
            <Package className="w-5 h-5 mr-3" />
            Commandes
          </NavLink>

          {/* L'onglet Documents est masqué mais la route reste active */}
          <NavLink
            to="/dashboard/profile"
            className={({ isActive }) =>
              `flex items-center px-4 py-2 text-sm font-medium rounded-md ${
                isActive
                  ? 'bg-blue-50 text-blue-700'
                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
              }`
            }
          >
            <User className="w-5 h-5 mr-3" />
            Mon profil
          </NavLink>

          <button
            onClick={handleLogout}
            className="w-full flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-600 hover:bg-red-50 hover:text-red-600"
          >
            <LogOut className="w-5 h-5 mr-3" />
            Déconnexion
          </button>
        </nav>
      </div>
    </div>
  );
};

export default ClientSidebar;