import React, { useState } from 'react';
import { Info } from 'lucide-react';

interface InteractiveCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  details: string[];
}

const InteractiveCard: React.FC<InteractiveCardProps> = ({
  icon,
  title,
  description,
  details
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={`
        relative bg-white rounded-lg shadow-lg overflow-hidden
        hover:shadow-xl transition-all duration-300 cursor-pointer
        ${isExpanded ? 'scale-105' : 'scale-100'}
      `}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className="p-6">
        <div className="flex items-center mb-4">
          <div className="flex items-center justify-center w-12 h-12 bg-blue-100 rounded-full mr-4">
            {icon}
          </div>
          <h3 className="text-xl font-semibold text-gray-900">{title}</h3>
        </div>
        <p className="text-gray-600 mb-4">{description}</p>
        
        <div
          className={`
            overflow-hidden transition-all duration-300
            ${isExpanded ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}
          `}
        >
          <div className="pt-4 border-t border-gray-200">
            <ul className="space-y-2">
              {details.map((detail, index) => (
                <li key={index} className="flex items-start text-gray-600">
                  <span className="inline-block w-2 h-2 bg-blue-500 rounded-full mt-2 mr-2" />
                  {detail}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InteractiveCard;
