import React, { useState } from 'react';
import { 
  Search, 
  FileText, 
  Upload, 
  CreditCard, 
  Clock, 
  CheckCircle, 
  HelpCircle,
  User,
  ShoppingCart,
  Bell,
  ArrowLeft,
  Monitor
} from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import InteractiveCard from './components/InteractiveCard';
import Accordion from './components/Accordion';
import VideoTutorial from './components/VideoTutorial';
import { faqUtilisation, videoTutorials } from './data/guideData';

const GuideUtilisation = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('interface');

  const interfaceFeatures = [
    {
      icon: <Monitor className="w-8 h-8 text-blue-500" />,
      title: "Interface principale",
      description: "Navigation simple et intuitive",
      details: [
        "Menu principal avec toutes les fonctionnalités",
        "Barre de recherche rapide",
        "Accès direct à votre profil",
        "Notifications en temps réel"
      ]
    },
    {
      icon: <Search className="w-8 h-8 text-blue-500" />,
      title: "Calculateur de tarif",
      description: "Estimation instantanée du coût",
      details: [
        "Sélection du type de véhicule",
        "Calcul automatique des taxes",
        "Historique des simulations",
        "Export PDF du devis"
      ]
    },
    {
      icon: <ShoppingCart className="w-8 h-8 text-blue-500" />,
      title: "Gestion des commandes",
      description: "Suivi complet de vos demandes",
      details: [
        "Liste de toutes vos commandes",
        "Statut en temps réel",
        "Historique détaillé",
        "Documents associés"
      ]
    }
  ];

  const processFeatures = [
    {
      icon: <User className="w-8 h-8 text-blue-500" />,
      title: "Création de compte",
      description: "Inscription rapide et sécurisée",
      details: [
        "Formulaire simplifié",
        "Vérification par email",
        "Protection des données",
        "Profil personnalisable"
      ]
    },
    {
      icon: <Upload className="w-8 h-8 text-blue-500" />,
      title: "Upload de documents",
      description: "Gestion simple des fichiers",
      details: [
        "Glisser-déposer de fichiers",
        "Vérification automatique",
        "Compression intelligente",
        "Stockage sécurisé"
      ]
    },
    {
      icon: <Bell className="w-8 h-8 text-blue-500" />,
      title: "Notifications",
      description: "Restez informé à chaque étape",
      details: [
        "Alertes par email",
        "Notifications push",
        "Centre de notifications",
        "Paramètres personnalisables"
      ]
    }
  ];

  const tabContent = {
    interface: {
      title: "Interface utilisateur",
      description: "Découvrez les principales fonctionnalités de notre plateforme",
      features: interfaceFeatures
    },
    process: {
      title: "Processus de demande",
      description: "Comment effectuer votre demande de carte grise",
      features: processFeatures
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      {/* Navigation */}
      <div className="max-w-7xl mx-auto mb-8">
        <button
          onClick={() => navigate('/guide')}
          className="inline-flex items-center text-blue-600 hover:text-blue-700"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Retour aux guides
        </button>
      </div>

      {/* Hero Section */}
      <div className="max-w-7xl mx-auto text-center mb-16">
        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          Guide d'utilisation
        </h1>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Apprenez à utiliser notre plateforme pour gérer vos demandes de carte grise en toute simplicité.
        </p>
      </div>

      {/* Tabs */}
      <div className="max-w-7xl mx-auto mb-12">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex justify-center space-x-8">
            {Object.entries(tabContent).map(([key, content]) => (
              <button
                key={key}
                onClick={() => setActiveTab(key)}
                className={`
                  py-4 px-1 border-b-2 font-medium text-sm
                  ${activeTab === key
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
                `}
              >
                {content.title}
              </button>
            ))}
          </nav>
        </div>
      </div>

      {/* Features Section */}
      <div className="max-w-7xl mx-auto mb-24">
        <div key={activeTab} className="transition-opacity duration-300">
          <h2 className="text-3xl font-bold text-gray-900 mb-4 text-center">
            {tabContent[activeTab].title}
          </h2>
          <p className="text-gray-600 text-center mb-12">
            {tabContent[activeTab].description}
          </p>
          <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
            {tabContent[activeTab].features.map((feature, index) => (
              <InteractiveCard
                key={index}
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
                details={feature.details}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Video Tutorials Section */}
      <div className="max-w-7xl mx-auto mb-24">
        <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">
          Tutoriels vidéo
        </h2>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          {videoTutorials.map((tutorial, index) => (
            <VideoTutorial key={index} {...tutorial} />
          ))}
        </div>
      </div>

      {/* FAQ Section */}
      <div className="max-w-7xl mx-auto mb-24">
        <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">
          Questions fréquentes
        </h2>
        <Accordion items={faqUtilisation} />
      </div>

      {/* Contact Section */}
      <div className="max-w-7xl mx-auto">
        <div className="bg-blue-50 p-8 rounded-2xl text-center">
          <div className="inline-block p-3 bg-blue-100 rounded-full mb-4">
            <HelpCircle className="w-8 h-8 text-blue-500" />
          </div>
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Besoin d'assistance ?</h2>
          <p className="text-gray-600 mb-6">
            Notre équipe support est là pour vous aider à utiliser notre plateforme
          </p>
          <div className="space-x-4">
            <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-300">
              Contacter le support
            </button>
            <Link
              to="/guide/carte-grise"
              className="inline-flex items-center px-6 py-3 border border-blue-600 text-base font-medium rounded-md text-blue-600 hover:bg-blue-50 transition-colors duration-300"
            >
              Guide des démarches
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuideUtilisation;
