import React, { useEffect, useState, useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Box, CircularProgress, Typography } from '@mui/material';
import PaymentForm from './PaymentForm';

interface StripeWrapperProps {
  clientSecret: string;
  orderId?: string;
  amount?: number;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

// Accès à la variable d'environnement
const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_STRIPE_PUBLIC_KEY;

// Log pour le debug
console.log('Stripe Key Status:', {
  keyExists: !!STRIPE_PUBLISHABLE_KEY,
  envVars: import.meta.env
});

if (!STRIPE_PUBLISHABLE_KEY) {
  console.error('La clé publique Stripe n\'est pas définie dans les variables d\'environnement');
  console.error('Variables disponibles:', import.meta.env);
}

// Initialisation de Stripe une seule fois pour toute l'application
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY || '');

const StripeWrapper: React.FC<StripeWrapperProps> = ({
  clientSecret,
  orderId = '',
  amount = 0,
  onSuccess = () => {},
  onError = () => {}
}) => {
  const [isStripeReady, setIsStripeReady] = useState(false);

  // Mémoriser les options pour éviter les re-rendus inutiles
  const options = useMemo(() => ({
    clientSecret,
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '4px',
        borderRadius: '4px',
      },
    },
    locale: 'fr'
  }), [clientSecret]);

  useEffect(() => {
    let mounted = true;

    const checkStripe = async () => {
      try {
        const stripe = await stripePromise;
        if (mounted && stripe) {
          setIsStripeReady(true);
        }
      } catch (error) {
        console.error('Erreur lors de l\'initialisation de Stripe:', error);
        if (mounted) {
          onError(error as Error);
        }
      }
    };

    checkStripe();

    return () => {
      mounted = false;
    };
  }, [onError]);

  if (!clientSecret) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight={200}>
        <CircularProgress size={40} />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Initialisation du système de paiement...
        </Typography>
      </Box>
    );
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentForm
        orderId={orderId}
        amount={amount}
        onSuccess={onSuccess}
        onError={onError}
      />
    </Elements>
  );
};

export default StripeWrapper;