import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { fetchVehicleInfo } from '../services/sivApi';
import { Car, Calendar, Leaf, Euro } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { calculateCartGrise } from '../services/carteGriseCalculator';
import { demarches } from '../data/demarches';
import { departements } from '../data/departements';
import { generateOrderNumber } from '../utils/orderNumber';
import DemarcheSelector from './DemarcheSelector';

interface CalculationResult {
  y1TaxeRegionale: number;
  y2MajorationTransport: number;
  y3MalusCO2: number;
  y4TaxeFixe: number;
  y5Acheminement: number;
  y6TotalTaxes: number;
  fraisDeGestion: number;
  total: number;
  formattedValues: {
    y1TaxeRegionale: string;
    y2MajorationTransport: string;
    y3MalusCO2: string;
    y4TaxeFixe: string;
    y5Acheminement: string;
    y6TotalTaxes: string;
    fraisDeGestion: string;
    total: string;
  };
}

const Calculateur = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    demarche: '',
    immatriculation: '',
    departement: '',
    vehiculeInfo: {
      AWN_marque: '',
      AWN_modele: '',
      AWN_puissance_fiscale: '',
      AWN_emission_co_2: '',
      AWN_date_mise_en_circulation: '',
      AWN_energie: '',
      AWN_genre_carte_grise: '',
      AWN_version: ''
    }
  });

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [calculationResult, setCalculationResult] = useState<CalculationResult | null>(null);

  const handleImmatriculationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.toUpperCase();
    
    // Supprimer tous les caractères non alphanumériques
    value = value.replace(/[^A-Z0-9]/g, '');
    
    // Formater au format XX-XXX-XX
    if (value.length > 0) {
      let formatted = value;
      if (value.length > 2) {
        formatted = value.slice(0, 2) + '-' + value.slice(2);
      }
      if (value.length > 5) {
        formatted = formatted.slice(0, 6) + '-' + formatted.slice(6);
      }
      value = formatted;
    }

    // Limiter à 9 caractères (XX-XXX-XX)
    if (value.length <= 9) {
      setFormData(prev => ({ ...prev, immatriculation: value }));
    }
  };

  const handleCalculate = async () => {
    if (formData.immatriculation.replace(/-/g, '').length === 7) {
      setIsLoading(true);
      try {
        // Récupérer les informations du véhicule pour toutes les démarches
        const vehicleInfo = await fetchVehicleInfo(formData.immatriculation);

        console.log('Informations du véhicule reçues:', JSON.stringify(vehicleInfo, null, 2));
        
        if (!vehicleInfo) {
          throw new Error('Aucune information véhicule n\'a été reçue');
        }

        // Pour le changement d'adresse, on utilise un calcul fixe mais on garde les infos véhicule
        const calculationInput = {
          demarche: formData.demarche,
          departement: formData.departement,
          puissanceFiscale: formData.demarche === 'changement-adresse' ? 0 : parseInt(vehicleInfo.AWN_puissance_fiscale) || 0,
          co2: formData.demarche === 'changement-adresse' ? 0 : parseInt(vehicleInfo.AWN_emission_co_2) || 0,
          datePremiereImmatriculation: vehicleInfo.AWN_date_mise_en_circulation ? 
            new Date(vehicleInfo.AWN_date_mise_en_circulation.split('-').reverse().join('-')) : 
            new Date(),
          energie: vehicleInfo.AWN_energie,
          genre: vehicleInfo.AWN_genre_carte_grise || ''
        };

        const result = await calculateCartGrise(calculationInput);

        if (!result) {
          throw new Error('Erreur lors du calcul des taxes');
        }

        // Log détaillé du résultat
        console.log('Détail du calcul:');
        console.log('Taxe régionale:', result.y1TaxeRegionale);
        console.log('Majoration transport:', result.y2MajorationTransport);
        console.log('Malus CO2:', result.y3MalusCO2);
        console.log('Taxe fixe:', result.y4TaxeFixe);
        console.log('Redevance acheminement:', result.y5Acheminement);
        console.log('Total taxes avant frais:', result.y6TotalTaxes);
        console.log('Frais de service:', result.fraisDeGestion);
        console.log('Total final:', result.total);

        setCalculationResult(result as CalculationResult);
        console.log('Résultat du calcul:', result);

        // Transformer et stocker les données avec les valeurs directement de l'API
        console.log('Type de AWN_date_mise_en_circulation:', typeof vehicleInfo.AWN_date_mise_en_circulation);
        console.log('Valeur de AWN_date_mise_en_circulation:', vehicleInfo.AWN_date_mise_en_circulation);
        
        const vehiculeData = {
          marque: vehicleInfo.AWN_marque || 'Non spécifié',
          modele: vehicleInfo.AWN_modele || 'Non spécifié',
          immatriculation: formData.immatriculation,
          dateMiseEnCirculation: vehicleInfo.AWN_date_mise_en_circulation || '',
          energie: vehicleInfo.AWN_energie,
          puissance: vehicleInfo.AWN_puissance_fiscale || '0'
        };
        
        console.log('vehiculeData complet:', vehiculeData);
        console.log('Date stockée dans vehiculeData:', vehiculeData.dateMiseEnCirculation);

        // Stocker toutes les données nécessaires
        const orderNumber = await generateOrderNumber();
        const orderData = {
          orderNumber,
          type: formData.demarche, // Temporairement gardé pour compatibilité
          demarche: formData.demarche,
          departement: formData.departement,
          amount: result.total, // Temporairement gardé pour compatibilité
          totalAmount: result.total,
          vehicule: vehiculeData,
          // Stocker aussi les valeurs individuelles pour la rétrocompatibilité
          y1TaxeRegionale: result.y1TaxeRegionale,
          y2MajorationTransport: result.y2MajorationTransport,
          y3MalusCO2: result.y3MalusCO2,
          y4TaxeFixe: result.y4TaxeFixe,
          y5Acheminement: result.y5Acheminement,
          y6TotalTaxes: result.y6TotalTaxes,
          taxes: {
            taxeRegionale: result.y1TaxeRegionale,
            taxeGestion: result.y4TaxeFixe,
            taxeRedevanceAcheminement: result.y5Acheminement,
            taxesCO2: result.y3MalusCO2,
            majorationTransport: result.y2MajorationTransport,
            total: result.y6TotalTaxes
          }
        };
        console.log('Données à stocker dans localStorage:', orderData);
        localStorage.setItem('vehiculeData', JSON.stringify(vehiculeData));
        localStorage.setItem('orderData', JSON.stringify(orderData));

        setIsCalculated(true);
        setShowDetails(true);
        toast.success('Informations du véhicule récupérées avec succès');
        navigate('/recap');
      } catch (error) {
        console.error('Erreur lors de la récupération des informations du véhicule:', error);
        toast.error(error instanceof Error ? error.message : 'Erreur lors de la récupération des informations');
        setIsCalculated(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto px-4 sm:px-6 py-4 sm:py-6">
      <div className="bg-white rounded-2xl shadow-lg p-4 sm:p-8">
        <div className="space-y-6">
          <div>
            <DemarcheSelector 
              selectedDemarche={formData.demarche}
              onDemarcheChange={(demarche) => setFormData(prev => ({ ...prev, demarche }))}
            />
          </div>

          <div>
            <label className="block text-base sm:text-lg font-medium text-gray-700 mb-2">
              Numéro d'immatriculation
            </label>
            <div className="relative">
              <div className="flex items-stretch bg-white rounded-xl overflow-hidden shadow-md border border-gray-200">
                <div className="bg-[#003399] text-white flex items-center justify-center w-[60px] sm:w-[80px]">
                  <span className="font-bold text-2xl sm:text-3xl">F</span>
                </div>
                <input
                  type="text"
                  value={formData.immatriculation}
                  onChange={handleImmatriculationChange}
                  placeholder="DE-DED-DE"
                  maxLength={9}
                  className="flex-1 px-2 sm:px-4 text-center text-xl sm:text-3xl font-bold tracking-wider focus:outline-none focus:ring-0 border-none bg-white placeholder-gray-300"
                  style={{ letterSpacing: '0.25em', minWidth: '0' }}
                />
                <div className="bg-[#003399] w-[60px] sm:w-[80px]"></div>
              </div>
            </div>
          </div>

          <div>
            <label className="block text-base sm:text-lg font-medium text-gray-700 mb-2">
              Département
            </label>
            <select
              value={formData.departement}
              onChange={(e) => setFormData(prev => ({ ...prev, departement: e.target.value }))}
              className="w-full rounded-xl border border-gray-300 py-3 px-4 text-base focus:border-blue-500 focus:ring-blue-500 bg-white"
            >
              <option value="">Sélectionner un département</option>
              {departements.map(dept => (
                <option key={dept.code} value={dept.code}>
                  {dept.nom} ({dept.code})
                </option>
              ))}
            </select>
          </div>

          <button
            onClick={handleCalculate}
            disabled={
              isLoading || 
              formData.immatriculation.replace(/-/g, '').length !== 7 ||
              !formData.demarche ||
              !formData.departement
            }
            className={`w-full py-3 sm:py-4 px-4 rounded-xl transition-colors flex items-center justify-center gap-2 text-base sm:text-lg font-medium ${
              isLoading || !formData.demarche || !formData.departement || formData.immatriculation.replace(/-/g, '').length !== 7
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700 text-white'
            }`}
          >
            {isLoading ? (
              <div className="flex items-center gap-2">
                <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent"></div>
                <span>Calcul en cours...</span>
              </div>
            ) : (
              <>
                <Euro className="w-5 h-5" />
                <span>Calculer le tarif</span>
              </>
            )}
          </button>

          {isCalculated && (
            <div className="bg-blue-600 text-white p-4 rounded-xl text-sm">
              <h2 className="text-lg font-bold mb-4">Détail de votre demande</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                <div className="bg-blue-500/30 p-3 rounded-lg">
                  <div className="flex items-center gap-2 mb-2">
                    <span className="material-icons text-xl">description</span>
                    <span className="text-sm opacity-75">Démarche</span>
                  </div>
                  <p className="font-medium truncate">
                    {demarches.find(d => d.id === formData.demarche)?.label || '-'}
                  </p>
                </div>

                <div className="bg-blue-500/30 p-3 rounded-lg">
                  <div className="flex items-center gap-2 mb-2">
                    <Car className="w-5 h-5" />
                    <span className="text-sm opacity-75">Véhicule</span>
                  </div>
                  <p className="font-medium truncate">
                    {formData.vehiculeInfo.AWN_marque ? `${formData.vehiculeInfo.AWN_marque} ${formData.vehiculeInfo.AWN_modele}` : '-'}
                  </p>
                  <p className="text-sm truncate">
                    {formData.vehiculeInfo.AWN_puissance_fiscale ? `${formData.vehiculeInfo.AWN_puissance_fiscale} CV` : '0'} 
                    {formData.vehiculeInfo.AWN_energie ? ` - ${formData.vehiculeInfo.AWN_energie}` : ''}
                  </p>
                </div>

                <div className="bg-blue-500/30 p-3 rounded-lg">
                  <div className="flex items-center gap-2 mb-2">
                    <Calendar className="w-5 h-5" />
                    <span className="text-sm opacity-75">Mise en circulation</span>
                  </div>
                  <p className="font-medium">
                    {formData.vehiculeInfo.AWN_date_mise_en_circulation ? 
                      new Date(formData.vehiculeInfo.AWN_date_mise_en_circulation).toLocaleDateString() : 
                      '-'}
                  </p>
                </div>

                <div className="bg-blue-500/30 p-3 rounded-lg">
                  <div className="flex items-center gap-2 mb-2">
                    <Leaf className="w-5 h-5" />
                    <span className="text-sm opacity-75">CO2</span>
                  </div>
                  <p className="font-medium">
                    {formData.vehiculeInfo.AWN_emission_co_2 ? `${formData.vehiculeInfo.AWN_emission_co_2} g/km` : '0 g/km'}
                  </p>
                </div>
              </div>
            </div>
          )}

          {isCalculated && (
            <div className="bg-white rounded-xl p-6 shadow-lg mt-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold text-gray-900">Taxes et redevances</h2>
                <button
                  onClick={() => setShowDetails(!showDetails)}
                  className="text-blue-600 hover:text-blue-800 text-sm font-medium"
                >
                  {showDetails ? 'Masquer le détail' : 'Afficher le détail'}
                </button>
              </div>

              {showDetails && (
                <div className="space-y-4 mb-6">
                  {/* Taxe régionale */}
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-gray-700">Taxe régionale</p>
                      {calculationResult?.y1TaxeRegionale > 0 && (
                        <p className="text-green-600 text-sm">Réduction de 50% appliquée (véhicule de plus de 10 ans)</p>
                      )}
                    </div>
                    <p className="text-gray-900 font-medium">{(calculationResult?.y1TaxeRegionale || 0).toFixed(2)} €</p>
                  </div>

                  {/* Majoration transport */}
                  {calculationResult?.y2MajorationTransport > 0 && (
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="text-gray-700">Majoration transport</p>
                        <p className="text-gray-500 text-sm">Applicable aux véhicules utilitaires</p>
                      </div>
                      <p className="text-gray-900 font-medium">{calculationResult.y2MajorationTransport.toFixed(2)} €</p>
                    </div>
                  )}

                  {/* Malus CO2 */}
                  {calculationResult?.y3MalusCO2 > 0 && (
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="text-gray-700">Malus CO2</p>
                      </div>
                      <p className="text-gray-900 font-medium">{calculationResult.y3MalusCO2.toFixed(2)} €</p>
                    </div>
                  )}

                  {/* Taxe de gestion */}
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-gray-700">Taxe de gestion</p>
                    </div>
                    <p className="text-gray-900 font-medium">{(calculationResult?.y4TaxeFixe || 0).toFixed(2)} €</p>
                  </div>

                  {/* Redevance d'acheminement */}
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-gray-700">Redevance d'acheminement</p>
                    </div>
                    <p className="text-gray-900 font-medium">{(calculationResult?.y5Acheminement || 0).toFixed(2)} €</p>
                  </div>

                  {/* Frais de service */}
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-gray-700">Frais de service</p>
                    </div>
                    <p className="text-gray-900 font-medium">{(calculationResult?.fraisDeGestion || 0).toFixed(2)} €</p>
                  </div>
                </div>
              )}

              <div className="flex justify-between items-center pt-4 border-t border-gray-200">
                <p className="text-lg font-bold text-gray-900">Total</p>
                <p className="text-xl font-bold text-blue-600">{(calculationResult?.total || 0).toFixed(2)} €</p>
              </div>
            </div>
          )}

          {error && (
            <div className="bg-red-50 text-red-700 p-4 rounded-xl text-sm">
              {error}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Calculateur;