import React, { useState } from 'react';
import { Shield, RefreshCw, AlertCircle, CheckCircle } from 'lucide-react';
import { testSendGridConnection, SendGridTestResult } from '../../../services/apiTest';
import { toast } from 'react-hot-toast';

const ApiTest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [testResult, setTestResult] = useState<SendGridTestResult | null>(null);

  const testConnection = async () => {
    try {
      setIsLoading(true);
      setTestResult(null);
      
      const result = await testSendGridConnection();
      setTestResult(result);
      
      if (result.success) {
        toast.success('Connexion SendGrid établie avec succès');
      } else {
        toast.error(result.error || 'Échec de la connexion SendGrid');
      }
    } catch (error: any) {
      console.error('Erreur lors du test:', error);
      toast.error('Erreur lors du test de connexion');
      setTestResult({
        success: false,
        error: error.message || 'Une erreur inattendue est survenue'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex items-center gap-3 mb-6">
        <Shield className="w-6 h-6 text-blue-600" />
        <h2 className="text-2xl font-bold text-gray-900">Test de connexion SendGrid</h2>
      </div>

      <div className="space-y-6">
        <div className="bg-gray-50 rounded-lg p-4">
          <p className="text-sm text-gray-600 mb-4">
            Testez la connexion avec l'API SendGrid pour vérifier que la configuration est correcte.
          </p>

          {testResult && (
            <div className={`mb-4 p-4 rounded-lg ${
              testResult.success 
                ? 'bg-green-50 text-green-700' 
                : 'bg-red-50 text-red-700'
            }`}>
              <div className="flex items-center gap-2 mb-2">
                {testResult.success ? (
                  <CheckCircle className="w-5 h-5" />
                ) : (
                  <AlertCircle className="w-5 h-5" />
                )}
                <span className="font-medium">
                  {testResult.success 
                    ? 'Connexion réussie' 
                    : 'Échec de la connexion'}
                </span>
              </div>
              {testResult.success && testResult.account ? (
                <div className="mt-2 text-sm space-y-1">
                  <p>Type de compte : {testResult.account.type}</p>
                  <p>Réputation : {testResult.account.reputation}%</p>
                  <p>Client revendeur : {testResult.account.is_reseller_customer ? 'Oui' : 'Non'}</p>
                </div>
              ) : testResult.error && (
                <div className="text-sm mt-1">
                  <p className="font-medium">Erreur :</p>
                  <p>{testResult.error}</p>
                </div>
              )}
            </div>
          )}

          <button
            onClick={testConnection}
            disabled={isLoading}
            className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <RefreshCw className="w-5 h-5 animate-spin" />
                <span>Test en cours...</span>
              </>
            ) : (
              <>
                <Shield className="w-5 h-5" />
                <span>Tester la connexion</span>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApiTest;