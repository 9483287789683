import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/config';

export const validateOrder = async (orderId: string) => {
  try {
    const orderRef = doc(db, 'orders', orderId);
    
    // Mettre à jour le statut de la commande
    await updateDoc(orderRef, {
      status: 'validated',
      validatedAt: new Date(),
    });
    
    return { success: true, message: 'Commande validée avec succès' };
  } catch (error) {
    console.error('Erreur lors de la validation de la commande:', error);
    return { success: false, message: 'Erreur lors de la validation de la commande' };
  }
};

export const clearAllOrders = async () => {
  try {
    // Récupérer toutes les commandes
    const ordersRef = collection(db, 'orders');
    const snapshot = await getDocs(ordersRef);
    
    console.log(`Nombre de commandes trouvées : ${snapshot.size}`);
    
    // Supprimer chaque commande
    const deletePromises = snapshot.docs.map(async (doc) => {
      console.log(`Suppression de la commande ${doc.id}...`);
      await deleteDoc(doc.ref);
    });
    
    await Promise.all(deletePromises);
    
    return { success: true, message: `${snapshot.size} commandes ont été supprimées avec succès` };
  } catch (error) {
    console.error('Erreur lors de la suppression des commandes:', error);
    return { success: false, message: 'Erreur lors de la suppression des commandes' };
  }
};
