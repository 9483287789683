import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import toast from 'react-hot-toast';
import AddressAutocomplete from '../AddressAutocomplete';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/config';

const Profile = () => {
  const { user, userProfile, updateProfile, isAdmin } = useAuth();
  
  // Si c'est un admin, ne rien faire
  if (isAdmin) {
    return (
      <div className="p-6">
        <h2 className="text-xl font-semibold mb-4">Profil Administrateur</h2>
        <p>Cette page est réservée aux clients.</p>
      </div>
    );
  }

  // Extraire l'email original dès le début
  const originalEmail = user?.email?.includes('+') 
    ? user.email.split('+')[0] + '@' + user.email.split('@')[1]
    : user?.email || '';

  const [formData, setFormData] = useState({
    email: originalEmail,
    prenom: '',
    nom: '',
    telephone: '',
    adresse: '',
    ville: '',
    codePostal: ''
  });

  useEffect(() => {
    console.log('Effect déclenché avec:', {
      user,
      userProfile,
      originalEmail,
      isAdmin
    });

    const fetchClientInfo = async () => {
      // Ne pas charger les infos si c'est un admin
      if (!originalEmail || isAdmin) {
        console.log('Arrêt : pas d\'email ou admin', { originalEmail, isAdmin });
        return;
      }

      try {
        console.log('Début de la recherche pour l\'email:', originalEmail);
        
        // Chercher la dernière commande pour cet email
        const ordersRef = collection(db, 'orders');
        const emailToSearch = originalEmail.toLowerCase();
        console.log('Email recherché (en minuscules):', emailToSearch);
        
        const q = query(
          ordersRef, 
          where('email', '==', emailToSearch)
        );
        const querySnapshot = await getDocs(q);
        console.log('Nombre de commandes trouvées:', querySnapshot.size);

        if (!querySnapshot.empty) {
          // Prendre la commande la plus récente
          let mostRecentOrder = querySnapshot.docs[0];
          let mostRecentDate = mostRecentOrder.data().createdAt?.toMillis() || 0;

          querySnapshot.docs.forEach(doc => {
            const orderData = doc.data();
            console.log('Commande trouvée:', {
              id: doc.id,
              email: orderData.email,
              date: orderData.createdAt?.toDate(),
              userDetails: orderData.userDetails
            });
            
            const orderDate = orderData.createdAt?.toMillis() || 0;
            if (orderDate > mostRecentDate) {
              mostRecentOrder = doc;
              mostRecentDate = orderDate;
            }
          });

          const orderData = mostRecentOrder.data();
          console.log('Commande la plus récente:', {
            id: mostRecentOrder.id,
            date: orderData.createdAt?.toDate(),
            userDetails: orderData.userDetails
          });
          
          // Extraire les détails utilisateur de la commande
          const userDetails = orderData.userDetails || {};
          console.log('Détails utilisateur extraits:', userDetails);
          
          // Mettre à jour le formulaire avec les données de la commande
          const updatedFormData = {
            email: originalEmail,
            prenom: userDetails.firstName || '',
            nom: userDetails.lastName || '',
            telephone: userDetails.phone || '',
            adresse: userDetails.address || '',
            ville: userDetails.city || '',
            codePostal: userDetails.postalCode || ''
          };

          console.log('Données du formulaire à mettre à jour:', updatedFormData);
          setFormData(updatedFormData);

          // Mettre à jour le profil dans Firestore
          if (!isAdmin) {
            const profileData = {
              ...updatedFormData,
              role: 'client',
              createdAt: new Date(),
              updatedAt: new Date()
            };
            
            console.log('Mise à jour du profil Firestore avec:', profileData);
            await updateProfile(profileData);
            console.log('Profil mis à jour dans Firestore avec succès');
          }
        } else {
          console.log('Aucune commande trouvée pour l\'email:', originalEmail);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des informations client:', error);
      }
    };

    // Toujours charger les informations de la dernière commande
    fetchClientInfo();
    
  }, [user, userProfile, originalEmail, isAdmin]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const profileData = {
        prenom: formData.prenom,
        nom: formData.nom,
        telephone: formData.telephone,
        adresse: formData.adresse,
        ville: formData.ville,
        codePostal: formData.codePostal,
        email: originalEmail,
        role: 'client',
        updatedAt: new Date()
      };
      
      await updateProfile(profileData);
      toast.success('Profil mis à jour avec succès');
    } catch (error) {
      console.error('Erreur lors de la mise à jour du profil:', error);
      toast.error('Erreur lors de la mise à jour du profil');
    }
  };

  const handleAddressSelect = (address: { label: string; postcode: string; city: string }) => {
    setFormData(prev => ({
      ...prev,
      adresse: address.label,
      codePostal: address.postcode,
      ville: address.city
    }));
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-bold text-gray-900 mb-6">Mon profil</h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Prénom
            </label>
            <input
              type="text"
              value={formData.prenom}
              onChange={(e) => setFormData(prev => ({ ...prev, prenom: e.target.value }))}
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Nom
            </label>
            <input
              type="text"
              value={formData.nom}
              onChange={(e) => setFormData(prev => ({ ...prev, nom: e.target.value }))}
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Email
          </label>
          <input
            type="email"
            value={formData.email}
            disabled
            className="w-full rounded-lg border-gray-300 bg-gray-50 shadow-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Téléphone
          </label>
          <input
            type="tel"
            value={formData.telephone}
            onChange={(e) => setFormData(prev => ({ ...prev, telephone: e.target.value }))}
            className="w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Adresse
          </label>
          <AddressAutocomplete
            defaultValue={userProfile?.adresse || ''}
            onSelect={handleAddressSelect}
            className="w-full"
          />
        </div>

        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Ville
            </label>
            <input
              type="text"
              value={formData.ville}
              onChange={(e) => setFormData(prev => ({ ...prev, ville: e.target.value }))}
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              readOnly
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Code postal
            </label>
            <input
              type="text"
              value={formData.codePostal}
              onChange={(e) => setFormData(prev => ({ ...prev, codePostal: e.target.value }))}
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              readOnly
            />
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
          >
            Enregistrer les modifications
          </button>
        </div>
      </form>
    </div>
  );
};

export default Profile;