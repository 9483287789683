import React from 'react';
import { CheckCircle, XCircle, Send } from 'lucide-react';
import { Document } from '../../../types/common';

interface DocumentActionsProps {
  document: Document;
  orderId: string;
  documentId: string;
  onValidate: () => void;
  onReject: () => void;
  onRequestDocument: () => void;
}

const DocumentActions: React.FC<DocumentActionsProps> = ({
  document,
  onValidate,
  onReject,
  onRequestDocument,
}) => {
  // Si le document a une URL, montrer les boutons de validation/rejet
  if (document.url) {
    return (
      <div className="flex space-x-2">
        <button
          onClick={onValidate}
          className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          <CheckCircle className="w-4 h-4 mr-1" />
          Valider
        </button>
        <button
          onClick={onReject}
          className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          <XCircle className="w-4 h-4 mr-1" />
          Rejeter
        </button>
      </div>
    );
  }

  // Si le document n'a pas d'URL et est requis, montrer le bouton de demande
  if (document.required && (!document.status || document.status === 'pending')) {
    return (
      <button
        onClick={onRequestDocument}
        className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        <Send className="w-4 h-4 mr-1" />
        Demander
      </button>
    );
  }

  return null;
};

export default DocumentActions;