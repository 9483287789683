import React from 'react';
import { CheckCircle, XCircle, Clock } from 'lucide-react';

interface DocumentStatusBadgeProps {
  status: 'pending' | 'validated' | 'rejected';
}

const DocumentStatusBadge: React.FC<DocumentStatusBadgeProps> = ({ status }) => {
  const getStatusConfig = () => {
    switch (status) {
      case 'validated':
        return {
          icon: <CheckCircle className="w-4 h-4 mr-1" />,
          text: 'Validé',
          className: 'bg-green-100 text-green-800'
        };
      case 'rejected':
        return {
          icon: <XCircle className="w-4 h-4 mr-1" />,
          text: 'Rejeté',
          className: 'bg-red-100 text-red-800'
        };
      default:
        return {
          icon: <Clock className="w-4 h-4 mr-1" />,
          text: 'En attente',
          className: 'bg-yellow-100 text-yellow-800'
        };
    }
  };

  const { icon, text, className } = getStatusConfig();

  return (
    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${className}`}>
      {icon}
      {text}
    </span>
  );
};

export default DocumentStatusBadge;