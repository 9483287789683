import { doc, updateDoc, arrayUnion, Timestamp } from 'firebase/firestore';
import { db } from '../firebase/config';
import { Order } from '../types/common';

export const sendDocumentReminder = async (
  orderId: string,
  documentId: string,
  message: string
): Promise<void> => {
  try {
    const orderRef = doc(db, 'orders', orderId);
    
    // Mettre à jour l'historique des relances dans l'ordre
    await updateDoc(orderRef, {
      [`documents.${documentId}.reminders`]: arrayUnion({
        date: Timestamp.now(),
        message: message || 'Document demandé',
      }),
      lastUpdated: Timestamp.now(),
    });

    // TODO: Implémenter l'envoi d'email au client
    console.log('Email de relance envoyé pour le document:', documentId);
  } catch (error) {
    console.error('Erreur lors de l\'envoi de la relance:', error);
    throw new Error('Impossible d\'envoyer la relance pour le document');
  }
};
