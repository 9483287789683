export const SITE_CONFIG = {
  MAIN_SITE_URL: 'https://minute-cartegrise.com',
  MAIN_SITE_LINKS: {
    HOME: 'https://minute-cartegrise.com',
    ABOUT: 'https://minute-cartegrise.com/a-propos',
    CONTACT: 'https://minute-cartegrise.com/contact'
  },
  COMPANY_NAME: 'Minute Carte Grise',
  SUPPORT_EMAIL: 'contact@minute-cartegrise.com',
  LOGO_URL: 'https://minute-cartegrise.com/wp-content/uploads/2024/01/logo.png' // À remplacer par l'URL réelle de votre logo
};
