import React, { useState } from 'react';
import { Mail, RefreshCw, AlertCircle, CheckCircle } from 'lucide-react';
import { sendTestEmail, EmailTestResult } from '../../../services/emailTest';
import { toast } from 'react-hot-toast';

const EmailTest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [testResult, setTestResult] = useState<EmailTestResult | null>(null);

  const handleSendTest = async () => {
    try {
      setIsLoading(true);
      setTestResult(null);
      
      const result = await sendTestEmail();
      setTestResult(result);
      
      if (result.success) {
        toast.success('Email de test envoyé avec succès');
      } else {
        toast.error(result.error || 'Échec de l\'envoi de l\'email');
      }
    } catch (error: any) {
      console.error('Erreur lors de l\'envoi:', error);
      toast.error('Erreur lors de l\'envoi de l\'email de test');
      setTestResult({
        success: false,
        error: error.message || 'Une erreur inattendue est survenue'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex items-center gap-3 mb-6">
        <Mail className="w-6 h-6 text-blue-600" />
        <h2 className="text-2xl font-bold text-gray-900">Test d'envoi d'email</h2>
      </div>

      <div className="space-y-6">
        <div className="bg-gray-50 rounded-lg p-4">
          <p className="text-sm text-gray-600 mb-4">
            Envoyez un email de test pour vérifier que la configuration SendGrid fonctionne correctement.
          </p>

          {testResult && (
            <div className={`mb-4 p-4 rounded-lg ${
              testResult.success 
                ? 'bg-green-50 text-green-700' 
                : 'bg-red-50 text-red-700'
            }`}>
              <div className="flex items-center gap-2">
                {testResult.success ? (
                  <CheckCircle className="w-5 h-5" />
                ) : (
                  <AlertCircle className="w-5 h-5" />
                )}
                <span className="font-medium">
                  {testResult.success 
                    ? 'Email envoyé avec succès' 
                    : 'Échec de l\'envoi'}
                </span>
              </div>
              {testResult.error && (
                <p className="text-sm mt-2">{testResult.error}</p>
              )}
              {testResult.messageId && (
                <p className="text-sm mt-2">ID du message : {testResult.messageId}</p>
              )}
            </div>
          )}

          <button
            onClick={handleSendTest}
            disabled={isLoading}
            className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <RefreshCw className="w-5 h-5 animate-spin" />
                <span>Envoi en cours...</span>
              </>
            ) : (
              <>
                <Mail className="w-5 h-5" />
                <span>Envoyer un email de test</span>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailTest;