import React, { useState } from 'react';
import { FileText, User, Car } from 'lucide-react';
import OrderClientTab from './OrderClientTab';
import OrderDocumentsView from './OrderDocumentsView';

interface OrderTabsProps {
  order: any;
}

const OrderTabs: React.FC<OrderTabsProps> = ({ order }) => {
  const [activeTab, setActiveTab] = useState('documents');

  const tabs = [
    { id: 'documents', label: 'Documents', icon: FileText },
    { id: 'client', label: 'Client', icon: User },
    { id: 'vehicule', label: 'Véhicule', icon: Car }
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'documents':
        return <OrderDocumentsView orderId={order.id} />;
      case 'client':
        return <OrderClientTab client={order.client} />;
      case 'vehicule':
        return (
          <div className="p-4">
            <h3 className="text-lg font-medium text-gray-900">Informations véhicule</h3>
            {order.vehicule ? (
              <div className="mt-4 space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm font-medium text-gray-500">Marque</p>
                    <p className="mt-1 text-sm text-gray-900">{order.vehicule.marque}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Modèle</p>
                    <p className="mt-1 text-sm text-gray-900">{order.vehicule.modele}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Immatriculation</p>
                    <p className="mt-1 text-sm text-gray-900">{order.vehicule.immatriculation}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Date de mise en circulation</p>
                    <p className="mt-1 text-sm text-gray-900">{order.vehicule.dateMiseEnCirculation}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Énergie</p>
                    <p className="mt-1 text-sm text-gray-900">{order.vehicule.energie}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Puissance</p>
                    <p className="mt-1 text-sm text-gray-900">{order.vehicule.puissance}</p>
                  </div>
                </div>
              </div>
            ) : (
              <p className="mt-4 text-sm text-gray-500">Aucune information véhicule disponible</p>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => {
            const Icon = tab.icon;
            return (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`
                  group inline-flex items-center py-4 px-4 border-b-2 font-medium text-sm
                  ${activeTab === tab.id
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
                `}
              >
                <Icon className={`
                  ${activeTab === tab.id ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500'}
                  -ml-0.5 mr-2 h-5 w-5
                `} />
                {tab.label}
              </button>
            );
          })}
        </nav>
      </div>

      <div className="mt-4">
        {renderContent()}
      </div>
    </div>
  );
};

export default OrderTabs;
