import React from 'react';
import { Mail } from 'lucide-react';
import ApiTest from './ApiTest';
import EmailTest from './EmailTest';

const EmailSettings = () => {
  return (
    <div className="space-y-6">
      <div className="flex items-center gap-3 mb-6">
        <Mail className="w-6 h-6 text-blue-600" />
        <h2 className="text-2xl font-bold text-gray-900">Configuration Email</h2>
      </div>

      {/* Test de connexion API */}
      <ApiTest />

      {/* Test d'envoi d'email */}
      <EmailTest />
    </div>
  );
};

export default EmailSettings;