import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { Package, Clock, CheckCircle, AlertTriangle } from 'lucide-react';
import toast from 'react-hot-toast';

const OrderTracking = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOrder = async () => {
      if (!orderId) {
        setError('Numéro de commande manquant');
        setLoading(false);
        return;
      }

      try {
        const orderRef = doc(db, 'orders', orderId);
        const orderSnap = await getDoc(orderRef);

        if (!orderSnap.exists()) {
          setError('Commande introuvable');
          setLoading(false);
          return;
        }

        const orderData = {
          id: orderSnap.id,
          ...orderSnap.data()
        };
        
        setOrder(orderData);
        setLoading(false);
      } catch (err) {
        console.error('Erreur lors de la récupération de la commande:', err);
        setError('Une erreur est survenue lors de la récupération des détails de la commande');
        setLoading(false);
      }
    };

    fetchOrder();
  }, [orderId]);

  const getStatusBadge = (status: string) => {
    switch (status) {
      case 'completed':
        return (
          <div className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800">
            <CheckCircle className="w-3 h-3 mr-1" />
            Payé
          </div>
        );
      case 'pending':
        return (
          <div className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
            <Clock className="w-3 h-3 mr-1" />
            En attente
          </div>
        );
      case 'cancelled':
        return (
          <div className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800">
            <AlertTriangle className="w-3 h-3 mr-1" />
            Annulé
          </div>
        );
      default:
        return (
          <div className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
            <Clock className="w-3 h-3 mr-1" />
            {status}
          </div>
        );
    }
  };

  const formatOrderType = (type: string) => {
    switch (type) {
      case 'changement-adresse':
        return 'Changement d\'adresse';
      case 'declaration-cession':
        return 'Déclaration de cession';
      case 'changement-proprietaire':
        return 'Changement de titulaire';
      default:
        return type;
    }
  };

  const handleLogin = () => {
    navigate('/login', { state: { from: `/order-tracking/${orderId}` } });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[300px]">
        <div className="text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
          <p className="mt-2 text-gray-600">Chargement des détails de la commande...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-md p-6 mt-8">
        <div className="text-center">
          <AlertTriangle className="w-12 h-12 text-red-500 mx-auto mb-4" />
          <h2 className="text-xl font-semibold text-gray-800 mb-2">Erreur</h2>
          <p className="text-gray-600 mb-6">{error}</p>
          <button
            onClick={() => navigate('/')}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Retour à l'accueil
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto">
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="bg-blue-600 text-white p-6">
          <h1 className="text-2xl font-bold">Suivi de commande</h1>
          <p className="text-blue-100">Détails et statut de votre commande</p>
        </div>

        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <div>
              <div className="flex items-center gap-2">
                <Package className="w-5 h-5 text-blue-500" />
                <h2 className="text-lg font-semibold">
                  Commande #{order.orderNumber}
                </h2>
              </div>
              <p className="text-gray-500 text-sm mt-1">
                {order.createdAt ? new Date(order.createdAt.seconds * 1000).toLocaleDateString('fr-FR') : 'Date inconnue'}
              </p>
            </div>
            <div className="text-right">
              {getStatusBadge(order.status)}
            </div>
          </div>

          <div className="border-t border-gray-200 pt-4 pb-2">
            <h3 className="text-md font-semibold mb-3">Détails de la commande</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-gray-500 text-sm">Type de démarche</p>
                <p className="font-medium">{formatOrderType(order.orderType)}</p>
              </div>
              <div>
                <p className="text-gray-500 text-sm">Montant</p>
                <p className="font-medium">{order.amount?.toFixed(2)} €</p>
              </div>
            </div>
          </div>

          {order.vehiculeDetails && (
            <div className="border-t border-gray-200 pt-4 pb-2 mt-4">
              <h3 className="text-md font-semibold mb-3">Informations du véhicule</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <p className="text-gray-500 text-sm">Marque</p>
                  <p className="font-medium">{order.vehiculeDetails.marque}</p>
                </div>
                <div>
                  <p className="text-gray-500 text-sm">Modèle</p>
                  <p className="font-medium">{order.vehiculeDetails.modele}</p>
                </div>
                {order.vehiculeDetails.immatriculation && (
                  <div>
                    <p className="text-gray-500 text-sm">Immatriculation</p>
                    <p className="font-medium">{order.vehiculeDetails.immatriculation}</p>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="border-t border-gray-200 pt-4 pb-2 mt-4">
            <h3 className="text-md font-semibold mb-3">Statut du traitement</h3>
            <div className="space-y-3">
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-green-100 flex items-center justify-center text-green-600 mr-3">
                  <CheckCircle className="w-4 h-4" />
                </div>
                <div>
                  <p className="font-medium">Paiement validé</p>
                  <p className="text-sm text-gray-500">Votre paiement a été reçu et confirmé</p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 mr-3">
                  <Clock className="w-4 h-4" />
                </div>
                <div>
                  <p className="font-medium">Traitement en cours</p>
                  <p className="text-sm text-gray-500">Votre dossier est en cours de traitement par nos équipes</p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex flex-col sm:flex-row gap-3 justify-center">
            <button
              onClick={() => navigate('/')}
              className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
            >
              Retour à l'accueil
            </button>
            <button
              onClick={handleLogin}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              Se connecter pour plus de détails
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTracking;
