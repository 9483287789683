export interface ProcessingFee {
  id: string;
  label: string;
  fee: number;
}

export const PROCESSING_FEES: Record<string, ProcessingFee> = {
  'changement-titulaire': {
    id: 'changement-titulaire',
    label: 'Changement de Titulaire',
    fee: 29.90
  },
  'changement-adresse': {
    id: 'changement-adresse',
    label: 'Changement d\'Adresse',
    fee: 29.90
  },
  'declaration-cession': {
    id: 'declaration-cession',
    label: 'Déclaration de Cession',
    fee: 29.90
  },
  'duplicata': {
    id: 'duplicata',
    label: 'Duplicata / Perte / Vol',
    fee: 29.90
  },
  'heritage': {
    id: 'heritage',
    label: 'Héritage',
    fee: 49.90
  },
  'importation': {
    id: 'importation',
    label: 'Vehicule importé / première immatriculation',
    fee: 59.90
  },
  'probleme-cession': {
    id: 'probleme-cession',
    label: 'Problème Cession / Ancienne carte grise',
    fee: 39.90
  },
  'modification': {
    id: 'modification',
    label: 'Modification données carte grise',
    fee: 49.90
  },
  'premiere-immatriculation': {
    id: 'premiere-immatriculation',
    label: 'Première immatriculation',
    fee: 29.90
  }
};