import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ClientSidebar from './ClientSidebar';
import OrdersList from './OrdersList';
import OrderDetails from './OrderDetails';
import Documents from './Documents';
import Profile from './Profile';

const ClientDashboard = () => {
  return (
    <div className="flex gap-8">
      <ClientSidebar />
      <div className="flex-1">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Tableau de bord</h1>
        </div>
        <Routes>
          <Route path="/" element={<OrdersList />} />
          <Route path="/orders/:orderId" element={<OrderDetails />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </div>
    </div>
  );
};

export default ClientDashboard;