import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  FileText, 
  Upload, 
  CreditCard, 
  Clock, 
  CheckCircle, 
  HelpCircle,
  ArrowLeft
} from 'lucide-react';
import InteractiveCard from './components/InteractiveCard';
import Accordion from './components/Accordion';
import VideoTutorial from './components/VideoTutorial';
import { faqCarteGrise, videoTutorials } from './data/guideData';

const GuideCarteGrise = () => {
  const navigate = useNavigate();

  const demarches = [
    {
      icon: <FileText className="w-8 h-8 text-blue-500" />,
      title: "Changement de titulaire",
      description: "Achat d'un véhicule d'occasion",
      details: [
        "Certificat de cession",
        "Carte grise barrée",
        "Justificatif d'identité",
        "Justificatif de domicile"
      ]
    },
    {
      icon: <Upload className="w-8 h-8 text-blue-500" />,
      title: "Immatriculation véhicule neuf",
      description: "Premier enregistrement",
      details: [
        "Certificat de conformité",
        "Facture d'achat",
        "Justificatif d'identité",
        "Justificatif de domicile"
      ]
    },
    {
      icon: <CreditCard className="w-8 h-8 text-blue-500" />,
      title: "Changement d'adresse",
      description: "Mise à jour suite à un déménagement",
      details: [
        "Justificatif de domicile",
        "Carte grise actuelle",
        "Justificatif d'identité"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      {/* Navigation */}
      <div className="max-w-7xl mx-auto mb-8">
        <button
          onClick={() => navigate('/guide')}
          className="inline-flex items-center text-blue-600 hover:text-blue-700"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Retour aux guides
        </button>
      </div>

      {/* Hero Section */}
      <div className="max-w-7xl mx-auto text-center mb-16">
        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          Guide des démarches carte grise
        </h1>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Découvrez toutes les étapes pour obtenir votre carte grise rapidement et simplement.
        </p>
      </div>

      {/* Démarches Section */}
      <div className="max-w-7xl mx-auto mb-24">
        <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">
          Types de démarches
        </h2>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          {demarches.map((demarche, index) => (
            <InteractiveCard
              key={index}
              icon={demarche.icon}
              title={demarche.title}
              description={demarche.description}
              details={demarche.details}
            />
          ))}
        </div>
      </div>

      {/* Video Tutorials Section */}
      <div className="max-w-7xl mx-auto mb-24">
        <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">
          Tutoriels vidéo
        </h2>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          {videoTutorials.map((tutorial, index) => (
            <VideoTutorial key={index} {...tutorial} />
          ))}
        </div>
      </div>

      {/* FAQ Section */}
      <div className="max-w-7xl mx-auto mb-24">
        <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">
          Questions fréquentes
        </h2>
        <Accordion items={faqCarteGrise} />
      </div>

      {/* Support Section */}
      <div className="max-w-7xl mx-auto">
        <div className="bg-blue-50 p-8 rounded-2xl text-center">
          <div className="inline-block p-3 bg-blue-100 rounded-full mb-4">
            <HelpCircle className="w-8 h-8 text-blue-500" />
          </div>
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Besoin d'aide ?</h2>
          <p className="text-gray-600 mb-6">
            Notre équipe est là pour vous accompagner dans vos démarches
          </p>
          <button className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-300">
            Contacter le support
          </button>
        </div>
      </div>
    </div>
  );
};

export default GuideCarteGrise;
