import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useAuth } from './context/AuthContext';
import Navbar from './components/Navbar';
import NavigationBanner from './components/navigation/NavigationBanner';
import Home from './components/Home';
import ClientDashboard from './components/client/ClientDashboard';
import AdminDashboard from './components/admin/AdminDashboard';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute'; // Import AdminRoute
import EmailTester from './components/EmailTester';
import Recap from './components/Recap';
import Payment from './components/Payment';
import Success from './components/Success';
import CheckoutForm from './components/CheckoutForm';
import EmailTestPanel from './components/EmailTestPanel';
import Contact from './components/Contact';
import DocumentsManager from './components/admin/DocumentsManager';
import Calculateur from './components/Calculateur';
import AuthProvider from './context/AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import Cart from './components/Cart';
import GuideSelector from './components/Guide';
import GuideCarteGrise from './components/guides/GuideCarteGrise';
import GuideUtilisation from './components/guides/GuideUtilisation';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import TestAutoAccountEmail from './components/TestAutoAccountEmail';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import AdminLoginPage from './pages/AdminLoginPage';
import OrderTrackingPage from './pages/OrderTrackingPage';

const App = () => {
  const { user, isAdmin } = useAuth();

  return (
    <ErrorBoundary>
      <div className="min-h-screen bg-gray-50">
        <NavigationBanner />
        <Navbar />
        <main className="container mx-auto px-4 py-8">
          <Toaster position="top-right" />
          <Routes>
            {/* Routes publiques */}
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/documents" element={<Navigate to="/" replace />} />
            <Route path="/calculateur" element={<Navigate to="/" replace />} />
            <Route path="/calculator" element={<Navigate to="/" replace />} />
            <Route path="/recap" element={<Recap />} />
            <Route path="/checkout" element={<CheckoutForm />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/payment-success" element={<PaymentSuccessPage />} />
            <Route path="/success" element={<Success />} />
            <Route path="/test-auto-account-email" element={<TestAutoAccountEmail />} />
            <Route path="/guide" element={<GuideSelector />} />
            <Route path="/guide/carte-grise" element={<GuideCarteGrise />} />
            <Route path="/guide/utilisation" element={<GuideUtilisation />} />
            <Route path="/order-tracking/:orderId" element={<OrderTrackingPage />} />
            
            {/* Routes Admin */}
            <Route path="/admin" element={<AdminLoginPage />} />
            <Route path="/admin/dashboard/*" element={<AdminDashboard />} />
            <Route path="/admin/documents" element={<AdminRoute><DocumentsManager /></AdminRoute>} />

            {/* Routes Client */}
            <Route
              path="/dashboard/*"
              element={
                <PrivateRoute>
                  <ClientDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/panier"
              element={
                <PrivateRoute>
                  <Cart />
                </PrivateRoute>
              }
            />

            {/* Redirection par défaut vers l'accueil */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
      </div>
    </ErrorBoundary>
  );
};

export default App;