import { auth } from '../firebase/config';
import { sendPasswordResetEmail } from 'firebase/auth';
import { toast } from 'react-toastify';

export const sendPasswordResetLink = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    toast.success('Un email de réinitialisation a été envoyé');
    return true;
  } catch (error: any) {
    console.error('Erreur lors de l\'envoi du lien de réinitialisation:', error);
    toast.error('Erreur lors de l\'envoi du lien de réinitialisation');
    return false;
  }
};
