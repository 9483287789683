import React from 'react';

const Contact = () => {
  return (
    <div className="max-w-2xl mx-auto">
      <h1 className="text-3xl font-bold mb-8">Contactez-nous</h1>
      
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">Nos coordonnées</h2>
        <div className="space-y-4">
          <div>
            <h2 className="text-lg font-semibold mb-2">Adresse</h2>
            <p className="text-gray-600">32 Bd Henri IV<br />28100 Dreux</p>
          </div>
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2">Téléphone</h2>
            <p className="text-gray-600">06 25 62 15 59</p>
          </div>
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2">Email</h2>
            <p className="text-gray-600">contact@minute-cartegrise.com</p>
          </div>
        </div>
      </div>

      <form className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Envoyez-nous un message</h2>
        <div className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Nom complet
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
              Sujet
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="message" className="block text-sm font-medium text-gray-700">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              rows={4}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Envoyer
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
