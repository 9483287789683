// Configuration de l'API
export const API_CONFIG = {
  baseURL: process.env.NODE_ENV === 'production' 
    ? 'https://app.minute-cartegrise.com'
    : 'http://localhost:3000',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
};

export const API_BASE_URL = API_CONFIG.baseURL;

export const PAYMENT_API = {
  CREATE_INTENT: '/api/payment/create-intent',
  WEBHOOK: '/api/webhook/stripe',
};

export const CLOUDFLARE_WORKER = {
  url: 'https://app.minute-cartegrise.com',
  maxRetries: 3,
  retryDelay: 1000, // 1 seconde
};

export default API_CONFIG;