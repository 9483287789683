import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { clearAllOrders } from '../../services/orderService';

const AdminProfile = () => {
  const { user } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<{ success?: boolean; message?: string } | null>(null);

  const handleClearOrders = async () => {
    setIsLoading(true);
    setResult(null);
    
    try {
      const result = await clearAllOrders();
      setResult(result);
    } catch (error) {
      setResult({ success: false, message: 'Une erreur est survenue lors de la suppression des commandes' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold text-gray-900 mb-6">Mon profil administrateur</h1>
      
      <div className="bg-white shadow rounded-lg p-6 space-y-6">
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <div className="mt-1 text-gray-900">{user?.email}</div>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700">Rôle</label>
            <div className="mt-1 text-gray-900">Administrateur</div>
          </div>
        </div>

        <div className="pt-4 border-t border-gray-200">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Actions administrateur</h2>
          
          <button
            onClick={() => setIsModalOpen(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Effacer toutes les commandes
          </button>
        </div>
      </div>

      {/* Modal de confirmation */}
      {isModalOpen && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            {/* Overlay */}
            <div className="fixed inset-0 bg-black opacity-30" onClick={() => !isLoading && setIsModalOpen(false)} />

            {/* Modal */}
            <div className="relative bg-white rounded-lg max-w-md w-full p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                Confirmation de suppression
              </h3>

              {!result ? (
                <>
                  <p className="text-sm text-gray-500 mb-4">
                    Êtes-vous sûr de vouloir effacer toutes les commandes ? Cette action est irréversible.
                  </p>

                  <div className="mt-6 flex justify-end space-x-3">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      onClick={() => setIsModalOpen(false)}
                      disabled={isLoading}
                    >
                      Annuler
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      onClick={handleClearOrders}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Suppression...' : 'Confirmer la suppression'}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className={`mb-4 text-sm ${result.success ? 'text-green-600' : 'text-red-600'}`}>
                    {result.message}
                  </div>
                  <div className="mt-6 flex justify-end">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      onClick={() => {
                        setIsModalOpen(false);
                        setResult(null);
                      }}
                    >
                      Fermer
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminProfile;
