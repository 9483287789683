import React from 'react';
import Calculateur from './Calculateur';

const Home: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto">
      <div className="text-center mb-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">
          Calculateur de Carte Grise
        </h1>
        <p className="text-gray-600">
          Estimez instantanément le coût de votre carte grise et découvrez les documents nécessaires
        </p>
      </div>
      <Calculateur />
    </div>
  );
};

export default Home;
