import React, { useState, useEffect } from 'react';
import { Upload, FileText, CheckCircle, AlertCircle, Info, Clock, XCircle, Package, Bell } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { uploadDocument, getOrderDocuments, subscribeToOrderDocuments, db, doc, onSnapshot } from '../../services/documentService';
import { formatOrderId } from '../../utils/formatters';
import { DOCUMENT_GROUPS } from '../../config/documentGroups';
import toast from 'react-hot-toast';
import { Order } from '../../types/common';

const formatDate = (date: any) => {
  if (date instanceof Date) {
    return date.toLocaleDateString('fr-FR');
  }
  if (typeof date === 'string' || typeof date === 'number') {
    return new Date(date).toLocaleDateString('fr-FR');
  }
  return 'Date invalide';
};

interface OrderDocument {
  status: string;
  fileName?: string;
  fileUrl?: string;
  uploadedAt?: Date;
  uploadedBy?: string;
  comment?: string;
  hasReminder?: boolean;
  lastReminder?: {
    date: Date;
    comment: string;
  };
}

interface SelectedOrder {
  id: string;
  type: string;
  documents: Record<string, OrderDocument>;
  notifications: Array<{
    id: string;
    message: string;
    createdAt: Date;
    read: boolean;
  }>;
  vehicleInfo?: {
    brand: string;
    model: string;
  };
}

interface UploadStatus {
  status: 'idle' | 'uploading' | 'success' | 'error';
  fileName?: string;
  progress?: number;
}

const Documents = () => {
  const { user } = useAuth();
  const [selectedOrder, setSelectedOrder] = useState<SelectedOrder | null>(null);
  const [uploadStatus, setUploadStatus] = useState<Record<string, UploadStatus>>({});
  const [orderNumber, setOrderNumber] = useState<string>('');

  useEffect(() => {
    const orderData = localStorage.getItem('selectedOrder');
    if (orderData) {
      try {
        const parsedOrder = JSON.parse(orderData);
        if (!parsedOrder.id) {
          toast.error('Données de commande invalides');
          return;
        }

        // Initialiser avec les données existantes
        getOrderDocuments(parsedOrder.id).then(({ documents, notifications }) => {
          setSelectedOrder({
            ...parsedOrder,
            type: parsedOrder.type || 'changement-adresse',
            documents: {
              ...(parsedOrder.documents || {}),
              ...documents.reduce((acc: Record<string, OrderDocument>, doc: any) => {
                acc[doc.documentId] = doc;
                return acc;
              }, {})
            },
            notifications: notifications || []
          });
        });

        // S'abonner aux mises à jour en temps réel
        const unsubscribe = subscribeToOrderDocuments(parsedOrder.id, ({ documents, notifications }) => {
          setSelectedOrder(prev => {
            if (!prev) return null;
            return {
              ...prev,
              documents: {
                ...(prev.documents || {}),
                ...documents.reduce((acc: Record<string, OrderDocument>, doc: any) => {
                  acc[doc.documentId] = doc;
                  return acc;
                }, {})
              },
              notifications: notifications || []
            };
          });
        });

        // Nettoyer l'abonnement quand le composant est démonté
        return () => unsubscribe();

      } catch (error) {
        console.error('Erreur lors du parsing des données:', error);
        toast.error('Erreur lors du chargement des données de la commande');
      }
    }
  }, []);

  useEffect(() => {
    if (selectedOrder) {
      const orderRef = doc(db, 'orders', selectedOrder.id);
      const unsubscribe = onSnapshot(orderRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setOrderNumber(data.orderNumber || 'en cours de traitement');
        }
      });
      return () => unsubscribe();
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (!selectedOrder) return;

    const unsubscribe = onSnapshot(
      doc(db, 'orders', selectedOrder.id),
      (doc) => {
        if (doc.exists()) {
          const orderData = doc.data() as Order;
          setSelectedOrder(prev => ({
            ...prev!,
            ...orderData,
            id: doc.id,
          }));
        }
      },
      (error) => {
        console.error('Error listening to order changes:', error);
      }
    );

    return () => unsubscribe();
  }, [selectedOrder?.id]);

  const handleFileUpload = async (documentId: string, file: File) => {
    if (!selectedOrder || !user) return;

    try {
      setUploadStatus(prev => ({
        ...prev,
        [documentId]: { 
          status: 'uploading',
          fileName: file.name,
          progress: 0
        }
      }));

      await uploadDocument(
        selectedOrder.id,
        documentId,
        file,
        user.uid,
        (progress) => {
          setUploadStatus(prev => ({
            ...prev,
            [documentId]: {
              status: 'uploading',
              fileName: file.name,
              progress
            }
          }));
        }
      );

      // Mise à jour optimiste de l'état local
      setSelectedOrder(prev => {
        if (!prev) return null;
        return {
          ...prev,
          documents: {
            ...prev.documents,
            [documentId]: {
              status: 'pending',
              fileName: file.name,
              fileUrl: true,
              uploadedAt: new Date().toISOString(),
              uploadedBy: user.uid
            }
          }
        };
      });

      setUploadStatus(prev => ({
        ...prev,
        [documentId]: {
          status: 'success',
          fileName: file.name,
          progress: 100
        }
      }));

      toast.success('Document uploadé avec succès');
    } catch (error) {
      console.error('Upload error:', error);
      setUploadStatus(prev => ({
        ...prev,
        [documentId]: {
          status: 'error',
          fileName: file.name,
          progress: 0
        }
      }));
      toast.error('Erreur lors de l\'upload du document');
    }
  };

  return (
    <div className="space-y-6">
      {selectedOrder ? (
        <>
          <div className="bg-white p-4 rounded-lg shadow mb-6">
            <h2 className="text-xl font-semibold mb-2">Documents pour la commande N° {orderNumber}</h2>
            <div className="flex items-center gap-4 text-gray-600">
              <div className="flex items-center gap-2">
                <Package className="w-5 h-5" />
                <span>{selectedOrder.vehicleInfo?.brand} {selectedOrder.vehicleInfo?.model}</span>
              </div>
              <div className="flex items-center gap-2">
                <Info className="w-5 h-5" />
                <span>{selectedOrder.type.replace(/-/g, ' ')}</span>
              </div>
            </div>
          </div>

          <div className="container mx-auto">
            <div className="space-y-6">
              {DOCUMENT_GROUPS[selectedOrder.type] && DOCUMENT_GROUPS[selectedOrder.type].map(group => (
                <div key={group.id} className="bg-white rounded-lg shadow p-6">
                  <h3 className="text-lg font-semibold mb-4">{group.title}</h3>
                  <div className="space-y-4">
                    {group.documents.map(doc => {
                      const orderDoc = selectedOrder.documents[doc.id];
                      const uploadState = uploadStatus[doc.id];

                      return (
                        <div key={doc.id} className={`border rounded-lg p-4 ${
                          orderDoc?.status === 'rejected' ? 'border-red-500' : 'border-gray-200'
                        }`}>
                          <div className="flex items-start justify-between">
                            <div className="flex-1">
                              <div className="flex items-center gap-2">
                                <h4 className="font-medium">{doc.name}</h4>
                                {doc.required && (
                                  <span className="px-2 py-0.5 text-xs font-medium bg-amber-50 text-amber-700 rounded-full border border-amber-200">
                                    Requis
                                  </span>
                                )}
                              </div>
                              {doc.description && (
                                <p className="text-sm text-gray-600 mt-1">{doc.description}</p>
                              )}
                              {orderDoc?.status === 'rejected' && orderDoc.comment && (
                                <div className="mt-2 text-sm text-red-600">
                                  <p>Motif du rejet : {orderDoc.comment}</p>
                                </div>
                              )}
                              {orderDoc?.hasReminder && orderDoc.lastReminder?.comment && (
                                <div className="mt-2 p-3 bg-blue-50 border border-blue-200 rounded-md">
                                  <div className="flex items-start">
                                    <Bell className="w-5 h-5 text-blue-500 mt-0.5 flex-shrink-0" />
                                    <div className="ml-2">
                                      <p className="text-sm font-medium text-blue-800">
                                        Rappel concernant ce document
                                      </p>
                                      <p className="text-sm text-blue-700 mt-1">
                                        {orderDoc.lastReminder.comment}
                                      </p>
                                      <p className="text-xs text-blue-500 mt-1">
                                        {orderDoc.lastReminder.date && (
                                          <>
                                            Envoyé le {formatDate(orderDoc.lastReminder.date)}
                                          </>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="flex items-center space-x-2">
                              {orderDoc?.status === 'pending' && (
                                <span className="flex items-center text-blue-600">
                                  <Clock className="w-4 h-4 mr-1" />
                                  En cours de validation
                                </span>
                              )}
                              {orderDoc?.status === 'validated' && (
                                <span className="flex items-center text-green-600">
                                  <CheckCircle className="w-4 h-4 mr-1" />
                                  Validé
                                </span>
                              )}
                              {orderDoc?.status === 'rejected' && (
                                <span className="flex items-center text-red-600">
                                  <XCircle className="w-4 h-4 mr-1" />
                                  Refusé
                                </span>
                              )}
                              {(!orderDoc || orderDoc.status === 'rejected') && (
                                <label className="cursor-pointer inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">
                                  <Upload className="w-4 h-4 mr-2" />
                                  {orderDoc?.status === 'rejected' ? 'Renvoyer' : 'Envoyer'}
                                  <input
                                    type="file"
                                    className="hidden"
                                    onChange={(e) => {
                                      const file = e.target.files?.[0];
                                      if (file) handleFileUpload(doc.id, file);
                                    }}
                                  />
                                </label>
                              )}
                            </div>
                          </div>
                          {uploadState?.status === 'uploading' && (
                            <div className="mt-2">
                              <div className="w-full bg-gray-200 rounded-full h-2.5">
                                <div
                                  className="bg-blue-600 h-2.5 rounded-full"
                                  style={{ width: `${uploadState.progress}%` }}
                                ></div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="text-center py-12">
          <p>Chargement des documents...</p>
        </div>
      )}
    </div>
  );
};

export default Documents;