import React from 'react';
import { ShoppingCart } from 'lucide-react';

const Cart = () => {
  // TODO: Ajouter la logique pour récupérer les éléments du panier
  const cartItems: any[] = [];

  if (cartItems.length === 0) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center px-4">
        <ShoppingCart className="h-16 w-16 text-gray-400 mb-4" />
        <h2 className="text-2xl font-semibold text-gray-900 mb-2">Votre panier est vide</h2>
        <p className="text-gray-600 text-center mb-8">
          Vous n'avez pas encore ajouté de demande de carte grise à votre panier.
        </p>
        <a
          href="/calculateur"
          className="bg-blue-600 text-white px-6 py-3 rounded-md hover:bg-blue-700 transition-colors"
        >
          Commencer une demande
        </a>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Mon panier</h1>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          {/* TODO: Afficher les éléments du panier */}
          <div className="px-4 py-5 sm:p-6">
            <p>Liste des demandes en cours...</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
