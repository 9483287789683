export const demarches = [
  {
    id: 'changement-proprietaire',
    label: 'Changement de propriétaire d\'un véhicule d\'occasion français',
    icon: 'exchange'
  },
  {
    id: 'changement-adresse',
    label: 'Changement d\'adresse',
    icon: 'home'
  },
  {
    id: 'duplicata',
    label: 'Duplicata',
    icon: 'copy'
  },
  {
    id: 'premiere-immatriculation',
    label: 'Première immatriculation d\'un véhicule d\'occasion en France (Bientôt disponible)',
    icon: 'car',
    disabled: true
  },
  {
    id: 'immatriculation-neuf',
    label: 'Immatriculation d\'un véhicule neuf (Bientôt disponible)',
    icon: 'car',
    disabled: true
  },
  {
    id: 'declaration-cession',
    label: 'Déclaration de cession',
    icon: 'file'
  },
  {
    id: 'heritage',
    label: 'Carte grise pour véhicule reçu en héritage',
    description: 'Demande de carte grise suite à un héritage',
    icon: 'FileText'
  }
];