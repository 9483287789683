import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { validateOrder } from '../../services/orderService';
import OrderTabs from './OrderTabs';
import OrderDocumentsView from './OrderDocumentsView';
import { toast } from 'react-hot-toast';
import { Skeleton } from '../ui/skeleton';

const OrderDetails = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [orderNumber, setOrderNumber] = useState<string>('');

  useEffect(() => {
    if (orderId) {
      fetchOrder();
    }
  }, [orderId]);

  const fetchOrder = async () => {
    if (!orderId) return;
    
    try {
      setLoading(true);
      
      // Récupérer directement le document avec l'ID
      const docRef = doc(db, 'orders', orderId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const orderNumber = data.orderNumber || '';
        setOrderNumber(orderNumber);
        setOrder({
          id: orderId,
          orderNumber: orderNumber,
          date: data.date,
          status: data.status,
          documents: data.documents || {},
          client: data.userDetails || {}, 
          vehicule: data.vehiculeDetails || {},
          paiement: data.paiement || {}
        });
      } else {
        console.error('Commande non trouvée');
        setOrder(null);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération de la commande:', error);
      setOrder(null);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Skeleton />
      </div>
    );
  }

  if (!order) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg p-6 text-center">
          <div className="mb-4">
            <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M12 12h.01M12 12h.01M12 12h.01" />
            </svg>
          </div>
          <h3 className="text-lg font-medium text-gray-900 mb-2">Commande non trouvée</h3>
          <p className="text-gray-500">Impossible de charger les informations de la commande.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Détails de la commande
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {orderNumber ? `Commande n° ${orderNumber}` : `Commande ${orderId.slice(0, 6).toUpperCase()}`}
            </p>
          </div>
          {order.status !== 'validated' && (
            <button
              onClick={() => {
                if (window.confirm('Êtes-vous sûr de vouloir valider cette commande ? Cette action est irréversible.')) {
                  validateOrder(order.id)
                    .then((result) => {
                      if (result.success) {
                        toast.success(result.message);
                        fetchOrder(); // Recharger les données de la commande
                      } else {
                        toast.error(result.message);
                      }
                    });
                }
              }}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Valider la commande
            </button>
          )}
        </div>

        <OrderTabs order={order}>
          <OrderDocumentsView orderId={orderId} />
        </OrderTabs>
      </div>
    </div>
  );
};

export default OrderDetails;