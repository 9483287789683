import { emailConfig } from '../config/email';
import { 
  EmailTemplates, 
  EmailData, 
  OrderConfirmationEmailData, 
  DocumentStatusEmailData,
  AutoAccountEmailData,
  EmailResponse 
} from '../types/email';

// Templates SendGrid
const EMAIL_TEMPLATES: EmailTemplates = {
  TEST: 'd-25d7a4d26774427cbe2cd34535945c10',
  ORDER_CONFIRMATION: 'd-6bd904befa8e4606928b60e87fd3288b',
  DOCUMENTS_PENDING: 'd-42885cc7c58a47a29f67ecce83c0520c',
  DOCUMENTS_REJECTED: 'd-f3c8c63cd55e457481eadba00e51c5c8',
  AUTO_ACCOUNT_CREATED: 'd-6bd904befa8e4606928b60e87fd3288b'
};

interface OrderConfirmationEmailData {
  to: string;
  orderNumber: string;
  clientName: string;
  totalAmount: number;
  requiredDocuments: string[];
  vehiculeDetails?: {
    marque?: string;
    modele?: string;
    immatriculation?: string;
    dateMiseEnCirculation?: string;
    energie?: string;
  };
}

const sendEmail = async (emailData: EmailData): Promise<EmailResponse> => {
  try {
    const msg = {
      to: emailData.to,
      from: {
        email: emailConfig.fromEmail,
        name: emailConfig.fromName
      },
      templateId: emailData.templateId,
      dynamicTemplateData: {
        ...emailData.dynamicTemplateData,
        siteUrl: emailConfig.siteUrl
      }
    };

    console.log('Sending email with data:', JSON.stringify(msg, null, 2));
    
    const response = await fetch('https://minutecartegrise.bosartfilm.workers.dev/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(msg)
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error sending email: ${errorText}`);
    }

    const result = await response.json();
    return {
      success: true,
      messageId: result.messageId
    };
  } catch (error) {
    console.error('Error sending email:', error);
    return {
      success: false,
      error: error instanceof Error ? error.message : 'Unknown error'
    };
  }
};

const sendOrderConfirmationEmail = async (data: OrderConfirmationEmailData): Promise<EmailResponse> => {
  const templateData = {
    orderNumber: data.orderNumber,
    clientName: data.clientName,
    email: data.to,
    totalAmount: data.totalAmount.toFixed(2),
    requiredDocuments: data.requiredDocuments,
    vehiculeDetails: data.vehiculeDetails ? {
      marque: data.vehiculeDetails.marque || '',
      modele: data.vehiculeDetails.modele || '',
      immatriculation: data.vehiculeDetails.immatriculation || '',
      dateMiseEnCirculation: data.vehiculeDetails.dateMiseEnCirculation || '',
      energie: data.vehiculeDetails.energie || ''
    } : null
  };

  return sendEmail({
    to: data.to,
    templateId: EMAIL_TEMPLATES.ORDER_CONFIRMATION,
    dynamicTemplateData: templateData
  });
};

export const sendAutoAccountEmail = async (data: AutoAccountEmailData): Promise<EmailResponse> => {
  return sendEmail({
    to: data.email,
    templateId: EMAIL_TEMPLATES.AUTO_ACCOUNT_CREATED,
    dynamicTemplateData: {
      firstName: data.firstName,
      lastName: data.lastName,
      orderNumber: data.orderNumber,
      tempPassword: data.tempPassword,
      loginUrl: `${emailConfig.siteUrl}/login`,
      resetPasswordUrl: `${emailConfig.siteUrl}/reset-password?email=${encodeURIComponent(data.email)}&token=${data.resetToken}`
    }
  });
};

export const sendDocumentsPendingEmail = async (data: DocumentStatusEmailData): Promise<EmailResponse> => {
  return sendEmail({
    to: data.to,
    templateId: EMAIL_TEMPLATES.DOCUMENTS_PENDING,
    dynamicTemplateData: {
      orderNumber: data.orderNumber,
      clientName: data.clientName,
      requiredDocuments: data.requiredDocuments
    }
  });
};

export const sendDocumentsRejectedEmail = async (data: DocumentStatusEmailData): Promise<EmailResponse> => {
  return sendEmail({
    to: data.to,
    templateId: EMAIL_TEMPLATES.DOCUMENTS_REJECTED,
    dynamicTemplateData: {
      orderNumber: data.orderNumber,
      clientName: data.clientName,
      requiredDocuments: data.requiredDocuments
    }
  });
};

export { sendOrderConfirmationEmail };