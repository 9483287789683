import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Package, ChevronDown, ChevronUp } from 'lucide-react';
import { formatOrderId } from '../../../utils/formatters';

interface OrderHeaderProps {
  order: {
    id: string;
    orderNumber: string;
    clientName: string;
    type: string;
    date: string;
    email: string;
  };
  isExpanded: boolean;
  onToggle: () => void;
  documentsCount: {
    total: number;
    pending: number;
    toValidate: number;
    validated: number;
    rejected: number;
  };
}

const OrderHeader: React.FC<OrderHeaderProps> = ({ order, isExpanded, onToggle, documentsCount }) => {
  return (
    <div 
      className="flex flex-col border border-gray-200 rounded-lg mb-4 bg-white overflow-hidden cursor-pointer hover:border-gray-300 transition-colors"
      onClick={onToggle}
    >
      <div className="flex items-center justify-between p-4">
        <div className="flex items-center gap-4">
          <Package className="w-5 h-5 text-gray-400" />
          <div>
            <div className="flex items-center gap-2">
              <Link 
                to={`/admin/orders/${order.id}`}
                className="text-lg font-semibold text-blue-600 hover:text-blue-800"
                onClick={(e) => e.stopPropagation()}
              >
                Commande n°{order.orderNumber || 'en cours de traitement'}
              </Link>
              <span className="text-sm text-gray-500">
                • {new Date(order.date).toLocaleDateString()}
              </span>
            </div>
            <div className="text-sm text-gray-600">
              {order.clientName} ({order.email})
            </div>
            <div className="text-sm text-gray-500 capitalize">
              {order.type.replace(/-/g, ' ')}
            </div>
          </div>
        </div>

        <div className="flex items-center gap-6">
          <div className="flex gap-2">
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
              {documentsCount.pending} requis
            </span>
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
              {documentsCount.toValidate} à valider
            </span>
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
              {documentsCount.validated} validé(s)
            </span>
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
              {documentsCount.rejected} rejeté(s)
            </span>
          </div>
          {isExpanded ? (
            <ChevronUp className="w-5 h-5 text-gray-400" />
          ) : (
            <ChevronDown className="w-5 h-5 text-gray-400" />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderHeader;