import React from 'react';
import { User, Mail, Phone, MapPin } from 'lucide-react';

interface OrderClientTabProps {
  client: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    address?: string;
    postalCode?: string;
    city?: string;
    complementAddress?: string;
    civilite?: string;
    type?: string;
  };
}

const OrderClientTab: React.FC<OrderClientTabProps> = ({ client }) => {
  const fullName = client.firstName && client.lastName 
    ? `${client.civilite ? client.civilite.toUpperCase() + ' ' : ''}${client.firstName} ${client.lastName}`
    : 'Non spécifié';

  const fullAddress = client.address
    ? `${client.address}${client.complementAddress ? ', ' + client.complementAddress : ''}, ${client.postalCode} ${client.city}`
    : 'Non spécifiée';

  return (
    <div className="p-4">
      <h3 className="text-lg font-medium text-gray-900">Informations client</h3>
      <div className="mt-5 border-t border-gray-200">
        <dl className="divide-y divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="flex items-center text-sm font-medium text-gray-500">
              <User className="mr-2 h-5 w-5" />
              Nom complet
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {fullName}
            </dd>
          </div>

          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="flex items-center text-sm font-medium text-gray-500">
              <Mail className="mr-2 h-5 w-5" />
              Email
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {client.email || 'Non spécifié'}
            </dd>
          </div>

          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="flex items-center text-sm font-medium text-gray-500">
              <Phone className="mr-2 h-5 w-5" />
              Téléphone
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {client.phone || 'Non spécifié'}
            </dd>
          </div>

          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="flex items-center text-sm font-medium text-gray-500">
              <MapPin className="mr-2 h-5 w-5" />
              Adresse
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {fullAddress}
            </dd>
          </div>

          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="flex items-center text-sm font-medium text-gray-500">
              <User className="mr-2 h-5 w-5" />
              Type de client
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {client.type ? client.type.charAt(0).toUpperCase() + client.type.slice(1) : 'Non spécifié'}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default OrderClientTab;
