import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { collection, query, where, onSnapshot, orderBy, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { updateDocumentStatus } from '../../services/documentService';
import OrderHeader from './documents/OrderHeader';
import OrderDocumentsList from './documents/OrderDocumentsList';
import { toast } from 'react-hot-toast';
import { DOCUMENT_GROUPS } from '../../config/documentGroups';

interface Document {
  id: string;
  name: string;
  type: string;
  status: 'pending' | 'validated' | 'rejected';
  uploadDate: string;
  url: string;
  comment?: string;
  required: boolean;
  description?: string;
}

interface VehiculeDetails {
  marque: string;
  modele: string;
  immatriculation: string;
  dateMiseEnCirculation: string;
  energie: string;
  puissance: string;
  type: string;
}

interface UserDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  complementAddress?: string;
  city: string;
  postalCode: string;
  civilite: string;
  type: string;
}

interface Order {
  id: string;
  orderNumber: string;
  amount: number;
  status: string;
  paymentStatus: string;
  type: string;
  date: string;
  documents: Record<string, Document>;
  userDetails: UserDetails;
  vehiculeDetails: VehiculeDetails;
  email: string;
}

const DocumentsManager = () => {
  const { user } = useAuth();
  const [orders, setOrders] = useState<Order[]>([]);
  const [expandedOrders, setExpandedOrders] = useState<string[]>([]);

  useEffect(() => {
    if (!user) return;

    const ordersRef = collection(db, 'orders');
    const ordersQuery = query(
      ordersRef,
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(ordersQuery, async (snapshot) => {
      const ordersData: Order[] = [];
      
      for (const docSnapshot of snapshot.docs) {
        const orderData = docSnapshot.data();
        const documentConfig = DOCUMENT_GROUPS[orderData.type || orderData.orderType] || [];
        const documents: Record<string, Document> = {};

        // Traitement des documents existants
        if (orderData.documents) {
          Object.entries(orderData.documents).forEach(([docId, uploadedDoc]: [string, any]) => {
            const hasUrl = uploadedDoc.fileUrl && 
                         uploadedDoc.fileUrl.length > 0 && 
                         !uploadedDoc.fileUrl.includes('example.com');
            
            if (uploadedDoc.status || hasUrl) {
              const configDoc = documentConfig
                .flatMap(group => group.documents)
                .find(doc => doc.id === docId);

              documents[docId] = {
                id: docId,
                name: configDoc?.name || uploadedDoc.name || docId,
                description: configDoc?.description || uploadedDoc.description || '',
                status: uploadedDoc.status || 'pending',
                required: configDoc?.required ?? true,
                uploadDate: uploadedDoc.uploadDate || uploadedDoc.uploadedAt?.toDate().toISOString() || orderData.createdAt?.toDate().toISOString(),
                url: hasUrl ? uploadedDoc.fileUrl : '',
                type: uploadedDoc.type || 'Document',
                comment: uploadedDoc.comment
              };
            }
          });
        }

        // Ajout des documents requis manquants
        documentConfig.forEach(group => {
          group.documents.forEach(docConfig => {
            if (!documents[docConfig.id] && docConfig.required) {
              documents[docConfig.id] = {
                id: docConfig.id,
                name: docConfig.name,
                description: docConfig.description || '',
                status: 'pending',
                required: true,
                uploadDate: '',
                url: '',
                type: group.title,
                comment: ''
              };
            }
          });
        });

        // Construction de l'adresse complète
        const ud = orderData.userDetails || {};
        const fullAddress = [
          ud.address,
          ud.complementAddress,
          ud.city,
          ud.postalCode
        ].filter(Boolean).join(', ');

        ordersData.push({
          id: docSnapshot.id,
          orderNumber: orderData.orderNumber || docSnapshot.id,
          amount: orderData.amount || 0,
          status: orderData.status || 'pending',
          paymentStatus: orderData.paymentStatus || 'pending',
          type: orderData.type || orderData.orderType || 'Type inconnu',
          date: orderData.createdAt instanceof Timestamp 
            ? orderData.createdAt.toDate().toISOString() 
            : new Date().toISOString(),
          documents,
          userDetails: {
            firstName: ud.firstName || '',
            lastName: ud.lastName || '',
            email: ud.email || orderData.email || '',
            phone: ud.phone || '',
            address: ud.address || '',
            complementAddress: ud.complementAddress || '',
            city: ud.city || '',
            postalCode: ud.postalCode || '',
            civilite: ud.civilite || '',
            type: ud.type || 'particulier'
          },
          vehiculeDetails: orderData.vehiculeDetails || {
            marque: '',
            modele: '',
            immatriculation: '',
            dateMiseEnCirculation: '',
            energie: '',
            puissance: '',
            type: ''
          },
          email: ud.email || orderData.email || ''
        });
      }

      setOrders(ordersData);
    });

    return () => unsubscribe();
  }, [user]);

  const handleValidateDocument = async (orderId: string, documentId: string, comment: string) => {
    await updateDocumentStatus(orderId, documentId, 'validated', comment);
    toast.success('Document validé avec succès');
  };

  const handleRejectDocument = async (orderId: string, documentId: string, comment: string) => {
    await updateDocumentStatus(orderId, documentId, 'rejected', comment);
    toast.error('Document rejeté');
  };

  const toggleOrderExpanded = (orderId: string) => {
    setExpandedOrders(prev => 
      prev.includes(orderId)
        ? prev.filter(id => id !== orderId)
        : [...prev, orderId]
    );
  };

  const getDocumentCounts = (documents: Record<string, Document>) => {
    const counts = {
      total: Object.keys(documents).length,
      pending: 0,
      toValidate: 0,
      validated: 0,
      rejected: 0
    };

    Object.values(documents).forEach(doc => {
      if (doc.status === 'pending') {
        // Si le document a une URL, il est à valider, sinon il est requis
        if (doc.url && doc.url.length > 0) {
          counts.toValidate++;
        } else {
          counts.pending++;
        }
      } else {
        counts[doc.status]++;
      }
    });

    return counts;
  };

  return (
    <div className="max-w-5xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">Documents à traiter</h1>
        <span className="text-sm text-gray-500">
          {orders.length} commande(s) trouvée(s)
        </span>
      </div>

      <div className="space-y-4">
        {orders.map((order) => (
          <div key={order.id}>
            <OrderHeader
              order={order}
              isExpanded={expandedOrders.includes(order.id)}
              onToggle={() => toggleOrderExpanded(order.id)}
              documentsCount={getDocumentCounts(order.documents)}
            />
            {expandedOrders.includes(order.id) && (
              <OrderDocumentsList
                documents={order.documents}
                onValidate={(docId, comment) => handleValidateDocument(order.id, docId, comment)}
                onReject={(docId, comment) => handleRejectDocument(order.id, docId, comment)}
                orderNumber={order.orderNumber}
                clientName={order.userDetails.firstName + ' ' + order.userDetails.lastName}
                clientEmail={order.email}
                orderId={order.id}
                documentGroups={DOCUMENT_GROUPS[order.type] || []}
                orderType={order.type}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocumentsManager;