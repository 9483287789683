import React from 'react';
import { SITE_CONFIG } from '../../config/siteConfig';

const NavigationBanner = () => {
  return (
    <div className="bg-blue-50 border-b border-blue-100">
      <div className="max-w-7xl mx-auto px-4 py-2 sm:py-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2">
              <svg className="h-5 w-5 sm:h-6 sm:w-6 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </span>
            <p className="ml-2 sm:ml-3 font-medium text-blue-900 truncate text-sm sm:text-base">
              <span className="hidden sm:inline">Vous êtes dans l'application de calcul de carte grise</span>
              <span className="sm:hidden">Application carte grise</span>
            </p>
          </div>
          <div className="flex-shrink-0">
            <a
              href={SITE_CONFIG.MAIN_SITE_URL}
              className="inline-flex items-center px-3 py-1 sm:px-4 sm:py-2 border border-transparent rounded-md text-sm font-medium text-blue-600 bg-white hover:bg-blue-50 shadow-sm"
            >
              <span className="hidden sm:inline">Retourner au site principal</span>
              <span className="sm:hidden">Site principal</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigationBanner;
