import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AdminSidebar from './AdminSidebar';
import AdminUserMenu from './AdminUserMenu';
import OrdersList from './OrdersList';
import OrderDetails from './OrderDetails';
import ClientsList from './ClientsList';
import AdminDocuments from './AdminDocuments';
import EmailSettings from './settings/EmailSettings';
import TestOrderButton from './TestOrderButton';
import OrderDocumentsView from './OrderDocumentsView';
import AdminProfile from './AdminProfile';
import AdminSettings from './AdminSettings';
import { collection, query, getDocs, where, deleteDoc, doc, updateDoc, writeBatch } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RefreshCw, Trash2 } from 'react-feather';
import { clearAllOrders } from '../../services/orderService';

const AdminDashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<{ success?: boolean; message?: string } | null>(null);

  const handleClearOrders = async () => {
    setIsLoading(true);
    setResult(null);
    
    try {
      const result = await clearAllOrders();
      setResult(result);
      if (result.success) {
        toast.success(result.message);
        window.location.reload();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setResult({ success: false, message: 'Une erreur est survenue lors de la suppression des commandes' });
      toast.error('Une erreur est survenue lors de la suppression des commandes');
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      <AdminSidebar />
      <div className="flex-1">
        <div className="p-8">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold text-gray-900">Tableau de bord administrateur</h1>
            <div className="flex gap-2">
              <button
                onClick={() => setIsModalOpen(true)}
                className="inline-flex items-center px-4 py-2 bg-red-600 text-white rounded-lg text-sm font-medium hover:bg-red-700"
                title="Supprimer toutes les commandes"
              >
                <Trash2 className="w-4 h-4 mr-2" />
                Supprimer toutes les commandes
              </button>
            </div>
          </div>

          {/* Modal de confirmation */}
          {isModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
                <h2 className="text-xl font-bold mb-4">Confirmation de suppression</h2>
                <p className="text-gray-600 mb-6">
                  Êtes-vous sûr de vouloir supprimer toutes les commandes ? Cette action est irréversible.
                </p>
                <div className="flex justify-end gap-3">
                  <button
                    onClick={() => setIsModalOpen(false)}
                    className="px-4 py-2 text-gray-600 hover:text-gray-800"
                    disabled={isLoading}
                  >
                    Annuler
                  </button>
                  <button
                    onClick={handleClearOrders}
                    className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 flex items-center"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <RefreshCw className="w-4 h-4 mr-2 animate-spin" />
                        Suppression...
                      </>
                    ) : (
                      <>
                        <Trash2 className="w-4 h-4 mr-2" />
                        Confirmer la suppression
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="container mx-auto px-4 py-8">
            <Routes>
              <Route path="/" element={<OrdersList />} />
              <Route path="/orders" element={<OrdersList />} />
              <Route path="/orders/:orderId" element={<OrderDetails />} />
              <Route path="/clients" element={<ClientsList />} />
              <Route path="/documents" element={<AdminDocuments />} />
              <Route path="/documents/:orderId" element={<OrderDocumentsView />} />
              <Route path="/settings" element={<AdminSettings />} />
              <Route path="/settings/email" element={<EmailSettings />} />
              <Route path="/profile" element={<AdminProfile />} />
              <Route path="*" element={<Navigate to="/admin" replace />} />
            </Routes>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdminDashboard;