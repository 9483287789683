export const faqCarteGrise = [
  {
    question: "Quels sont les délais pour obtenir ma carte grise ?",
    answer: "Le délai moyen est de 3 à 5 jours ouvrés après validation de votre dossier complet. L'envoi de votre carte grise se fait par courrier sécurisé. En cas d'urgence, vous pouvez obtenir un certificat provisoire d'immatriculation (CPI) valable 1 mois."
  },
  {
    question: "Quels documents dois-je fournir pour une carte grise d'occasion ?",
    answer: "Pour un véhicule d'occasion, vous devez fournir : l'ancien certificat d'immatriculation barré et signé, le certificat de cession, un justificatif d'identité en cours de validité, un justificatif de domicile de moins de 6 mois, et le contrôle technique en cours de validité pour les véhicules de plus de 4 ans."
  },
  {
    question: "Comment calculer le coût de ma carte grise ?",
    answer: "Le coût de votre carte grise dépend de plusieurs facteurs : la puissance fiscale du véhicule, votre région de résidence (taxe régionale), l'âge du véhicule, et son type d'énergie. Notre calculateur en ligne vous permet d'obtenir une estimation précise instantanément."
  },
  {
    question: "Que faire en cas d'erreur sur ma carte grise ?",
    answer: "Si vous constatez une erreur sur votre carte grise, contactez-nous immédiatement. Nous vous guiderons dans la procédure de rectification. La correction est gratuite si l'erreur vient de l'administration, mais peut être payante si l'erreur provient des informations que vous avez fournies."
  },
  {
    question: "Puis-je faire une demande pour quelqu'un d'autre ?",
    answer: "Oui, vous pouvez faire une demande pour une autre personne. Dans ce cas, vous devrez fournir une procuration signée par le titulaire, ainsi que les copies des pièces d'identité du titulaire et du mandataire."
  },
  {
    question: "Comment payer ma carte grise ?",
    answer: "Nous acceptons plusieurs moyens de paiement sécurisés : carte bancaire, virement bancaire, ou PayPal. Le paiement est requis pour finaliser votre demande, et vous recevrez une facture détaillée par email."
  },
  {
    question: "Que faire si mon dossier est incomplet ?",
    answer: "Si votre dossier est incomplet, nous vous contacterons par email en précisant les documents manquants. Vous pourrez alors les ajouter directement depuis votre espace personnel. Votre demande sera traitée dès que le dossier sera complet."
  },
  {
    question: "Mon véhicule est-il éligible au bonus écologique ?",
    answer: "Les véhicules électriques et certains véhicules hybrides peuvent bénéficier du bonus écologique. Le montant dépend du type de véhicule et de son prix. Nous pouvons vous aider à vérifier votre éligibilité et à inclure cette réduction dans votre demande."
  }
];

export const faqUtilisation = [
  {
    question: "Comment créer mon compte ?",
    answer: "La création de compte est simple et gratuite. Cliquez sur 'S'inscrire', remplissez le formulaire avec vos informations personnelles (nom, email, mot de passe). Vous recevrez un email de confirmation pour activer votre compte."
  },
  {
    question: "Comment suivre l'avancement de ma demande ?",
    answer: "Connectez-vous à votre espace personnel et accédez à la section 'Mes commandes'. Vous y trouverez l'état d'avancement détaillé de chaque demande. Vous recevrez également des notifications par email à chaque étape importante."
  },
  {
    question: "Comment télécharger mes documents ?",
    answer: "Dans votre espace personnel, section 'Documents', vous pouvez télécharger vos documents en les scannant ou en les photographiant. Assurez-vous que les documents sont lisibles et complets. Les formats acceptés sont JPG, PNG et PDF."
  },
  {
    question: "J'ai oublié mon mot de passe, que faire ?",
    answer: "Cliquez sur 'Mot de passe oublié' sur la page de connexion. Entrez votre email, vous recevrez un lien pour réinitialiser votre mot de passe. Par sécurité, ce lien n'est valable que 24 heures."
  },
  {
    question: "Comment modifier mes informations personnelles ?",
    answer: "Dans votre espace personnel, accédez à 'Mon profil'. Vous pouvez y modifier vos coordonnées, votre adresse et vos préférences de notification. Certaines modifications peuvent nécessiter une vérification d'identité."
  },
  {
    question: "Comment contacter le support client ?",
    answer: "Vous pouvez nous contacter via le formulaire de contact, par email à contact@minute-cartegrise.com, ou par téléphone au 06 25 62 15 59 du lundi au vendredi de 9h à 18h. Pour un traitement plus rapide, mentionnez votre numéro de commande."
  },
  {
    question: "Les paiements sont-ils sécurisés ?",
    answer: "Oui, tous nos paiements sont sécurisés. Nous utilisons un système de paiement certifié aux normes PCI-DSS. Vos informations bancaires sont cryptées et ne sont jamais stockées sur nos serveurs."
  },
  {
    question: "Comment annuler ou modifier ma commande ?",
    answer: "Pour modifier ou annuler une commande, contactez rapidement notre service client. L'annulation est possible tant que le dossier n'a pas été transmis à l'ANTS. Des frais peuvent s'appliquer selon l'état d'avancement de la demande."
  }
];

export const videoTutorials = [
  {
    title: "Comment calculer le prix de votre carte grise",
    description: "Guide étape par étape pour utiliser notre calculateur de tarif",
    thumbnailUrl: "/assets/videos/thumbnails/calculateur.jpg",
    videoUrl: "https://www.youtube.com/embed/XXXXX"
  },
  {
    title: "Préparer vos documents",
    description: "Les bonnes pratiques pour scanner et télécharger vos documents",
    thumbnailUrl: "/assets/videos/thumbnails/documents.jpg",
    videoUrl: "https://www.youtube.com/embed/XXXXX"
  },
  {
    title: "Suivre votre commande",
    description: "Comment suivre l'avancement de votre demande de carte grise",
    thumbnailUrl: "/assets/videos/thumbnails/suivi.jpg",
    videoUrl: "https://www.youtube.com/embed/XXXXX"
  }
];

export const demarcheDetails = {
  "changement-titulaire": {
    title: "Changement de titulaire",
    steps: [
      "Obtention du certificat de cession",
      "Vérification de la carte grise barrée",
      "Scan des documents d'identité",
      "Justificatif de domicile récent"
    ],
    requirements: [
      "Certificat de cession original",
      "Carte grise barrée avec mention 'Vendu le [date]'",
      "Permis de conduire valide",
      "Justificatif de domicile de moins de 6 mois"
    ],
    tips: [
      "Vérifiez que tous les champs du certificat de cession sont remplis",
      "La mention 'Vendu le' doit être suivie de la date et de la signature",
      "Le justificatif doit être à votre nom et prénom"
    ]
  },
  "changement-adresse": {
    title: "Changement d'adresse",
    steps: [
      "Préparation du justificatif de domicile",
      "Scan de la carte grise actuelle",
      "Vérification des documents d'identité"
    ],
    requirements: [
      "Carte grise actuelle",
      "Justificatif de domicile de moins de 6 mois",
      "Pièce d'identité valide"
    ],
    tips: [
      "Le justificatif peut être une facture d'électricité, de gaz ou d'internet",
      "L'adresse doit être exactement la même sur tous les documents",
      "La démarche est gratuite pour les 2 premiers changements"
    ]
  }
};
