import React from 'react';
import Modal from '../../common/Modal';

interface DocumentReminderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  documentName: string;
}

const DocumentReminderModal: React.FC<DocumentReminderModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  documentName,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Demander un document"
    >
      <div className="p-6">
        <p className="text-sm text-gray-500 mb-4">
          Vous êtes sur le point d'envoyer une demande pour le document : <br />
          <span className="font-medium text-gray-900">{documentName}</span>
        </p>

        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={onConfirm}
          >
            Envoyer la demande
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={onClose}
          >
            Annuler
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DocumentReminderModal;
