import {
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  onSnapshot,
  serverTimestamp,
  DocumentReference,
  DocumentData,
  Timestamp,
  getDocs,
  deleteDoc,
  addDoc,
  getFirestore
} from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { app, db, storage } from '../firebase/config';
import { Document, Order } from '../types/common';
import { OrderDocuments, DocumentUpdate, DocumentUploadProgress, OrderDocumentSubscription } from '../types/document';

const transformDocuments = (data: any) => {
  // Si les documents sont directement dans l'objet de la commande
  if (data.piece_identite || data.justificatif_domicile) {
    return {
      piece_identite: data.piece_identite || {},
      justificatif_domicile: data.justificatif_domicile || {},
      ...(data.certificat_immatriculation && { certificat_immatriculation: data.certificat_immatriculation }),
      ...(data.certificat_cession && { certificat_cession: data.certificat_cession })
    };
  }

  // Si les documents sont dans un sous-objet documents
  if (data.documents) {
    const transformedDocs: any = {};
    Object.entries(data.documents).forEach(([key, value]: [string, any]) => {
      // Convertir les tirets en underscores
      const newKey = key.replace(/-/g, '_');
      transformedDocs[newKey] = {
        ...value,
        type: newKey,
        documentId: newKey,
        name: value.name || key.replace(/-/g, ' ').replace(/(^\w|\s\w)/g, c => c.toUpperCase())
      };
    });
    return transformedDocs;
  }

  return {};
};

// Fonction pour récupérer les documents d'une commande
export const getOrderDocuments = async (orderId: string): Promise<OrderDocuments> => {
  try {
    const orderRef = doc(db, 'orders', orderId);
    const orderDoc = await getDoc(orderRef);
    
    if (orderDoc.exists()) {
      const data = orderDoc.data();
      console.log('Raw data from getOrderDocuments:', data);
      
      const transformedDocs = transformDocuments(data);
      console.log('Transformed documents:', transformedDocs);
      
      const documents = Object.entries(transformedDocs).map(([key, value]) => ({
        id: key,
        name: value.name || key.replace(/_/g, ' ').replace(/(^\w|\s\w)/g, c => c.toUpperCase()),
        type: value.type || key,
        status: value.status || 'pending',
        required: value.required !== undefined ? value.required : true,
        fileUrl: value.fileUrl || null,
        fileName: value.fileName || null,
        uploadedBy: value.uploadedBy || null,
        uploadedAt: value.uploadedAt instanceof Timestamp ? value.uploadedAt.toDate() : null,
        lastUpdate: value.lastUpdate instanceof Timestamp ? value.lastUpdate.toDate() : new Date(),
        comment: value.comment || null
      }));

      return {
        documents,
        notifications: data.notifications || []
      };
    }
    console.error('Order document not found');
    return { documents: [], notifications: [] };
  } catch (error) {
    console.error('Error getting order documents:', error);
    throw error;
  }
};

export const updateDocumentStatus = async (
  orderId: string,
  documentId: string,
  status: 'validated' | 'rejected',
  comment: string,
  userId: string
): Promise<void> => {
  try {
    const orderRef = doc(db, 'orders', orderId);
    
    const orderDoc = await getDoc(orderRef);
    if (!orderDoc.exists()) {
      throw new Error('Order not found');
    }

    const update: DocumentUpdate = {
      status,
      comment,
      updatedBy: userId,
      updatedAt: new Date()
    };

    await updateDoc(orderRef, {
      [`documents.${documentId}.status`]: status,
      [`documents.${documentId}.comment`]: comment,
      [`documents.${documentId}.updatedBy`]: userId,
      [`documents.${documentId}.updatedAt`]: Timestamp.fromDate(new Date())
    });
  } catch (error) {
    console.error('Error updating document status:', error);
    throw error;
  }
};

export const uploadDocument = async (
  orderId: string,
  documentId: string,
  file: File,
  userId?: string,
  onProgress?: (progress: number) => void
): Promise<void> => {
  try {
    const storageRef = ref(storage, `documents/${orderId}/${documentId}`);
    const orderRef = doc(db, 'orders', orderId);
    
    // Upload file to Firebase Storage
    const uploadTask = uploadBytesResumable(storageRef, file);
    
    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (onProgress) {
            onProgress(progress);
          }
        },
        (error) => {
          console.error('Error uploading document:', error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            
            // Update Firestore with the document info
            await updateDoc(orderRef, {
              [`documents.${documentId}`]: {
                id: documentId,
                status: 'pending',
                fileUrl: downloadURL,
                fileName: file.name,
                name: file.name,
                uploadedBy: userId || auth.currentUser?.uid || null,
                uploadedAt: serverTimestamp(),
                lastUpdate: serverTimestamp(),
                type: documentId,
                comment: '',
                updatedBy: null,
                updatedAt: null
              }
            });
            
            resolve();
          } catch (error) {
            console.error('Error in upload completion:', error);
            reject(error);
          }
        }
      );
    });
  } catch (error) {
    console.error('Error in uploadDocument:', error);
    throw error;
  }
};

export const subscribeToOrderDocuments = ( 
  orderId: string,
  callback: (data: OrderDocumentSubscription) => void
): () => void => {
  const orderRef = doc(db, 'orders', orderId);
  
  return onSnapshot(orderRef, (doc) => {
    if (doc.exists()) {
      const data = doc.data();
      console.log('Raw data from subscribeToOrderDocuments:', data);
      
      const transformedDocs = transformDocuments(data);
      console.log('Transformed documents:', transformedDocs);
      
      const documents = Object.entries(transformedDocs).map(([key, value]) => ({
        id: key,
        name: value.name || key.replace(/_/g, ' ').replace(/(^\w|\s\w)/g, c => c.toUpperCase()),
        type: value.type || key,
        status: value.status || 'pending',
        required: value.required !== undefined ? value.required : true,
        fileUrl: value.fileUrl || null,
        fileName: value.fileName || null,
        uploadedBy: value.uploadedBy || null,
        uploadedAt: value.uploadedAt instanceof Timestamp ? value.uploadedAt.toDate() : null,
        lastUpdate: value.lastUpdate instanceof Timestamp ? value.lastUpdate.toDate() : new Date(),
        comment: value.comment || null
      }));

      callback({
        documents,
        notifications: data.notifications || []
      });
    } else {
      callback({
        documents: [],
        notifications: []
      });
    }
  });
};

export { db, doc, onSnapshot };