export const departements = [
  { code: '01', nom: '01 - Ain', region: 'Auvergne-Rhône-Alpes' },
  { code: '02', nom: '02 - Aisne', region: 'Hauts-de-France' },
  { code: '03', nom: '03 - Allier', region: 'Auvergne-Rhône-Alpes' },
  { code: '04', nom: '04 - Alpes-de-Haute-Provence', region: 'Provence-Alpes-Côte d\'Azur' },
  { code: '05', nom: '05 - Hautes-Alpes', region: 'Provence-Alpes-Côte d\'Azur' },
  { code: '06', nom: '06 - Alpes-Maritimes', region: 'Provence-Alpes-Côte d\'Azur' },
  { code: '07', nom: '07 - Ardèche', region: 'Auvergne-Rhône-Alpes' },
  { code: '08', nom: '08 - Ardennes', region: 'Grand Est' },
  { code: '09', nom: '09 - Ariège', region: 'Occitanie' },
  { code: '10', nom: '10 - Aube', region: 'Grand Est' },
  { code: '11', nom: '11 - Aude', region: 'Occitanie' },
  { code: '12', nom: '12 - Aveyron', region: 'Occitanie' },
  { code: '13', nom: '13 - Bouches-du-Rhône', region: 'Provence-Alpes-Côte d\'Azur' },
  { code: '14', nom: '14 - Calvados', region: 'Normandie' },
  { code: '15', nom: '15 - Cantal', region: 'Auvergne-Rhône-Alpes' },
  { code: '16', nom: '16 - Charente', region: 'Nouvelle-Aquitaine' },
  { code: '17', nom: '17 - Charente-Maritime', region: 'Nouvelle-Aquitaine' },
  { code: '18', nom: '18 - Cher', region: 'Centre-Val de Loire' },
  { code: '19', nom: '19 - Corrèze', region: 'Nouvelle-Aquitaine' },
  { code: '2A', nom: '2A - Corse-du-Sud', region: 'Corse' },
  { code: '2B', nom: '2B - Haute-Corse', region: 'Corse' },
  { code: '21', nom: '21 - Côte-d\'Or', region: 'Bourgogne-Franche-Comté' },
  { code: '22', nom: '22 - Côtes-d\'Armor', region: 'Bretagne' },
  { code: '23', nom: '23 - Creuse', region: 'Nouvelle-Aquitaine' },
  { code: '24', nom: '24 - Dordogne', region: 'Nouvelle-Aquitaine' },
  { code: '25', nom: '25 - Doubs', region: 'Bourgogne-Franche-Comté' },
  { code: '26', nom: '26 - Drôme', region: 'Auvergne-Rhône-Alpes' },
  { code: '27', nom: '27 - Eure', region: 'Normandie' },
  { code: '28', nom: '28 - Eure-et-Loir', region: 'Centre-Val de Loire' },
  { code: '29', nom: '29 - Finistère', region: 'Bretagne' },
  { code: '30', nom: '30 - Gard', region: 'Occitanie' },
  { code: '31', nom: '31 - Haute-Garonne', region: 'Occitanie' },
  { code: '32', nom: '32 - Gers', region: 'Occitanie' },
  { code: '33', nom: '33 - Gironde', region: 'Nouvelle-Aquitaine' },
  { code: '34', nom: '34 - Hérault', region: 'Occitanie' },
  { code: '35', nom: '35 - Ille-et-Vilaine', region: 'Bretagne' },
  { code: '36', nom: '36 - Indre', region: 'Centre-Val de Loire' },
  { code: '37', nom: '37 - Indre-et-Loire', region: 'Centre-Val de Loire' },
  { code: '38', nom: '38 - Isère', region: 'Auvergne-Rhône-Alpes' },
  { code: '39', nom: '39 - Jura', region: 'Bourgogne-Franche-Comté' },
  { code: '40', nom: '40 - Landes', region: 'Nouvelle-Aquitaine' },
  { code: '41', nom: '41 - Loir-et-Cher', region: 'Centre-Val de Loire' },
  { code: '42', nom: '42 - Loire', region: 'Auvergne-Rhône-Alpes' },
  { code: '43', nom: '43 - Haute-Loire', region: 'Auvergne-Rhône-Alpes' },
  { code: '44', nom: '44 - Loire-Atlantique', region: 'Pays de la Loire' },
  { code: '45', nom: '45 - Loiret', region: 'Centre-Val de Loire' },
  { code: '46', nom: '46 - Lot', region: 'Occitanie' },
  { code: '47', nom: '47 - Lot-et-Garonne', region: 'Nouvelle-Aquitaine' },
  { code: '48', nom: '48 - Lozère', region: 'Occitanie' },
  { code: '49', nom: '49 - Maine-et-Loire', region: 'Pays de la Loire' },
  { code: '50', nom: '50 - Manche', region: 'Normandie' },
  { code: '51', nom: '51 - Marne', region: 'Grand Est' },
  { code: '52', nom: '52 - Haute-Marne', region: 'Grand Est' },
  { code: '53', nom: '53 - Mayenne', region: 'Pays de la Loire' },
  { code: '54', nom: '54 - Meurthe-et-Moselle', region: 'Grand Est' },
  { code: '55', nom: '55 - Meuse', region: 'Grand Est' },
  { code: '56', nom: '56 - Morbihan', region: 'Bretagne' },
  { code: '57', nom: '57 - Moselle', region: 'Grand Est' },
  { code: '58', nom: '58 - Nièvre', region: 'Bourgogne-Franche-Comté' },
  { code: '59', nom: '59 - Nord', region: 'Hauts-de-France' },
  { code: '60', nom: '60 - Oise', region: 'Hauts-de-France' },
  { code: '61', nom: '61 - Orne', region: 'Normandie' },
  { code: '62', nom: '62 - Pas-de-Calais', region: 'Hauts-de-France' },
  { code: '63', nom: '63 - Puy-de-Dôme', region: 'Auvergne-Rhône-Alpes' },
  { code: '64', nom: '64 - Pyrénées-Atlantiques', region: 'Nouvelle-Aquitaine' },
  { code: '65', nom: '65 - Hautes-Pyrénées', region: 'Occitanie' },
  { code: '66', nom: '66 - Pyrénées-Orientales', region: 'Occitanie' },
  { code: '67', nom: '67 - Bas-Rhin', region: 'Grand Est' },
  { code: '68', nom: '68 - Haut-Rhin', region: 'Grand Est' },
  { code: '69', nom: '69 - Rhône', region: 'Auvergne-Rhône-Alpes' },
  { code: '70', nom: '70 - Haute-Saône', region: 'Bourgogne-Franche-Comté' },
  { code: '71', nom: '71 - Saône-et-Loire', region: 'Bourgogne-Franche-Comté' },
  { code: '72', nom: '72 - Sarthe', region: 'Pays de la Loire' },
  { code: '73', nom: '73 - Savoie', region: 'Auvergne-Rhône-Alpes' },
  { code: '74', nom: '74 - Haute-Savoie', region: 'Auvergne-Rhône-Alpes' },
  { code: '75', nom: '75 - Paris', region: 'Île-de-France' },
  { code: '76', nom: '76 - Seine-Maritime', region: 'Normandie' },
  { code: '77', nom: '77 - Seine-et-Marne', region: 'Île-de-France' },
  { code: '78', nom: '78 - Yvelines', region: 'Île-de-France' },
  { code: '79', nom: '79 - Deux-Sèvres', region: 'Nouvelle-Aquitaine' },
  { code: '80', nom: '80 - Somme', region: 'Hauts-de-France' },
  { code: '81', nom: '81 - Tarn', region: 'Occitanie' },
  { code: '82', nom: '82 - Tarn-et-Garonne', region: 'Occitanie' },
  { code: '83', nom: '83 - Var', region: 'Provence-Alpes-Côte d\'Azur' },
  { code: '84', nom: '84 - Vaucluse', region: 'Provence-Alpes-Côte d\'Azur' },
  { code: '85', nom: '85 - Vendée', region: 'Pays de la Loire' },
  { code: '86', nom: '86 - Vienne', region: 'Nouvelle-Aquitaine' },
  { code: '87', nom: '87 - Haute-Vienne', region: 'Nouvelle-Aquitaine' },
  { code: '88', nom: '88 - Vosges', region: 'Grand Est' },
  { code: '89', nom: '89 - Yonne', region: 'Bourgogne-Franche-Comté' },
  { code: '90', nom: '90 - Territoire de Belfort', region: 'Bourgogne-Franche-Comté' },
  { code: '91', nom: '91 - Essonne', region: 'Île-de-France' },
  { code: '92', nom: '92 - Hauts-de-Seine', region: 'Île-de-France' },
  { code: '93', nom: '93 - Seine-Saint-Denis', region: 'Île-de-France' },
  { code: '94', nom: '94 - Val-de-Marne', region: 'Île-de-France' },
  { code: '95', nom: '95 - Val-d\'Oise', region: 'Île-de-France' },
  { code: '971', nom: '971 - Guadeloupe', region: 'Guadeloupe' },
  { code: '972', nom: '972 - Martinique', region: 'Martinique' },
  { code: '973', nom: '973 - Guyane', region: 'Guyane' },
  { code: '974', nom: '974 - La Réunion', region: 'La Réunion' },
  { code: '976', nom: '976 - Mayotte', region: 'Mayotte' }
];