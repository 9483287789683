import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CheckCircle, FileText, Upload, ArrowRight } from 'lucide-react';
import { doc, getDoc, updateDoc, serverTimestamp, db } from '../firebase/config';
import { toast } from 'react-hot-toast';

const Success: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const orderId = searchParams.get('orderId');
  const orderNumberFromUrl = searchParams.get('orderNumber');
  const [countdown, setCountdown] = useState(10);
  const [orderNumber, setOrderNumber] = useState<string>(orderNumberFromUrl || '');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!orderId) {
      toast.error('Commande non trouvée');
      navigate('/');
      return;
    }

    const updateOrderStatus = async () => {
      try {
        const orderRef = doc(db, 'orders', orderId);
        const orderSnap = await getDoc(orderRef);
        
        if (orderSnap.exists()) {
          const data = orderSnap.data();
          setOrderNumber(data.orderNumber || orderNumberFromUrl || '');
          
          // Mettre à jour le statut et envoyer l'email
          await updateDoc(orderRef, {
            status: 'completed',
            paymentStatus: 'succeeded',
            paymentDate: new Date().toISOString(),
            updatedAt: serverTimestamp()
          });

          // Envoyer l'email de confirmation ici
          try {
            await fetch('/api/send-confirmation-email', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                orderId,
                orderNumber: data.orderNumber || orderNumberFromUrl,
                email: data.email,
              }),
            });
          } catch (emailError) {
            console.error('Erreur lors de l\'envoi de l\'email:', emailError);
          }

          setLoading(false);
          
          const timer = setInterval(() => {
            setCountdown((prev) => {
              if (prev <= 1) {
                clearInterval(timer);
                navigate('/dashboard');
                return 0;
              }
              return prev - 1;
            });
          }, 1000);

          return () => clearInterval(timer);
        } else {
          throw new Error('Commande non trouvée');
        }
      } catch (error) {
        console.error('Erreur lors de la mise à jour de la commande:', error);
        toast.error('Une erreur est survenue lors de la mise à jour de la commande');
        setTimeout(() => navigate('/'), 3000);
      }
    };

    updateOrderStatus();
  }, [orderId, navigate]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-green-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-2xl mx-auto">
        <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
          {/* En-tête avec icône et titre */}
          <div className="bg-gradient-to-r from-green-500 to-green-600 p-6 text-center">
            <div className="flex justify-center mb-4">
              <CheckCircle className="w-20 h-20 text-white" />
            </div>
            <h1 className="text-3xl font-bold text-white">
              Paiement effectué avec succès !
            </h1>
          </div>

          {/* Contenu principal */}
          <div className="p-8">
            {/* Numéro de commande */}
            <div className="text-center mb-8">
              <p className="text-lg text-gray-600">
                Votre commande
              </p>
              <p className="text-2xl font-bold text-gray-900">
                N° {orderNumber}
              </p>
            </div>

            {/* Étapes suivantes */}
            <div className="bg-gray-50 rounded-xl p-6 mb-8">
              <h2 className="flex items-center justify-center gap-2 text-xl font-semibold text-gray-800 mb-6">
                <FileText className="w-6 h-6 text-green-500" />
                Prochaines étapes
              </h2>
              
              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0 w-8 h-8 bg-green-100 rounded-full flex items-center justify-center">
                    <span className="text-green-600 font-semibold">1</span>
                  </div>
                  <div>
                    <p className="text-gray-800 font-medium">Email de confirmation</p>
                    <p className="text-gray-600">Vous allez recevoir un email avec la liste des documents nécessaires.</p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0 w-8 h-8 bg-green-100 rounded-full flex items-center justify-center">
                    <span className="text-green-600 font-semibold">2</span>
                  </div>
                  <div>
                    <p className="text-gray-800 font-medium">Téléchargement des documents</p>
                    <p className="text-gray-600">Connectez-vous à votre espace client pour téléverser les documents requis.</p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0 w-8 h-8 bg-green-100 rounded-full flex items-center justify-center">
                    <span className="text-green-600 font-semibold">3</span>
                  </div>
                  <div>
                    <p className="text-gray-800 font-medium">Traitement de votre demande</p>
                    <p className="text-gray-600">Notre équipe traitera votre dossier dès réception de tous les documents.</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Bouton d'action */}
            <div className="text-center space-y-4">
              <button
                onClick={() => navigate('/dashboard')}
                className="inline-flex items-center justify-center gap-2 w-full sm:w-auto px-8 py-3 text-base font-medium text-white bg-green-600 hover:bg-green-700 rounded-lg transition-colors duration-200"
              >
                <Upload className="w-5 h-5" />
                Accéder à mon espace client
                <ArrowRight className="w-5 h-5" />
              </button>

              <p className="text-sm text-gray-500">
                Redirection automatique dans {countdown} secondes...
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
