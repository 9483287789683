import { sendAutoAccountEmail } from './emailService';

export interface EmailTestResult {
  success: boolean;
  error?: string;
}

export const sendTestEmail = async (): Promise<EmailTestResult> => {
  try {
    const response = await sendAutoAccountEmail({
      email: 'test@minutecartegrise.fr',
      firstName: 'Test',
      lastName: 'User',
      orderNumber: 'TEST123',
      tempPassword: 'TempPass123!',
      resetToken: 'reset-token-123'
    });

    console.log('Email test envoyé:', response);
    return { success: true };
  } catch (error: any) {
    console.error('Erreur lors du test d\'email:', error);
    return {
      success: false,
      error: error.message || 'Une erreur est survenue lors de l\'envoi de l\'email'
    };
  }
};

export const testAutoAccountEmail = async () => {
  try {
    const response = await sendAutoAccountEmail({
      email: 'votre-email@test.com',
      firstName: 'Test',
      lastName: 'User',
      orderNumber: 'TEST123',
      tempPassword: 'TempPass123!',
      resetToken: 'reset-token-123'
    });

    console.log('Email test envoyé:', response);
    return response;
  } catch (error) {
    console.error('Erreur lors du test d\'email:', error);
    throw error;
  }
};