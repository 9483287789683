import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, doc, updateDoc, deleteDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import toast from 'react-hot-toast';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Card } from '../ui/card';
import AccountManagement from './AccountManagement';

interface PromoCode {
  id: string;
  code: string;
  discount: number;
  validUntil: Date;
  maxUses: number;
  currentUses: number;
  isActive: boolean;
}

interface Settings {
  emailNotifications: boolean;
  autoApproval: boolean;
  maxDocumentSize: number;
  supportEmail: string;
  supportPhone: string;
}

const AdminSettings = () => {
  const [promoCodes, setPromoCodes] = useState<PromoCode[]>([]);
  const [loading, setLoading] = useState(true);
  const [newPromoCode, setNewPromoCode] = useState<Partial<PromoCode>>({
    code: '',
    discount: 0,
    maxUses: 0,
    isActive: true
  });
  const [settings, setSettings] = useState<Settings>({
    emailNotifications: true,
    autoApproval: false,
    maxDocumentSize: 10,
    supportEmail: '',
    supportPhone: ''
  });

  useEffect(() => {
    fetchPromoCodes();
    fetchSettings();
  }, []);

  const fetchPromoCodes = async () => {
    try {
      const q = query(collection(db, 'promoCodes'));
      const snapshot = await getDocs(q);
      const codes = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        validUntil: doc.data().validUntil?.toDate()
      })) as PromoCode[];
      setPromoCodes(codes);
    } catch (error) {
      console.error('Erreur lors de la récupération des codes promo:', error);
      toast.error('Erreur lors du chargement des codes promo');
    } finally {
      setLoading(false);
    }
  };

  const fetchSettings = async () => {
    try {
      const settingsDoc = await getDocs(collection(db, 'settings'));
      if (!settingsDoc.empty) {
        const settingsData = settingsDoc.docs[0].data() as Settings;
        setSettings(settingsData);
      }
    } catch (error) {
      console.error('Erreur lors du chargement des paramètres:', error);
      toast.error('Erreur lors du chargement des paramètres');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Paramètres administrateur</h1>
      
      <Tabs defaultValue="users" className="w-full">
        <TabsList className="mb-8">
          <TabsTrigger value="users">Utilisateurs</TabsTrigger>
          <TabsTrigger value="accounts">Gestion des comptes</TabsTrigger>
          <TabsTrigger value="promo">Codes Promo</TabsTrigger>
          <TabsTrigger value="settings">Paramètres</TabsTrigger>
        </TabsList>

        <TabsContent value="users">
          <AccountManagement />
        </TabsContent>

        <TabsContent value="accounts">
          <AccountManagement />
        </TabsContent>

        <TabsContent value="promo">
          <Card className="p-6">
            <h2 className="text-2xl font-bold mb-4">Codes Promo</h2>
            <div className="mb-8">
              <h2 className="text-xl font-semibold mb-4">Créer un nouveau code promo</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <Input
                  type="text"
                  placeholder="Code"
                  value={newPromoCode.code}
                  onChange={(e) => setNewPromoCode(prev => ({ ...prev, code: e.target.value }))}
                />
                <Input
                  type="number"
                  placeholder="Réduction (%)"
                  value={newPromoCode.discount}
                  onChange={(e) => setNewPromoCode(prev => ({ ...prev, discount: Number(e.target.value) }))}
                />
                <Input
                  type="number"
                  placeholder="Utilisations max"
                  value={newPromoCode.maxUses}
                  onChange={(e) => setNewPromoCode(prev => ({ ...prev, maxUses: Number(e.target.value) }))}
                />
                <Button>
                  Créer le code promo
                </Button>
              </div>
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Code
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Réduction
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Utilisations
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Valide jusqu'au
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Statut
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {promoCodes.map((promo) => (
                    <tr key={promo.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">{promo.code}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">{promo.discount}%</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          {promo.currentUses} / {promo.maxUses}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {promo.validUntil?.toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          promo.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                        }`}>
                          {promo.isActive ? 'Actif' : 'Inactif'}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <Button variant={promo.isActive ? "destructive" : "default"}>
                          {promo.isActive ? 'Désactiver' : 'Activer'}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Card>
        </TabsContent>

        <TabsContent value="settings">
          <Card className="p-6">
            <h2 className="text-2xl font-bold mb-4">Paramètres généraux</h2>
            <form className="space-y-8">
              <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-xl font-semibold mb-6">Paramètres généraux</h2>
                
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <label htmlFor="emailNotifications" className="font-medium">
                        Notifications par email
                      </label>
                      <p className="text-sm text-gray-500">
                        Envoyer des notifications par email aux clients
                      </p>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none">
                      <input
                        type="checkbox"
                        name="emailNotifications"
                        id="emailNotifications"
                        checked={settings.emailNotifications}
                        onChange={(e) => setSettings(prev => ({ ...prev, emailNotifications: e.target.checked }))}
                        className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                      />
                      <label
                        htmlFor="emailNotifications"
                        className={`toggle-label block overflow-hidden h-6 rounded-full cursor-pointer ${
                          settings.emailNotifications ? 'bg-blue-600' : 'bg-gray-300'
                        }`}
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div>
                      <label htmlFor="autoApproval" className="font-medium">
                        Approbation automatique
                      </label>
                      <p className="text-sm text-gray-500">
                        Approuver automatiquement les documents après vérification
                      </p>
                    </div>
                    <div className="relative inline-block w-12 mr-2 align-middle select-none">
                      <input
                        type="checkbox"
                        name="autoApproval"
                        id="autoApproval"
                        checked={settings.autoApproval}
                        onChange={(e) => setSettings(prev => ({ ...prev, autoApproval: e.target.checked }))}
                        className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                      />
                      <label
                        htmlFor="autoApproval"
                        className={`toggle-label block overflow-hidden h-6 rounded-full cursor-pointer ${
                          settings.autoApproval ? 'bg-blue-600' : 'bg-gray-300'
                        }`}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="maxDocumentSize" className="block font-medium">
                      Taille maximale des documents (MB)
                    </label>
                    <input
                      type="number"
                      name="maxDocumentSize"
                      id="maxDocumentSize"
                      value={settings.maxDocumentSize}
                      onChange={(e) => setSettings(prev => ({ ...prev, maxDocumentSize: Number(e.target.value) }))}
                      min="1"
                      max="50"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </div>

              <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-xl font-semibold mb-6">Informations de contact</h2>
                
                <div className="space-y-4">
                  <div>
                    <label htmlFor="supportEmail" className="block font-medium">
                      Email de support
                    </label>
                    <input
                      type="email"
                      name="supportEmail"
                      id="supportEmail"
                      value={settings.supportEmail}
                      onChange={(e) => setSettings(prev => ({ ...prev, supportEmail: e.target.value }))}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>

                  <div>
                    <label htmlFor="supportPhone" className="block font-medium">
                      Téléphone de support
                    </label>
                    <input
                      type="tel"
                      name="supportPhone"
                      id="supportPhone"
                      value={settings.supportPhone}
                      onChange={(e) => setSettings(prev => ({ ...prev, supportPhone: e.target.value }))}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Enregistrer les modifications
                </button>
              </div>
            </form>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default AdminSettings;
