import React, { useState } from 'react';
import { demarches } from '../../data/demarches';
import './DemarcheSelector.css';

// Types
interface DemarcheSelectorProps {
  selectedDemarche: string;
  onDemarcheChange: (demarche: string) => void;
}

// Icônes SVG pour chaque type de démarche
const DemarcheIcon = ({ type }: { type: string }) => {
  switch (type) {
    case 'exchange':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="demarche-svg-icon">
          <path d="M7 16V4M7 4L3 8M7 4L11 8" />
          <path d="M17 8v12m0 0 4-4m-4 4-4-4" />
        </svg>
      );
    case 'home':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="demarche-svg-icon">
          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
          <polyline points="9 22 9 12 15 12 15 22" />
        </svg>
      );
    case 'copy':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="demarche-svg-icon">
          <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
          <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
        </svg>
      );
    case 'car':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="demarche-svg-icon">
          <path d="M14 16H9m10 0h3v-3.15a1 1 0 0 0-.84-.99L16 11l-2.7-3.6a1 1 0 0 0-.8-.4H5.24a2 2 0 0 0-1.8 1.1l-.8 1.63A6 6 0 0 0 2 12.42V16h2" />
          <circle cx="6.5" cy="16.5" r="2.5" />
          <circle cx="16.5" cy="16.5" r="2.5" />
        </svg>
      );
    case 'file':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="demarche-svg-icon">
          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
          <polyline points="14 2 14 8 20 8" />
          <line x1="16" y1="13" x2="8" y2="13" />
          <line x1="16" y1="17" x2="8" y2="17" />
          <polyline points="10 9 9 9 8 9" />
        </svg>
      );
    case 'FileText':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="demarche-svg-icon">
          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
          <polyline points="14 2 14 8 20 8" />
          <line x1="16" y1="13" x2="8" y2="13" />
          <line x1="16" y1="17" x2="8" y2="17" />
          <polyline points="10 9 9 9 8 9" />
        </svg>
      );
    default:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="demarche-svg-icon">
          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
          <polyline points="14 2 14 8 20 8" />
          <line x1="16" y1="13" x2="8" y2="13" />
          <line x1="16" y1="17" x2="8" y2="17" />
          <polyline points="10 9 9 9 8 9" />
        </svg>
      );
  }
};

const DemarcheSelector: React.FC<DemarcheSelectorProps> = ({ selectedDemarche, onDemarcheChange }) => {
  const [hoveredDemarche, setHoveredDemarche] = useState<string | null>(null);

  return (
    <div className="w-full">
      <label className="block text-base sm:text-lg font-medium text-gray-700 mb-4">
        Sélectionnez votre démarche
      </label>
      
      {/* Affichage mobile : select standard pour les petits écrans */}
      <div className="block sm:hidden">
        <select
          value={selectedDemarche}
          onChange={(e) => onDemarcheChange(e.target.value)}
          className="w-full rounded-xl border border-gray-300 py-3 px-4 text-base focus:border-blue-500 focus:ring-blue-500 bg-white"
        >
          <option value="">Choisir une démarche</option>
          {demarches.map(demarche => (
            <option 
              key={demarche.id} 
              value={demarche.id}
              disabled={demarche.disabled}
            >
              {demarche.label}
            </option>
          ))}
        </select>
      </div>
      
      {/* Affichage desktop : cartes avec animations CSS */}
      <div className="hidden sm:block">
        <div className="demarche-grid">
          {demarches.map((demarche, index) => (
            <div
              key={demarche.id}
              onClick={() => !demarche.disabled && onDemarcheChange(demarche.id)}
              onMouseEnter={() => setHoveredDemarche(demarche.id)}
              onMouseLeave={() => setHoveredDemarche(null)}
              className={`
                demarche-card p-4 rounded-xl border
                ${demarche.disabled ? 'disabled' : ''}
                ${selectedDemarche === demarche.id ? 'selected border-blue-500 bg-blue-50' : 'border-gray-200 bg-white'}
              `}
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              {/* Icône SVG avec animation CSS */}
              <div className="w-full h-32 flex items-center justify-center mb-3">
                <div className={`
                  demarche-icon-container 
                  ${hoveredDemarche === demarche.id ? 'hovered' : ''} 
                  ${selectedDemarche === demarche.id ? 'selected' : ''}
                  ${demarche.disabled ? 'disabled' : ''}
                `}>
                  <DemarcheIcon type={demarche.icon} />
                </div>
              </div>
              
              <h3 className={`text-sm font-medium ${selectedDemarche === demarche.id ? 'text-blue-700' : 'text-gray-800'}`}>
                {demarche.label}
              </h3>
              
              {demarche.disabled && (
                <div className="demarche-badge">
                  Bientôt
                </div>
              )}
              
              {/* Indicateur de sélection */}
              <div className="demarche-check">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {/* Message d'aide */}
      {!selectedDemarche && (
        <p className="mt-2 text-sm text-gray-500">
          Veuillez sélectionner une démarche pour continuer
        </p>
      )}
    </div>
  );
};

export default DemarcheSelector;
