import React, { useState } from 'react';
import { X } from 'lucide-react';

interface DocumentValidationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onValidate: (comment: string) => void;
  onReject: (comment: string) => void;
  documentId: string;
  mode: 'validate' | 'reject';
}

const DocumentValidationModal: React.FC<DocumentValidationModalProps> = ({
  isOpen,
  onClose,
  onValidate,
  onReject,
  documentId,
  mode
}) => {
  const [comment, setComment] = useState('');

  if (!isOpen) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (mode === 'validate') {
      onValidate(comment);
    } else {
      onReject(comment);
    }
    setComment('');
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">
            {mode === 'validate' ? 'Valider le document' : 'Rejeter le document'}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="comment"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              {mode === 'validate' 
                ? 'Commentaire (optionnel)' 
                : 'Motif du rejet (obligatoire)'}
            </label>
            <textarea
              id="comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows={4}
              required={mode === 'reject'}
            />
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Annuler
            </button>
            <button
              type="submit"
              className={`px-4 py-2 text-sm font-medium text-white rounded-md ${
                mode === 'validate'
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-red-600 hover:bg-red-700'
              }`}
            >
              {mode === 'validate' ? 'Valider' : 'Rejeter'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DocumentValidationModal;