import React from 'react';
import { Link } from 'react-router-dom';
import { Car, Book } from 'lucide-react';

const GuideSelector = () => {
  const guides = [
    {
      title: "Guide des démarches carte grise",
      description: "Tout savoir sur les différentes démarches de carte grise et les documents nécessaires",
      icon: <Car className="w-12 h-12 text-blue-500" />,
      path: "/guide/carte-grise"
    },
    {
      title: "Guide d'utilisation de l'application",
      description: "Comment utiliser notre plateforme pour gérer vos demandes de carte grise",
      icon: <Book className="w-12 h-12 text-blue-500" />,
      path: "/guide/utilisation"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-extrabold text-gray-900 text-center mb-12">
          Guides et Documentation
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {guides.map((guide, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
            >
              <Link to={guide.path} className="block p-8">
                <div className="flex flex-col items-center text-center">
                  <div className="bg-blue-50 p-4 rounded-full mb-6">
                    {guide.icon}
                  </div>
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">{guide.title}</h2>
                  <p className="text-gray-600">{guide.description}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GuideSelector;
