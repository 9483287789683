import React, { useState, useEffect } from 'react';
import { FileText, Send } from 'lucide-react';
import DocumentStatusBadge from './DocumentStatusBadge';
import DocumentActions from './DocumentActions';
import { sendDocumentReminder } from '../../../services/documentReminderService';
import DocumentReminderModal from './DocumentReminderModal';
import { Document } from '../../../types/common';

interface OrderDocumentsListProps {
  documents: Record<string, Document>;
  onValidate: (docId: string, comment: string) => void;
  onReject: (docId: string, comment: string) => void;
  orderNumber: string;
  clientName: string;
  clientEmail: string;
  orderId: string;
  showDocumentActions?: boolean;
  documentGroups: Array<{ title: string; documents: Array<{ id: string; name: string; description: string; required: boolean }> }>;
  orderType: string;
}

const OrderDocumentsList: React.FC<OrderDocumentsListProps> = ({
  documents,
  onValidate,
  onReject,
  orderNumber,
  clientName,
  clientEmail,
  orderId,
  showDocumentActions = true,
  documentGroups,
  orderType
}) => {
  const [localDocuments, setLocalDocuments] = useState<Record<string, Document>>(documents);
  const [reminderModalOpen, setReminderModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<Document & { id: string } | null>(null);

  useEffect(() => {
    setLocalDocuments(documents);
  }, [documents]);

  const handleValidate = async (docId: string, comment: string) => {
    await onValidate(docId, comment);
  };

  const handleReject = async (docId: string, comment: string) => {
    await onReject(docId, comment);
  };

  const handleSendReminder = async (message: string) => {
    if (!selectedDocument) return;

    try {
      await sendDocumentReminder({
        documentId: selectedDocument.id,
        orderId,
        orderNumber,
        clientName,
        clientEmail,
        documentName: selectedDocument.name || selectedDocument.type || '',
        comment: message,
      });

      setReminderModalOpen(false);
    } catch (error) {
      console.error('Error sending reminder:', error);
    }
  };

  if (!localDocuments || Object.keys(localDocuments).length === 0) {
    return (
      <div className="text-center py-4 text-gray-500">
        Aucun document disponible
      </div>
    );
  }

  // Organiser les documents par groupe
  const documentsByGroup = documentGroups.map(group => {
    const groupDocs = group.documents.map(doc => {
      const uploadedDoc = localDocuments[doc.id];
      if (!uploadedDoc) {
        return {
          id: doc.id,
          name: doc.name,
          description: doc.description,
          required: doc.required,
          status: 'pending',
          uploadDate: '',
          url: '',
          type: group.title
        };
      }
      return {
        ...uploadedDoc,
        id: doc.id,
        name: doc.name || uploadedDoc.name,
        description: doc.description || uploadedDoc.description,
        required: doc.required
      };
    });

    return {
      title: group.title,
      documents: groupDocs
    };
  });

  return (
    <div className="space-y-6 mt-4">
      {documentsByGroup.map((group) => (
        <div key={group.title} className="bg-white rounded-lg shadow-sm border border-gray-100">
          <div className="px-6 py-4 border-b border-gray-100">
            <h3 className="text-lg font-medium text-gray-900">{group.title}</h3>
          </div>
          <div className="p-4">
            <div className="divide-y divide-gray-100">
              {group.documents.map((doc) => (
                <div
                  key={doc.id}
                  className="flex items-center justify-between py-3 hover:bg-gray-50 px-2 rounded transition-colors duration-150"
                >
                  <div className="flex items-center gap-3">
                    <div className="text-gray-400">
                      <FileText className="w-5 h-5" />
                    </div>
                    <div>
                      <div className="text-sm font-medium text-gray-900">{doc.name}</div>
                      {doc.description && (
                        <div className="text-xs text-gray-500 mt-0.5">{doc.description}</div>
                      )}
                    </div>
                    <DocumentStatusBadge status={doc.status || 'pending'} />
                    {doc.required && (
                      <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-50 text-yellow-800">
                        Requis
                      </span>
                    )}
                  </div>
                  
                  {showDocumentActions && (
                    <div className="flex items-center gap-3">
                      <DocumentActions
                        documentId={doc.id}
                        documentUrl={doc.url}
                        status={doc.status}
                        onValidate={handleValidate}
                        onReject={handleReject}
                      />
                      {doc.status === 'pending' && (
                        <button
                          onClick={() => {
                            setSelectedDocument(doc);
                            setReminderModalOpen(true);
                          }}
                          className="text-blue-600 hover:text-blue-700 transition-colors duration-150"
                          title="Envoyer une relance"
                        >
                          <Send className="w-5 h-5" />
                        </button>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}

      <DocumentReminderModal
        isOpen={reminderModalOpen}
        onClose={() => setReminderModalOpen(false)}
        onSendReminder={handleSendReminder}
        documentName={selectedDocument?.name || ''}
      />
    </div>
  );
};

export default OrderDocumentsList;